.ol-control i {
  cursor: default;
}

/* Bar style */
.ol-control.ol-bar {
  left: 50%;
  min-height: 1em;
  min-width: 1em;
  position: absolute;
  top: 0.5em;
  transform: translate(-50%,0);
  -webkit-transform: translate(-50%,0);
  white-space: nowrap;
}

/* Hide subbar when not inserted in a parent bar */
.ol-control.ol-toggle .ol-option-bar {
  display: none;
}

/* Default position for controls */
.ol-control.ol-bar .ol-bar {
  position: static;
}
.ol-control.ol-bar .ol-control {
  position: relative;
  top: auto;
  left:auto;
  right:auto;
  bottom: auto;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  padding: 0;
  margin: 0;
  transform: none;
  -webkit-transform: none;
}
.ol-control.ol-bar .ol-bar {
  position: static;
}
.ol-control.ol-bar .ol-control button {
  margin:2px 1px;
  outline: none;
}

/* Positionning */
.ol-control.ol-bar.ol-left {
  left: 0.5em;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}
.ol-control.ol-bar.ol-left .ol-control {
  display: block;
}

.ol-control.ol-bar.ol-right {
  left: auto;
  right: 0.5em;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}
.ol-control.ol-bar.ol-right .ol-control {
  display: block;
}

.ol-control.ol-bar.ol-bottom {
  top: auto;
  bottom: 0.5em;
}

.ol-control.ol-bar.ol-top.ol-left,
.ol-control.ol-bar.ol-top.ol-right {
  top: 4.5em;
  -webkit-transform:none;
          transform:none;
}
.ol-touch .ol-control.ol-bar.ol-top.ol-left,
.ol-touch .ol-control.ol-bar.ol-top.ol-right {
  top: 5.5em;
}
.ol-control.ol-bar.ol-bottom.ol-left,
.ol-control.ol-bar.ol-bottom.ol-right {
  top: auto;
  bottom: 0.5em;
  -webkit-transform:none;
          transform:none;
}

/* Group buttons */
.ol-control.ol-bar.ol-group {
  margin: 1px 1px 1px 0;
}
.ol-control.ol-bar.ol-right .ol-group,
.ol-control.ol-bar.ol-left .ol-group {
  margin: 1px 1px 0 1px;
}

.ol-control.ol-bar.ol-group button {
  border-radius:0;
  margin: 0 0 0 1px;
}
.ol-control.ol-bar.ol-right.ol-group button,
.ol-control.ol-bar.ol-left.ol-group button,
.ol-control.ol-bar.ol-right .ol-group button,
.ol-control.ol-bar.ol-left .ol-group button {
  margin: 0 0 1px 0;
}
.ol-control.ol-bar.ol-group .ol-control:first-child > button {
  border-radius: 5px 0 0 5px;
}
.ol-control.ol-bar.ol-group .ol-control:last-child > button {
  border-radius: 0 5px 5px 0;
}
.ol-control.ol-bar.ol-left.ol-group .ol-control:first-child > button,
.ol-control.ol-bar.ol-right.ol-group .ol-control:first-child > button,
.ol-control.ol-bar.ol-left .ol-group .ol-control:first-child > button,
.ol-control.ol-bar.ol-right .ol-group .ol-control:first-child > button {
  border-radius: 5px 5px 0 0;
}
.ol-control.ol-bar.ol-left.ol-group .ol-control:last-child > button,
.ol-control.ol-bar.ol-right.ol-group .ol-control:last-child > button,
.ol-control.ol-bar.ol-left .ol-group .ol-control:last-child > button,
.ol-control.ol-bar.ol-right .ol-group .ol-control:last-child > button {
  border-radius: 0 0 5px 5px;
}

/* */
.ol-control.ol-bar .ol-rotate {
  opacity:1;
  visibility: visible;
}
.ol-control.ol-bar .ol-rotate button {
  display: block
}

/* Active buttons */
.ol-control.ol-bar .ol-toggle.ol-active > button {
  background-color: rgba(60, 136, 0, 0.7)
}
.ol-control.ol-bar .ol-toggle.ol-active button:hover {
  background-color: rgba(60, 136, 0, 0.7)
}
.ol-control.ol-toggle button:disabled {
  background-color: rgba(0,60,136,.3);
}

/* Subbar toolbar */
.ol-control.ol-bar .ol-control.ol-option-bar {
  display: none;
  position:absolute;
  top:100%;
  left:0;
  margin: 5px 0;
  border-radius: 0;
  background-color: rgba(255,255,255, 0.8);
  /* border: 1px solid rgba(0, 60, 136, 0.5); */
  -webkit-box-shadow: 0 0 0 1px rgba(0, 60, 136, 0.5), 1px 1px 2px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 0 1px rgba(0, 60, 136, 0.5), 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.ol-control.ol-bar .ol-option-bar:before {
  content: "";
  border: 0.5em solid transparent;
  border-color: transparent transparent rgba(0, 60, 136, 0.5);
  position: absolute;
  bottom: 100%;
  left: 0.3em;
}

.ol-control.ol-bar .ol-option-bar .ol-control {
  display: table-cell;
}
.ol-control.ol-bar .ol-control .ol-bar
{	display: none;
}
.ol-control.ol-bar .ol-control.ol-active > .ol-option-bar {
  display: block;
}

.ol-control.ol-bar .ol-control.ol-collapsed ul {
  display: none;
}

.ol-control.ol-bar .ol-control.ol-text-button > div:hover,
.ol-control.ol-bar .ol-control.ol-text-button > div {
  background-color: transparent;
  color: rgba(0, 60, 136, 0.5);
  width: auto;
  min-width: 1.375em;
  margin: 0;
}

.ol-control.ol-bar .ol-control.ol-text-button {
  font-size:0.9em;
  border-left: 1px solid rgba(0, 60, 136, 0.8);
  border-radius: 0;
}
.ol-control.ol-bar .ol-control.ol-text-button:first-child {
  border-left:0;
}
.ol-control.ol-bar .ol-control.ol-text-button > div {
  padding: .11em 0.3em;
  font-weight: normal;
  font-size: 1.14em;
  font-family: Arial,Helvetica,sans-serif;
}
.ol-control.ol-bar .ol-control.ol-text-button div:hover {
  color: rgba(0, 60, 136, 1);
}

.ol-control.ol-bar.ol-bottom .ol-option-bar {
  top: auto;
  bottom: 100%;
}
.ol-control.ol-bar.ol-bottom .ol-option-bar:before {
  border-color: rgba(0, 60, 136, 0.5) transparent transparent ;
  bottom: auto;
  top: 100%;
}

.ol-control.ol-bar.ol-left .ol-option-bar {
  left:100%;
  top: 0;
  bottom: auto;
  margin: 0 5px;
}
.ol-control.ol-bar.ol-left .ol-option-bar:before {
  border-color: transparent rgba(0, 60, 136, 0.5) transparent transparent;
  bottom: auto;
  right: 100%;
  left: auto;
  top: 0.3em;
}
.ol-control.ol-bar.ol-right .ol-option-bar {
  right:100%;
  left:auto;
  top: 0;
  bottom: auto;
  margin: 0 5px;
}
.ol-control.ol-bar.ol-right .ol-option-bar:before {
  border-color: transparent transparent transparent rgba(0, 60, 136, 0.5);
  bottom: auto;
  left: 100%;
  top: 0.3em;
}

.ol-control.ol-bar.ol-left .ol-option-bar .ol-option-bar,
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar {
  top: 100%;
  bottom: auto;
  left: 0.3em;
  right: auto;
  margin: 5px 0;
}
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar {
  right: 0.3em;
  left: auto;
}
.ol-control.ol-bar.ol-left .ol-option-bar .ol-option-bar:before,
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar:before {
  border-color: transparent transparent rgba(0, 60, 136, 0.5);
  bottom: 100%;
  top: auto;
  left: 0.3em;
  right: auto;
}
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar:before {
  right: 0.3em;
  left: auto;
}

.ol-control-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ol-center-position {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: rgba(255,255,255,.8);
  padding: .1em 1em;
}

.ol-compassctrl {
  display: none;
  top: 1em;
  left: auto;
  right: 1em;
}
.ol-compassctrl.ol-visible {
  display: block!important;
}
.ol-ext-dialog {
  position: fixed;
  top: -100%;
  left: 0;
  width: 150%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 1000;
  pointer-events: none!important;
  -webkit-transition: opacity .2s, top 0s .2s;
  transition: opacity .2s, top 0s .2s;
}
.ol-ext-dialog.ol-visible {
  opacity: 1;
  top: 0;
  pointer-events: all!important;
  -webkit-transition: opacity .2s, top 0s;
  transition: opacity .2s, top 0s;
}

.ol-viewport .ol-ext-dialog {
  position: absolute;
}
.ol-ext-dialog > form > h2 {
  margin: 0 .5em .5em 0;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ol-ext-dialog > form.ol-title > h2 {
  display: block;
}
.ol-ext-dialog > form {
  position: absolute;
  top: 0;
  left: 33.33%;
  min-width: 5em;
  max-width: 60%;
  min-height: 3em;
  max-height: 100%;
  background-color: #fff;
  border: 1px solid #333;
  -webkit-box-shadow: 3px 3px 4px rgba(0,0,0, 0.5);
          box-shadow: 3px 3px 4px rgba(0,0,0, 0.5);
  -webkit-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
  -webkit-transition: top .2s, -webkit-transform .2s;
  transition: top .2s, -webkit-transform .2s;
  transition: top .2s, transform .2s;
  transition: top .2s, transform .2s, -webkit-transform .2s;
  padding: 1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.ol-ext-dialog > form.ol-closebox {
  padding-top: 1.5em;
}
.ol-ext-dialog > form.ol-title {
  padding-top: 1em;
}
.ol-ext-dialog > form.ol-button {
  padding-bottom: .5em;
}

.ol-ext-dialog.ol-zoom > form {
  top: 30%;
  -webkit-transform: translate(-50%, -30%) scale(0);
          transform: translate(-50%, -30%) scale(0);
}
.ol-ext-dialog.ol-visible > form {
  top: 30%;
}
.ol-ext-dialog.ol-zoom.ol-visible > form {
  -webkit-transform: translate(-50%, -30%) scale(1);
          transform: translate(-50%, -30%) scale(1);
}

.ol-ext-dialog > form .ol-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.ol-ext-dialog > form .ol-closebox {
  position: absolute;
  top: .5em;
  right: .5em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  display: none;
}
.ol-ext-dialog > form.ol-closebox .ol-closebox {
  display: block;
}
.ol-ext-dialog > form .ol-closebox:before,
.ol-ext-dialog > form .ol-closebox:after {
  content: "";
  position: absolute;
  background-color: currentColor;
  top: 50%;
  left: 50%;
  width: 1em;
  height: .1em;
  border-radius: .1em;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.ol-ext-dialog > form .ol-closebox:before {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.ol-ext-dialog > form .ol-buttons {
  text-align: right;
  overflow-x: hidden;
}
.ol-ext-dialog > form .ol-buttons input {
  margin-top: .5em;
  padding: .5em;
  background: none;
  border: 0;
  font-size: 1em;
  color: rgba(0,60,136,1);
  cursor: pointer;
  border-radius: .25em;
  outline-width: 0;
}
.ol-ext-dialog > form .ol-buttons input:hover {
  background-color:  rgba(0,60,136,.1);
}
.ol-ext-dialog > form .ol-buttons input[type=submit] {
  font-weight: bold;
}

.ol-ext-dialog .ol-progress-message {
  font-size: .9em;
  text-align: center;
  padding-bottom: .5em;
}
.ol-ext-dialog .ol-progress-bar {
  border: 1px solid #369;
  width: 20em;
  height: 1em;
  max-width: 100%;
  padding: 2px;
  margin: .5em auto 0;
  overflow: hidden;
}
.ol-ext-dialog .ol-progress-bar > div {
  background: #369;
  height: 100%;
  width: 50%;
  -webkit-transition: width .3s;
  transition: width .3s;
}
.ol-ext-dialog .ol-progress-bar > div.notransition {
  -webkit-transition: unset;
  transition: unset;
}

/* full screen */
.ol-ext-dialog.ol-fullscreen-dialog form {
  top: 1em;
  -webkit-transform: none;
          transform: none;
  left: 1em;
  bottom: 1em;
  right: 1em;
  max-width: calc(66.6% - 2em);
  text-align: center;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  color: #fff;
}
.ol-ext-dialog.ol-fullscreen-dialog form .ol-closebox {
  top: 0;
  right: 0;
  font-size: 2em;
}
.ol-ext-dialog.ol-fullscreen-dialog .ol-closebox:before,
.ol-ext-dialog.ol-fullscreen-dialog .ol-closebox:after {
  border: .1em solid currentColor;
}
.ol-ext-dialog.ol-fullscreen-dialog img,
.ol-ext-dialog.ol-fullscreen-dialog video {
  max-width: 100%;
}

/* Fullscreen dialog */
body > .ol-ext-dialog .ol-content {
  max-height: calc(100vh - 10em);
}

body > .ol-ext-dialog > form {
  overflow: visible;
}
.ol-editbar .ol-button button {
  position: relative;
  display: inline-block;
  font-style: normal;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: middle;
}
.ol-editbar .ol-button button:before, 
.ol-editbar .ol-button button:after {
  content: "";
  border-width: 0;
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: currentColor;
}
.ol-editbar .ol-button button:focus {
  outline: none;
}

.ol-editbar .ol-selection > button:before {
  width: .6em;
  height: 1em;
  background-color: transparent;
  border: .5em solid currentColor;
  border-width: 0 .25em .65em;
  border-color: currentColor transparent;
  -webkit-box-shadow:0 0.6em 0 -0.23em;
          box-shadow:0 0.6em 0 -0.23em;
  top: .35em;
  left: .5em;
  -webkit-transform: translate(-50%, -50%) rotate(-30deg);
          transform: translate(-50%, -50%) rotate(-30deg);
}
.ol-editbar .ol-selection0 > button:after {
  width: .28em;
  height: .6em;
  background-color: transparent;
  border: .5em solid currentColor;
  border-width: 0 .05em .7em;
  border-color: currentColor transparent;
  top: .5em;
  left: .7em;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ol-editbar .ol-delete button:after,
.ol-editbar .ol-delete button:before {
  width: 1em;
  height: .2em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.ol-editbar .ol-delete button:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.ol-editbar .ol-info button:before {
  width: .25em;
  height: .6em;
  border-radius: .03em;
  top: .47em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ol-editbar .ol-info button:after {
  width: .25em;
  height: .2em;
  border-radius: .03em;
  -webkit-box-shadow: -0.1em 0.35em, -0.1em 0.82em, 0.1em 0.82em;
          box-shadow: -0.1em 0.35em, -0.1em 0.82em, 0.1em 0.82em;
  top: .12em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.ol-editbar .ol-drawpoint button:before {
  width: .7em;
  height: .7em;
  border-radius: 50%;
  border: .15em solid currentColor;
  background-color: transparent;
  top: .2em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ol-editbar .ol-drawpoint button:after {
  width: .4em;
  height: .4em;
  border: .15em solid currentColor;
  border-color: currentColor transparent;
  border-width: .4em .2em 0;
  background-color: transparent;
  top: .8em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.ol-editbar .ol-drawline > button:before,
.ol-editbar .ol-drawpolygon > button:before,
.ol-editbar .ol-drawhole > button:before {
  width: .8em;
  height: .8em;
  border: .13em solid currentColor;
  background-color: transparent;
  border-width: .2em .13em .09em;
  top: .2em;
  left: .25em;
  -webkit-transform: rotate(10deg) perspective(1em) rotateX(40deg);
          transform: rotate(10deg) perspective(1em) rotateX(40deg);
}
.ol-editbar .ol-drawline > button:before {
  border-bottom: 0;
}
.ol-editbar .ol-drawline > button:after,
.ol-editbar .ol-drawhole > button:after,
.ol-editbar .ol-drawpolygon > button:after {
  width: .3em;
  height: .3em;
  top: 0.2em;
  left: .25em;
  -webkit-box-shadow: -0.2em 0.55em, 0.6em 0.1em, 0.65em 0.7em;
          box-shadow: -0.2em 0.55em, 0.6em 0.1em, 0.65em 0.7em;
}
.ol-editbar .ol-drawhole > button:after {
  -webkit-box-shadow: -0.2em 0.55em, 0.6em 0.1em, 0.65em 0.7em, 0.25em 0.35em;
          box-shadow: -0.2em 0.55em, 0.6em 0.1em, 0.65em 0.7em, 0.25em 0.35em;
}


.ol-editbar .ol-offset > button i,
.ol-editbar .ol-transform > button i {
  position: absolute;
  width: .9em;
  height: .9em;
  overflow: hidden;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-editbar .ol-offset > button i{
  width: .8em;
  height: .8em;
}

.ol-editbar .ol-offset > button i:before,
.ol-editbar .ol-transform > button i:before,
.ol-editbar .ol-transform > button i:after {
  content: "";
  height: 1em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  -webkit-box-shadow: 0.5em 0 0 0.1em, -0.5em 0 0 0.1em;
          box-shadow: 0.5em 0 0 0.1em, -0.5em 0 0 0.1em;
  width: .1em;
  position: absolute;
  background-color: currentColor;
}
.ol-editbar .ol-offset > button i:before{
  -webkit-box-shadow: 0.45em 0 0 0.1em, -0.45em 0 0 0.1em;
          box-shadow: 0.45em 0 0 0.1em, -0.45em 0 0 0.1em;
}
.ol-editbar .ol-transform > button i:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.ol-editbar .ol-split > button:before {
  width: .3em;
  height: .3em;
  top: .81em;
  left: .75em;
  border-radius: 50%;
  -webkit-box-shadow: 0.1em -0.4em, -0.15em -0.25em;
          box-shadow: 0.1em -0.4em, -0.15em -0.25em;
}
.ol-editbar .ol-split > button:after {
  width: .8em;
  height: .8em;
  top: .15em;
  left: -.1em;
  border: .1em solid currentColor;
  border-width: 0 .2em .2em 0;
  background-color: transparent;
  border-radius: .1em;
  -webkit-transform: rotate(20deg) scaleY(.6) rotate(-45deg);
          transform: rotate(20deg) scaleY(.6) rotate(-45deg);
}

.ol-editbar .ol-drawregular > button:before {
  width: .9em;
  height: .9em;
  top: 50%;
  left: 50%;
  border: .1em solid currentColor;
  background-color: transparent;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-editbar .ol-drawregular .ol-bar .ol-text-button > div > div > div {
  border: .5em solid currentColor;
  border-color: transparent currentColor;
  display: inline-block;
  cursor: pointer;
  vertical-align: text-bottom;
}
.ol-editbar .ol-drawregular .ol-bar:before,
.ol-control.ol-bar.ol-editbar .ol-drawregular .ol-bar {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ol-editbar .ol-drawregular .ol-bar .ol-text-button {
  min-width: 6em;
  text-align: center;
}
.ol-editbar .ol-drawregular .ol-bar .ol-text-button > div > div > div:first-child {
  border-width: .5em .5em .5em 0;
  margin: 0 .5em 0 0;
}
.ol-editbar .ol-drawregular .ol-bar .ol-text-button > div > div > div:last-child {
  border-width: .5em 0 .5em .5em;
  margin: 0 0 0 .5em;
}

.ol-gauge {
  top: 0.5em;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ol-gauge > * {
  display: inline-block;
  vertical-align: middle;
}
.ol-gauge > span {
  margin: 0 0.5em;
}
.ol-gauge > div {
  display: inline-block;
  width: 200px;
  border: 1px solid rgba(0,60,136,.5);
  border-radius: 3px;
  padding:1px;
}
.ol-gauge button {
  height: 0.8em;
  margin:0;
  max-width:100%;
}

.ol-control.ol-bookmark 
{	top: 0.5em;
	left: 3em;
}
.ol-control.ol-bookmark button
{	position: relative;
}
.ol-control.ol-bookmark > button::before
{	content: "";
	position: absolute;
	border-width: 10px 5px 4px;
	border-style: solid;
	border-color: #fff;
	border-bottom-color: transparent;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 0;
}

.ol-control.ol-bookmark > div
{	display: none;
	min-width: 5em;
}
.ol-control.ol-bookmark input
{	font-size: 0.9em;
	margin: 0.1em 0 ;
	padding: 0 0.5em;
}
.ol-control.ol-bookmark ul
{	margin:0;
	padding: 0;
	list-style: none;
	min-width: 10em;
}
.ol-control.ol-bookmark li
{	color: rgba(0,60,136,0.8);
	font-size: 0.9em;
	padding: 0 0.2em 0 0.5em;
	cursor: default;
	clear:both;
}

.ol-control.ol-bookmark li:hover
{	background-color: rgba(0,60,136,.5);
	color: #fff;
}

.ol-control.ol-bookmark > div button
{	width: 1em;
	height: 0.8em;
	float: right;
	background-color: transparent;
	cursor: pointer;
	border-radius: 0;
}
.ol-control.ol-bookmark > div button:before
{	content: "\2A2F";
    color: #936;
	font-size: 1.2em;
	line-height: 1em;
	border-radius: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ol-bookmark ul li button,
.ol-bookmark input
{	display: none;
}
.ol-bookmark.ol-editable ul li button,
.ol-bookmark.ol-editable input
{	display: block;
}


.ol-control.ol-geobt {
  top: auto;
  left: auto;
  right: .5em;
  bottom: 3em;
}
.ol-touch .ol-control.ol-geobt {
  bottom: 3.5em;
}
.ol-control.ol-geobt button:before {
  content: "";
  position: absolute;
  background: transparent;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: .16em solid currentColor;
  width: .4em;
  height: .4em;
  border-radius: 50%;
}
.ol-control.ol-geobt button:after {
  content: "";
  position: absolute;
  width: .2em;
  height: .2em;
  background: transparent;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-box-shadow: .42em 0, -.42em 0, 0 .42em, 0 -.42em;
          box-shadow: .42em 0, -.42em 0, 0 .42em, 0 -.42em;
}

.ol-control.ol-bar.ol-geobar .ol-control {
	display: inline-block;
	vertical-align: middle;
}

.ol-control.ol-bar.ol-geobar .ol-bar {
  display: none;
}
.ol-bar.ol-geobar.ol-active .ol-bar {
  display: inline-block;
}

.ol-bar.ol-geobar .geolocBt button:before,
.ol-bar.ol-geobar .geolocBt button:after {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid transparent;
  border-width: 0.3em 0.8em 0 0.2em;
  border-color: #fff transparent transparent;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  top: .45em;
  left: 0.15em;
  font-size: 1.2em;
}
.ol-bar.ol-geobar .geolocBt button:after {
  border-width: 0 0.8em .3em 0.2em;
  border-color: transparent transparent #fff;
	-webkit-transform: rotate(-61deg);
	transform: rotate(-61deg);
}

.ol-bar.ol-geobar .startBt button:before {
  content: "";
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  background-color: #800;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.ol-bar.ol-geobar .pauseBt button:before,
.ol-bar.ol-geobar .pauseBt button:after {
  content: "";
  display: block;
  position: absolute;
  width: .25em;
  height: 1em;
  background-color: #fff;
  top: 50%;
  left: 35%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.ol-bar.ol-geobar .pauseBt button:after {
  left: 65%;
}

.ol-control.ol-bar.ol-geobar .centerBt,
.ol-control.ol-bar.ol-geobar .pauseBt,
.ol-bar.ol-geobar.pauseTrack .startBt,
.ol-bar.ol-geobar.centerTrack .startBt,
.ol-bar.ol-geobar.centerTrack.pauseTrack .pauseBt,
.ol-bar.ol-geobar.centerTrack .pauseBt {
  display: none;
}
.ol-bar.ol-geobar.pauseTrack .pauseBt,
.ol-bar.ol-geobar.centerTrack .centerBt{
  display: inline-block;
}

.ol-control.ol-globe
{	position: absolute;
	left: 0.5em;
	bottom: 0.5em;
	border-radius: 50%;
	opacity: 0.7;
	transform: scale(0.5);
	transform-origin: 0 100%;
	-webkit-transform: scale(0.5);
	-webkit-transform-origin: 0 100%;
}
.ol-control.ol-globe:hover
{	opacity: 0.9;
}

.ol-control.ol-globe .panel
{	display:block;
	width:170px;
	height:170px;
	background-color:#fff;
	cursor: pointer;
	border-radius: 50%;
	overflow: hidden;
	-webkit-box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
	        box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
}
.ol-control.ol-globe .panel .ol-viewport
{	border-radius: 50%;
}

.ol-control.ol-globe .ol-pointer
{	display: block;
	background-color: #fff;
	width:10px;
	height: 10px;
	border:10px solid red;
	position: absolute;
	top: 50%;
	left:50%;
	transform: translate(-15px, -40px);
	-webkit-transform: translate(-15px, -40px);
	border-radius: 50%;
	z-index:1;
	transition: opacity 0.15s, top 0s, left 0s;
	-webkit-transition: opacity 0.15s, top 0s, left 0s;
}
.ol-control.ol-globe .ol-pointer.hidden
{	opacity:0;
	transition: opacity 0.15s, top 3s, left 5s;
	-webkit-transition: opacity 0.15s, top 3s, left 5s;
}

.ol-control.ol-globe .ol-pointer::before
{	border-radius: 50%;
	-webkit-box-shadow: 6px 6px 10px 5px #000;
	        box-shadow: 6px 6px 10px 5px #000;
	content: "";
	display: block;
	height: 0;
	left: 0;
	position: absolute;
	top: 23px;
	width: 0;
}
.ol-control.ol-globe .ol-pointer::after
{	content:"";
	width:0;
	height:0;
	display: block;
	position: absolute;
	border-width: 20px 10px 0;
	border-color: red transparent;
	border-style: solid;
	left: -50%;
	top: 100%;
}

.ol-control.ol-globe .panel::before {
  border-radius: 50%;
  -webkit-box-shadow: -20px -20px 80px 2px rgba(0, 0, 0, 0.7) inset;
          box-shadow: -20px -20px 80px 2px rgba(0, 0, 0, 0.7) inset;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.ol-control.ol-globe .panel::after {
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px 7px rgba(255, 255, 255, 1);
          box-shadow: 0 0 20px 7px rgba(255, 255, 255, 1);
  content: "";
  display: block;
  height: 0;
  left: 23%;
  position: absolute;
  top: 20%;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  width: 20%;
  z-index: 1;
}


.ol-control.ol-globe.ol-collapsed .panel
{	display:none;
}

.ol-control-top.ol-globe
{	bottom: auto;
	top: 5em;
	transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}
.ol-control-right.ol-globe
{	left: auto;
	right: 0.5em;
	transform-origin: 100% 100%;
	-webkit-transform-origin: 100% 100%;
}
.ol-control-right.ol-control-top.ol-globe
{	left: auto;
	right: 0.5em;
	transform-origin: 100% 0;
	-webkit-transform-origin: 100% 0;
}

.ol-gridreference
{	background: #fff;
	border: 1px solid #000;
	overflow: auto;
	max-height: 100%;
	top:0;
	right:0;
}
.ol-gridreference input
{	width:100%;
}
.ol-gridreference ul
{	margin:0;
	padding:0;
	list-style: none;
} 
.ol-gridreference li
{	padding: 0 0.5em;
	cursor: pointer;
}
.ol-gridreference ul li:hover 
{	background-color: #ccc;
}
.ol-gridreference li.ol-title,
.ol-gridreference li.ol-title:hover
{	background:rgba(0,60,136,.5);
	color:#fff;
	cursor:default;
}
.ol-gridreference ul li .ol-ref
{	margin-left: 0.5em;
}
.ol-gridreference ul li .ol-ref:before
{	content:"(";
}
.ol-gridreference ul li .ol-ref:after
{	content:")";
}

.ol-control.ol-imageline {
  bottom:0;
  left: 0;
  right: 0;
  padding: 0;
  overflow: visible;
  -webkit-transition: .3s;
  transition: .3s;
  border-radius: 0;
}
.ol-control.ol-imageline.ol-collapsed {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.ol-imageline > div {
  height: 4em;
  position: relative;
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100%;
}
.ol-imageline > div.ol-move {
  scroll-behavior: unset;
}

.ol-control.ol-imageline button {
  position: absolute;
  top: -1em;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  margin: .65em;
  -webkit-box-shadow: 0 0 0 0.15em rgba(255,255,255,.4);
          box-shadow: 0 0 0 0.15em rgba(255,255,255,.4);
}
.ol-control.ol-imageline button:before {
  content: '';
  position: absolute;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
          transform: translate(-50%, -50%) rotate(135deg);
  top: 40%;
  left: 50%;
  width: .4em;
  height: .4em;
  border: .1em solid currentColor;
  border-width: .15em .15em 0 0;
}
.ol-control.ol-imageline.ol-collapsed button:before {
  top: 60%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.ol-imageline,
.ol-imageline:hover {
  background-color: rgba(0,0,0,.75);
}

.ol-imageline.ol-arrow:after,
.ol-imageline.ol-arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: .2em;
  border-color: #fff #000;
  border-width: 1em .6em 1em 0;
  border-style: solid;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  opacity: .8;
  pointer-events: none;
  -webkit-box-shadow: -0.6em 0 0 1em #fff;
          box-shadow: -0.6em 0 0 1em #fff;
}
.ol-imageline.ol-arrow:after {
  border-width: 1em 0 1em .6em;
  left: auto;
  right: .2em;
  -webkit-box-shadow: 0.6em 0 0 1em #fff;
          box-shadow: 0.6em 0 0 1em #fff;
}
.ol-imageline.ol-scroll0.ol-arrow:before {
  display: none;
}
.ol-imageline.ol-scroll1.ol-arrow:after {
  display: none;
}


.ol-imageline .ol-image {
  position: relative;
  height: 100%;
  display: inline-block;
  cursor: pointer;
}
.ol-imageline img {
  max-height: 100%;
  border: .25em solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  opacity: 0;
  -webkit-transition: 1s;
  transition: 1s;
}
.ol-imageline img.ol-loaded {
  opacity:1;
}

.ol-imageline .ol-image.select {
  background-color: #fff;
}
.ol-imageline .ol-image span {
  position: absolute;
  width: 125%;
  max-height: 2.4em;
  left: 50%;
  bottom: 0;
  display: none;
  color: #fff;
  background-color: rgba(0,0,0,.5);
  font-size: .8em;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  line-height: 1.2em;
  -webkit-transform: translateX(-50%) scaleX(.8);
          transform: translateX(-50%) scaleX(.8);
}
/*
.ol-imageline .ol-image.select span,
*/
.ol-imageline .ol-image:hover span {
  display: block;
}

.ol-control.ol-routing.ol-isochrone .ol-method-time,
.ol-control.ol-routing.ol-isochrone .ol-method-distance,
.ol-control.ol-routing.ol-isochrone > button {
  position: relative;
}
.ol-control.ol-routing.ol-isochrone .ol-method-time:before,
.ol-control.ol-routing.ol-isochrone > button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: .1em solid currentColor;
  width: .8em;
  height: .8em;
  border-radius: 50%;
  -webkit-box-shadow: 0 -0.5em 0 -0.35em, 0.4em -0.35em 0 -0.35em;
          box-shadow: 0 -0.5em 0 -0.35em, 0.4em -0.35em 0 -0.35em;
  clip: unset;
}
.ol-control.ol-routing.ol-isochrone .ol-method-time:after,
.ol-control.ol-routing.ol-isochrone > button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-60deg);
          transform: translate(-50%, -50%) rotate(-60deg);
  border-radius: 50%;
  border: .3em solid transparent;
  border-right-color: currentColor;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  clip: unset;
}

.ol-control.ol-routing.ol-isochrone .ol-method-distance:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-30deg);
          transform: translate(-50%, -50%) rotate(-30deg);
  width: 1em;
  height: .5em;
  border: .1em solid currentColor;
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}
.ol-control.ol-routing.ol-isochrone .ol-method-distance:after {
  content: '';
  position: absolute;
  width: .1em;
  height: .15em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-30deg);
          transform: translate(-50%, -50%) rotate(-30deg);
  -webkit-box-shadow: inset 0 -0.15em, 0 0.1em, 0.25em 0.1em, -0.25em 0.1em;
          box-shadow: inset 0 -0.15em, 0 0.1em, 0.25em 0.1em, -0.25em 0.1em;
}

.ol-control.ol-routing.ol-isochrone .ol-direction-direct:before,
.ol-control.ol-routing.ol-isochrone .ol-direction-reverse:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 30%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: .3em;
  height: .3em;
  border-radius: 50%;
  border: .1em solid currentColor;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0.25em 0 0 -0.05em;
          box-shadow: 0.25em 0 0 -0.05em;
}
.ol-control.ol-routing.ol-isochrone .ol-direction-direct:after,
.ol-control.ol-routing.ol-isochrone .ol-direction-reverse:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 70%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: .4em solid transparent;
  border-width: .4em 0 .4em .4em;
  border-color: transparent currentColor;
}
.ol-control.ol-routing.ol-isochrone .ol-direction-reverse:after {
  border-width: .4em .4em .4em 0;
}

.ol-control.ol-isochrone.ol-collapsed .content {
  display: none;
}
.ol-control.ol-isochrone input[type="number"] {
  width: 3em;
  text-align: right;
  margin: 0 .1em;
}
.ol-control.ol-isochrone .ol-distance input[type="number"] {
  width: 5em;
}

.ol-isochrone .ol-time,
.ol-isochrone .ol-distance {
  display: none;
}
.ol-isochrone .ol-time.selected,
.ol-isochrone .ol-distance.selected {
  display: block;
}

.ol-control.ol-layerswitcher-popup {
  position: absolute;
  right: 0.5em;
  text-align: left;
  top: 3em;
}
.ol-control.ol-layerswitcher-popup .panel {
  clear:both;
  background:#fff;
}

.ol-layerswitcher-popup .panel {
  list-style: none;
  padding: 0.25em;
  margin:0;
  overflow: hidden;
}

.ol-layerswitcher-popup .panel ul {
  list-style: none;
  padding: 0 0 0 20px;
  overflow: hidden;
}

.ol-layerswitcher-popup.ol-collapsed .panel {
  display:none;
}
.ol-layerswitcher-popup.ol-forceopen .panel {
  display:block;
}

.ol-layerswitcher-popup button  {
  background-color: white;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  height: 38px;
  width: 38px;
}

.ol-layerswitcher-popup li {
  color:#369;
  padding:0.25em 1em;
  font-family:"Trebuchet MS",Helvetica,sans-serif;
  cursor:pointer;
}
.ol-layerswitcher-popup li.ol-header {
  display: none;
}
.ol-layerswitcher-popup li.select,
.ol-layerswitcher-popup li.ol-visible {
  background:rgba(0, 60, 136, 0.7);
  color:#fff;
}
.ol-layerswitcher-popup li:hover {
  background:rgba(0, 60, 136, 0.9);
  color:#fff;
}

.ol-control.ol-layerswitcher.ol-layer-shop {
  height: calc(100% - 4em);
  max-height: unset;
  max-width: 16em;
  background-color: transparent;
  pointer-events: none!important;
  overflow: visible;
}
.ol-control.ol-layerswitcher > * {
  pointer-events: auto;
}

.ol-control.ol-layer-shop > button,
.ol-control.ol-layer-shop .panel-container {
  -webkit-box-shadow: 0 0 0 3px rgba(255,255,255,.5);
          box-shadow: 0 0 0 3px rgba(255,255,255,.5);
}
.ol-control.ol-layerswitcher.ol-layer-shop .panel-container {
  overflow-y: scroll;
  max-height: calc(100% - 6.5em);
  border: 2px solid #369;
  border-width: 2px 0;
  padding: 0;
}
.ol-control.ol-layer-shop .panel {
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: .25em .5em;
}
.ol-control.ol-layerswitcher.ol-layer-shop .panel-container.ol-scrolldiv {
  overflow: hidden;
}
.ol-control.ol-layer-shop .ol-scroll {
  background-color: rgba(0,0,0,.3);
  opacity: .5;
}
.ol-layerswitcher.ol-layer-shop ul.panel li.ol-header {
  display: none;
}
.ol-layerswitcher.ol-layer-shop ul.panel li {
  margin-right: 0;
  padding-right: 0;
}
.ol-layerswitcher.ol-layer-shop .layerup {
  height: 1.5em;
  width: 1.4em;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  background-color: transparent;
  color: rgba(0,60,136,1);
}
.ol-layerswitcher.ol-layer-shop .layerup:hover {
  background-color: rgba(0,60,136,.3);
}
.ol-layerswitcher.ol-layer-shop .layerup:before {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0;
  background-color: currentColor;
  width: 1em;
  height: 2px;
  -webkit-box-shadow: 0 -4px, 0 4px;
          box-shadow: 0 -4px, 0 4px;
}
.ol-layerswitcher.ol-layer-shop .layerup:after {
  content: unset;
}

.ol-control.ol-layer-shop .ol-title-bar {
  background-color: rgba(255,255,255,.5);
  font-size: .9em;
  height: calc(2.8em - 4px);
  max-width: 14.6em;
  padding: .7em .5em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  -webkit-transform: scaleY(1.1);
          transform: scaleY(1.1);
  -webkit-transition: width 0s, -webkit-transform .1s;
  transition: width 0s, -webkit-transform .1s;
  transition: transform .1s, width 0s;
  transition: transform .1s, width 0s, -webkit-transform .1s;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}
.ol-control.ol-layer-shop:hover .ol-title-bar {
  background-color: rgba(255,255,255,.7);
}
.ol-control.ol-layer-shop.ol-collapsed .ol-title-bar {
  max-width: 10em;
  -webkit-transform: scale(.9, 1.1);
          transform: scale(.9, 1.1);
}
.ol-control.ol-layer-shop.ol-forceopen .ol-title-bar {
  max-width: 14.6em;
  -webkit-transform: scaleY(1.1);
          transform: scaleY(1.1);
}

.ol-control.ol-layer-shop .ol-bar {
  position: relative;
  height: 1.75em;
  clear: both;
  -webkit-box-shadow: 0 0 0 3px rgba(255,255,255,.5);
          box-shadow: 0 0 0 3px rgba(255,255,255,.5);
  background-color: #fff;
  text-align: right;
  z-index: 10;
}
.ol-control.ol-layer-shop.ol-collapsed .ol-scroll,
.ol-control.ol-layer-shop.ol-collapsed .ol-bar {
  border-width: 2px 0 0;
  display: none;
}
.ol-control.ol-layer-shop.ol-forceopen .ol-scroll,
.ol-control.ol-layer-shop.ol-forceopen .ol-bar  {
  display: block;
}
.ol-control.ol-layer-shop .ol-bar > * {
  font-size: .9em;
  display: inline-block;
  vertical-align: middle;
  margin-top: .25em;
  background-color: transparent;
}

.ol-layer-shop .ol-bar .ol-button,
.ol-touch .ol-layer-shop .ol-bar .ol-button {
  position: relative;
  top: unset;
  left: unset;
  bottom: unset;
  right: unset;
  margin: 0;
}
.ol-layer-shop .ol-bar button {
  background-color: #fff;
  color: rgba(0,60,136,1)
}
.ol-layer-shop .ol-bar button:hover {
  background-color: rgba(0,60,136,.2);
}

/* Touch device */
.ol-touch .ol-layerswitcher.ol-layer-shop > button {
  font-size: 1.7em;
}
.ol-touch .ol-layer-shop .ol-bar {
  height: 2em;
}
.ol-touch .ol-layer-shop .ol-control button {
  font-size: 1.4em;
}
.ol-touch .ol-control.ol-layer-shop .panel {
  max-height: calc(100% - 7em);
}
.ol-touch .ol-control.ol-layer-shop .panel label {
  height: 1.8em;
}
.ol-touch .ol-control.ol-layer-shop .panel label span {
  margin-left: .5em;
  padding-top: .25em;
}
.ol-touch .ol-control.ol-layer-shop .panel label:before,
.ol-touch .ol-control.ol-layer-shop .panel label:after {
  font-size: 1.3em;
  z-index: 1;
}

.ol-control.ol-layerswitcher {
  position: absolute;
  right: 0.5em;
  text-align: left;
  top: 3em;
  max-height: calc(100% - 6em);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.ol-control.ol-layerswitcher .ol-switchertopdiv,
.ol-control.ol-layerswitcher .ol-switcherbottomdiv {
  display: block
}
.ol-control.ol-layerswitcher.ol-collapsed .ol-switchertopdiv,
.ol-control.ol-layerswitcher.ol-collapsed .ol-switcherbottomdiv {
  display: none;
}
.ol-layerswitcher.ol-forceopen.ol-collapsed .ol-switchertopdiv,
.ol-layerswitcher.ol-forceopen.ol-collapsed .ol-switcherbottomdiv {
  display: block;
}

.ol-control.ol-layerswitcher .ol-switchertopdiv,
.ol-control.ol-layerswitcher .ol-switcherbottomdiv {
  position: absolute;
  top:0;
  left:0;
  right:0;
  height: 45px;
  background: #fff; 
  z-index:2;
  opacity:1;
  cursor: pointer;
  border-top:2px solid transparent;
  border-bottom:2px solid #369;
  margin:0 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-control.ol-layerswitcher .ol-switcherbottomdiv {
  top: auto;
  bottom: 0;
  height: 2em;
  border-top:2px solid #369;
  border-bottom:2px solid transparent;
}
.ol-control.ol-layerswitcher .ol-switchertopdiv:before,
.ol-control.ol-layerswitcher .ol-switcherbottomdiv:before {
  content:"";
  position: absolute;
  left:50%;
  top:50%;
  border:10px solid transparent;
  width:0;
  height:0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  opacity:0.8;
}

.ol-control.ol-layerswitcher .ol-switchertopdiv:hover:before,
.ol-control.ol-layerswitcher .ol-switcherbottomdiv:hover:before {
  opacity:1;
}
.ol-control.ol-layerswitcher .ol-switchertopdiv:before {
  border-bottom-color: #369;
  border-top: 0;
}
.ol-control.ol-layerswitcher .ol-switcherbottomdiv:before {
  border-top-color: #369;
  border-bottom: 0;
}

.ol-control.ol-layerswitcher .panel-container {
  background-color: #fff;
  border-radius: 0 0 2px 2px;
  clear: both;
  display: block; /* display:block to show panel on over */
  padding: 0.5em 0.5em 0;
}

.ol-layerswitcher .panel {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: Tahoma,Geneva,sans-serif;
  font-size:0.9em;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  position: relative;
  top:0;
}

.ol-layerswitcher .panel ul {
  list-style: none;
  padding: 0 0 0 20px;
  overflow: hidden;
  clear: both;
}

/** Customize checkbox
*/
.ol-layerswitcher input[type="radio"],
.ol-layerswitcher input[type="checkbox"] {
  display:none;
}

.ol-layerswitcher .panel li {
  -weblit-transition: -webkit-transform 0.2s linear;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  clear: both;
  display: block;
  border:1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-layerswitcher .panel li.ol-layer-select {
  background-color: rgba(0,60,136,.2);
  margin: 0 -.5em;
  padding: 0 .5em
}
/* drag and drop */
.ol-layerswitcher .panel li.drag {
  opacity: 0.5;
  transform:scale(0.8);
  -webkit-transform:scale(0.8);
}
.ol-dragover {
  background:rgba(51,102,153,0.5);
  opacity:0.8;
}
.ol-layerswitcher .panel li.forbidden,
.forbidden .ol-layerswitcher-buttons div,
.forbidden .layerswitcher-opacity div {
  background:rgba(255,0,0,0.5);
  color:#f00!important;
}

/* cursor management */
.ol-layerswitcher.drag,
.ol-layerswitcher.drag * {
  cursor:not-allowed!important;
  cursor:no-drop!important;
}
.ol-layerswitcher.drag .panel li.dropover,
.ol-layerswitcher.drag .panel li.dropover * {
  cursor: pointer!important;
  cursor: n-resize!important;
  cursor: ns-resize!important;
  cursor: -webkit-grab!important;
  cursor: grab!important;
  cursor: -webkit-grabbing!important;
  cursor: grabbing!important;
}

.ol-layerswitcher .panel li.dropover {
  background: rgba(51, 102, 153, 0.5);
}

.ol-layerswitcher .panel li label {
  display: inline-block;
  height: 1.4em;
  max-width: 12em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 0 0 1.7em;
  position: relative;
}

.ol-layerswitcher .panel li label span {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: .2em;
}
.ol-layerswitcher [type="radio"] + label:before,
.ol-layerswitcher [type="checkbox"] + label:before,
.ol-layerswitcher [type="radio"]:checked + label:after,
.ol-layerswitcher [type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  left: 0.1em; top: 0.1em;
  width: 1.2em; height: 1.2em; 
  border: 2px solid #369;
  background: #fff;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
}

.ol-layerswitcher [type="radio"] + label:before,
.ol-layerswitcher [type="radio"] + label:after {
  border-radius: 50%;
}

.ol-layerswitcher [type="radio"]:checked + label:after {
  background: #369 none repeat scroll 0 0;
  margin: 0.3em;
  width: 0.6em;
  height: 0.6em;
}

.ol-layerswitcher [type="checkbox"]:checked + label:after {
  background: transparent;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #369;
    width: 0.7em;
    height: 1em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 0.55em;
    top: -0.05em;
    -webkit-box-shadow: 1px 0px 1px 1px #fff;
            box-shadow: 1px 0px 1px 1px #fff;
}

.ol-layerswitcher .panel li.ol-layer-hidden {
  opacity: 0.6;
}

.ol-layerswitcher.ol-collapsed .panel-container {
  display:none;
}
.ol-layerswitcher.ol-forceopen .panel-container {
  display:block;
}

.ol-layerswitcher > button {
  background-color: white;
  float: right;
  z-index: 10;
  position: relative;
  font-size: 1.7em;
}
.ol-touch .ol-layerswitcher > button {
  font-size: 2.5em;
}
.ol-layerswitcher > button:before,
.ol-layerswitcher > button:after {
  content: "";
  position:absolute;
  width: .75em;
  height: .75em;
  border-radius: 0.15em;
  -webkit-transform: scaleY(.8) rotate(45deg);
  transform: scaleY(.8) rotate(45deg);
}
.ol-layerswitcher > button:before {
  background: #e2e4e1;
  top: .32em;
  left: .34em;
  -webkit-box-shadow: 0.1em 0.1em #325158;
  box-shadow: 0.1em 0.1em #325158;
}
.ol-layerswitcher > button:after {
  top: .22em;
  left: .34em;
  background: #83bcc5;
  background-image: radial-gradient( circle at .85em .6em, #70b3be 0, #70b3be .65em, #83bcc5 .65em);
}
.ol-layerswitcher-buttons {
  display:block;
  float: right;
  text-align:right;
}
.ol-layerswitcher-buttons > div {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height:1em;
  width:1em;
  margin:2px;
  line-height: 1em;
    text-align: center;
    background: #369;
    vertical-align: middle;
    color: #fff;
}

.ol-layerswitcher .panel li > div {
  display: inline-block;
  position: relative;
}

/* line break */
.ol-layerswitcher .ol-separator {
  display:block;
  width:0;
  height:0;
  padding:0;
  margin:0;
}

.ol-layerswitcher .layerup {
  float: right;
  height:2.5em;
  background-color: #369;
  opacity: 0.5;
  cursor: move;
  cursor: ns-resize;
}

.ol-layerswitcher .layerup:before,
.ol-layerswitcher .layerup:after {
  border-color: #fff transparent;
  border-style: solid;
  border-width: 0.4em 0.4em 0;
  content: "";
  height: 0;
  position: absolute;
  bottom: 3px;
  left: 0.1em;
  width: 0;
}
.ol-layerswitcher .layerup:after {
  border-width: 0 0.4em 0.4em;
  top:3px;
  bottom: auto;
}

.ol-layerswitcher .layerInfo {
  background: #369;
  border-radius: 100%;
}
.ol-layerswitcher .layerInfo:before {
  color: #fff;
  content: "i";
  display: block;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  width: 1.25em;
  position:absolute;
  left: 0;
  top: 0;
}

.ol-layerswitcher .layerTrash {
  background: #369;
}
.ol-layerswitcher .layerTrash:before {
  color: #fff;
  content: "\00d7";
  font-size:1em;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 1em;
  margin: -0.5em 0;
  position: absolute;
}

.ol-layerswitcher .layerExtent {
  background: #369;
}
.ol-layerswitcher .layerExtent:before {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  position: absolute;
  left: 6px;
  right: 2px;
  top: 6px;
  bottom: 3px;
}
.ol-layerswitcher .layerExtent:after {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  content: "";
  display: block;
  position: absolute;
  bottom: 6px;
  left: 2px;
  right: 6px;
  top: 3px;
}

.ol-layerswitcher .expend-layers,
.ol-layerswitcher .collapse-layers {
  margin: 0 2px;
  background-color: transparent;
}
.ol-layerswitcher .expend-layers:before,
.ol-layerswitcher .collapse-layers:before {
  content:"";
  position:absolute;
  top:50%;
  left:0;
  margin-top:-2px;
  height:4px;
  width:100%;
  background:#369;
}
.ol-layerswitcher .expend-layers:after {
  content:"";
  position:absolute;
  left:50%;
  top:0;
  margin-left:-2px;
  width:4px;
  height:100%;
  background:#369;
}
/*
.ol-layerswitcher .collapse-layers:before {
  content:"";
  position:absolute;
  border:0.5em solid #369;
  border-color: #369 transparent transparent;
  margin-top:0.25em;
}
.ol-layerswitcher .expend-layers:before {
  content:"";
  position:absolute;
  border:0.5em solid #369;
  border-color: transparent transparent transparent #369 ;
  margin-left:0.25em;
}
*/

.ol-layerswitcher .layerswitcher-opacity {
  position:relative;
  border: 1px solid #369;
  height: 3px;
  width: 120px;
  margin:5px 1em 10px 7px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  background: #69c;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0,60,136,0)), to(rgba(0,60,136,0.6)));
  background: linear-gradient(to right, rgba(0,60,136,0), rgba(0,60,136,0.6));
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.5);
          box-shadow: 1px 1px 1px rgba(0,0,0,0.5);
}

.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor,
.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor:before {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  background: rgba(0,60,136,0.5);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
}
.ol-touch .ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor {
  width: 26px;
  height: 26px;
}

.ol-layerswitcher .layerswitcher-opacity-label { 
  display:none;
  position: absolute;
  right: -2.5em;
  bottom: 5px;
  font-size: 0.8em;
}
.ol-layerswitcher .layerswitcher-opacity-label::after {
  content:"%";
}

.ol-layerswitcher .layerswitcher-progress {
  display:block;
  margin:-4px 1em 2px 7px;
  width: 120px;
}
.ol-layerswitcher .layerswitcher-progress div {
  background-color: #369;
  height:2px;
  display:block;
  width:0;
}

.ol-control.ol-layerswitcher-image {
  position: absolute;
  right: 0.5em;
  text-align: left;
  top: 1em;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
}
.ol-control.ol-layerswitcher-image.ol-collapsed {
  top:3em;
  -webkit-transition: none;
  transition: none;
}

.ol-layerswitcher-image .panel {
  list-style: none;
  padding: 0.25em;
  margin:0;
  overflow: hidden;
}

.ol-layerswitcher-image .panel ul {
  list-style: none;
  padding: 0 0 0 20px;
  overflow: hidden;
}

.ol-layerswitcher-image.ol-collapsed .panel {
  display:none;
}
.ol-layerswitcher-image.ol-forceopen .panel {
  display:block;
  clear:both;
}

.ol-layerswitcher-image button {
  background-color: white;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  height: 38px;
  width: 38px;
  display:none;
}

.ol-layerswitcher-image.ol-collapsed button {
  display:block;
  position:relative;
}

.ol-layerswitcher-image li {
  border-radius: 4px;
  border: 3px solid transparent;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 64px;
  height: 64px;
  margin:2px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  vertical-align: middle;
  cursor:pointer;
}
.ol-layerswitcher-image li.ol-layer-hidden {
  opacity: 0.5;
  border-color:#555;
}
.ol-layerswitcher-image li.ol-header {
  display: none;
}

.ol-layerswitcher-image li img {
  position:absolute;
  max-width:100%;
}
.ol-layerswitcher-image li.select,
.ol-layerswitcher-image li.ol-visible {
  border: 3px solid red;
}

.ol-layerswitcher-image li p {
  display:none;
}
.ol-layerswitcher-image li:hover p {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  display: block;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  height:1.2em;
  font-family:Verdana, Geneva, sans-serif;
  font-size:0.8em;
}
.ol-control.ol-legend {
  bottom: .5em;
  left: .5em;
  z-index: 1;
  max-height: 90%;
  max-width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}
.ol-control.ol-legend.ol-collapsed {
  overflow: hidden;
}
.ol-control.ol-legend button {
  position: relative;
  display: none;
}
.ol-control.ol-legend.ol-collapsed button {
  display: block;
}
.ol-control.ol-legend.ol-uncollapsible button {
  display: none;
}

.ol-control.ol-legend button.ol-closebox {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  cursor: pointer;
  z-index: 1;
}
.ol-control.ol-legend.ol-uncollapsible button.ol-closebox,
.ol-control.ol-legend.ol-collapsed button.ol-closebox {
  display: none;
}
.ol-control.ol-legend button.ol-closebox:before {
  content: "\D7";
  background: none;
  color: rgba(0,60,136,.5);
  font-size: 1.3em;
}
.ol-control.ol-legend button.ol-closebox:hover:before {
  color: rgba(0,60,136,1);
}
.ol-control.ol-legend .ol-legendImg {
  display: block;
}
.ol-control.ol-legend.ol-collapsed .ol-legendImg {
  display: none;
}
.ol-control.ol-legend.ol-uncollapsible .ol-legendImg {
  display: block;
}

.ol-control.ol-legend > button:first-child:before,
.ol-control.ol-legend > button:first-child:after {
  content: "";
  position: absolute;
  top: .25em;
  left: .2em;
  width: .2em;
  height: .2em;
  background-color: currentColor;
  -webkit-box-shadow: 0 0.35em, 0 0.7em;
          box-shadow: 0 0.35em, 0 0.7em;
}
.ol-control.ol-legend button:first-child:after {
  top: .27em;
  left: .55em;
  height: .15em;
  width: .6em;
}

ul.ol-legend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ol-control.ol-legend.ol-collapsed ul {
  display: none;
}
.ol-control.ol-legend.ol-uncollapsible ul {
  display: block;
}
ul.ol-legend li.ol-title {
  text-align: center;
  font-weight: bold;
}
ul.ol-legend li.ol-title > div:first-child {
  width: 0!important;
}
ul.ol-legend li {
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}
ul.ol-legend li div {
  display: inline-block;
  vertical-align: middle;
}

.ol-control.ol-legend .ol-legend {
  display: inline-block;
}
.ol-control.ol-legend.ol-collapsed .ol-legend {
  display: none;
}
.ol-control.ol-mapzone {
  position: absolute;
  right: 0.5em;
  text-align: left;
  top: .5em;
  max-height: calc(100% - 6em);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}

.ol-control.ol-mapzone.ol-collapsed {
  top: 3em;
}

.ol-control.ol-mapzone button {
  position: relative;
  float: right;
  margin-top: 2.2em;
}
.ol-touch .ol-control.ol-mapzone button {
  margin-top: 1.67em;
}
.ol-control.ol-mapzone.ol-collapsed button {
  margin-top: 0;
}

.ol-control.ol-mapzone button i {
  border: .1em solid currentColor;
  border-radius: 50%;
  width: .9em;
  height: .9em; 
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-control.ol-mapzone button i:before {
  content: "";
  background-color: currentColor;
  width: 0.4em;
  height: .4em;
  position: absolute;
  left: .5em;
  top: 0.3em;
  border-radius: 50%;
  -webkit-box-shadow: .05em .3em 0 -.051em currentColor,
  	-.05em -.35em 0 -.1em currentColor,
  	-.5em -.35em 0 0em currentColor,
  	-.65em .1em 0 -.03em currentColor,
  	-.65em -.05em 0 -.05em currentColor;
          box-shadow: .05em .3em 0 -.051em currentColor,
  	-.05em -.35em 0 -.1em currentColor,
  	-.5em -.35em 0 0em currentColor,
  	-.65em .1em 0 -.03em currentColor,
  	-.65em -.05em 0 -.05em currentColor
}

.ol-mapzone > div {
  position: relative;
  display: inline-block;
  width: 5em;
  height: 5em;
  margin: 0 .2em 0 0;
}
.ol-control.ol-mapzone.ol-collapsed > div {
  display: none;
}
.ol-mapzone > div p {
  margin: 0;
  position: absolute;
  bottom: 0;
  /* background: rgba(255,255,255,.5); */
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 160%;
  overflow: hidden;
  font-family: 'Lucida Grande',Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif;
  -webkit-transform: scaleX(.625);
          transform: scaleX(.625);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  cursor: default;
}

.ol-notification {
  width: 150%;
  bottom: 0;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
}
.ol-notification > div,
.ol-notification > div:hover {
  position: absolute;
  background-color: rgba(0,0,0,.8);
  color: #fff;
  bottom: 0;
  left: 33.33%;
  max-width: calc(66% - 4em);
  min-width: 5em;
  max-height: 5em;
  min-height: 1em;
  border-radius: 4px 4px 0 0;
  padding: .2em .5em;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: .3s;
  transition: .3s;
  opacity: 1;
}
.ol-notification.ol-collapsed > div {
  bottom: -5em;
  opacity: 0;
}

.ol-notification a {
  color: #9cf;
  cursor: pointer;
}

.ol-notification .ol-close,
.ol-notification .ol-close:hover {
  padding-right: 1.5em;
}

.ol-notification .closeBox {
  position: absolute;
  top: 0;
  right: 0.3em;
}
.ol-notification .closeBox:before {
  content: '\00d7';
}
.ol-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  padding: 1em;
  color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
  opacity: 0;
  display: none;
  cursor: default;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  pointer-events: none;
}

.ol-overlay.slide-up {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
}
.ol-overlay.slide-down {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.ol-overlay.slide-left
{	-webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.ol-overlay.slide-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.ol-overlay.zoom {
  top: 50%;
  left: 50%;
  opacity:0.5;
  -webkit-transform: translate(-50%,-50%) scale(0);
  transform: translate(-50%,-50%) scale(0);
}
.ol-overlay.zoomout {
  -webkit-transform: scale(3);
  transform: scale(3);
}
.ol-overlay.zoomrotate {
  top: 50%;
  left: 50%;
  opacity:0.5;
  -webkit-transform: translate(-50%,-50%) scale(0) rotate(360deg);
  transform: translate(-50%,-50%) scale(0) rotate(360deg);
}
.ol-overlay.stretch {
  top: 50%;
  left: 50%;
  opacity:0.5;
  -webkit-transform: translate(-50%,-50%) scaleX(0);
  transform: translate(-50%,-50%) scaleX(0) ;
}
.ol-overlay.stretchy {
  top: 50%;
  left: 50%;
  opacity:0.5;
  -webkit-transform: translate(-50%,-50%) scaleY(0);
  transform: translate(-50%,-50%) scaleY(0) ;
}
.ol-overlay.wipe {
  opacity: 1;
  /* clip: must be set programmatically */
  /* clip-path: use % but not crossplatform (IE) */
}
.ol-overlay.flip {
  -webkit-transform: perspective(600px) rotateY(180deg);
  transform: perspective(600px) rotateY(180deg);
}
.ol-overlay.card {
  opacity: 0.5;
  -webkit-transform: translate(-80%, 100%) rotate(-0.5turn);
  transform: translate(-80%, 100%) rotate(-0.5turn);
}
.ol-overlay.book {
  -webkit-transform: perspective(600px) rotateY(-180deg) scaleX(0.6);
  transform: perspective(600px) rotateY(-180deg) scaleX(0.6) ;
  -webkit-transform-origin: 10% 50%;
  transform-origin: 10% 50%;
}
.ol-overlay.book.visible {
  -webkit-transform-origin: 10% 50%;
  transform-origin: 10% 50%;
}

.ol-overlay.ol-visible {
  opacity:1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: none;
  transform: none;
  pointer-events: all;  
}

.ol-overlay .ol-closebox {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  z-index:1;
}
.ol-overlay .ol-closebox:before {
  content: "\274c";
  display: block;
  text-align: center;
  vertical-align: middle;
}

.ol-overlay .ol-fullscreen-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ol-overlay .ol-fullscreen-image img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ol-overlay .ol-fullscreen-image.ol-has-title img {
  padding-bottom: 3em;
}
.ol-overlay .ol-fullscreen-image p {
  background-color: rgba(0,0,0,.5);
  padding: .5em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  text-align: center;
}
.ol-control.ol-overview
{	position: absolute;
	left: 0.5em;
	text-align: left;
	bottom: 0.5em;
}

.ol-control.ol-overview .panel
{	display:block;
	width:150px;
	height:150px;
	margin:2px;
	background-color:#fff;
	border:1px solid #369;
	cursor: pointer;
}

.ol-overview:not(.ol-collapsed) button
{	position:absolute;
	bottom:2px;
	left:2px;
	z-index:2;
}

.ol-control.ol-overview.ol-collapsed .panel
{	display:none;
}

.ol-overview.ol-collapsed button:before
{	content:'\00bb';
}
.ol-overview button:before
{	content:'\00ab';
}


.ol-control-right.ol-overview
{	left: auto;
	right: 0.5em;
}
.ol-control-right.ol-overview:not(.ol-collapsed) button
{	left:auto;
	right:2px;
}
.ol-control-right.ol-overview.ol-collapsed button:before
{	content:'\00ab';
}
.ol-control-right.ol-overview button:before
{	content:'\00bb';
}

.ol-control-top.ol-overview
{	bottom: auto;
	top: 5em;
}
.ol-control-top.ol-overview:not(.ol-collapsed) button
{	bottom:auto;
	top:2px;
}

.ol-permalink
{	position: absolute;
	top:0.5em;
	right: 2.5em;
}
.ol-touch .ol-permalink
{	right: 3em;
}

.ol-permalink button
{	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AcFBjYE1ZK03gAAAUlJREFUOMuVk71KA1EQhc/NaiP+gCRpFHwGBSFlCrFVfAsbwSJCBMv06QIGJOBziI3EYAgkjU8gIloIAasIn4WzMqx34zrN7J6de+6ZmbNSgQDSfADcATPgHbgCyvonSYv8KEzWdofegH3gwmG9Ikq67sAESFzNueHThTyiEIKAmr2OJCUhhO30Aou+5aUQU2Ik65K2JC1KegohPGfUBkmvksqShnntHEcGOs60NXHfjmKz6czZTsNqbhzW+muwY2ATWAWawCOwBgxcTfvnvCPxKx4Cy5bPgBWgauRpdL2ImNlGhp3MabETm8mh94nDk4yCNE5/KTGg7xxbyhYAG0AN2AEqURIDZ0a0Fxn+LXAPXDpzRqMk6cOedz1ubdYl1b6NHgZRJe72nuu/CdSBl+yKi/zZlTnbaeXOJIesClwDU+ATeEhtX5TkCwAWUyAsHH1QAAAAAElFTkSuQmCC');
	background-position: center;
	background-repeat: no-repeat;
}
.ol-control.ol-print {
  top:.5em;
  left: 3em;
}
.ol-control.ol-print button:before {
  content: "";
  width: .9em;
  height: .35em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-box-shadow: inset 0 0 0 0.1em, inset 0.55em 0, 0 0.2em 0 -0.1em;
          box-shadow: inset 0 0 0 0.1em, inset 0.55em 0, 0 0.2em 0 -0.1em;
}
.ol-control.ol-print button:after {
  content: "";
  width: .7em;
  height: .6em;
  position: absolute;
  left: 50%;
  top: 25%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-box-shadow: inset 0 0 0 0.15em;
          box-shadow: inset 0 0 0 0.15em;
}
.ol-ext-print-dialog {
  width: 100%;
  height: 100%;
}
.ol-ext-print-dialog > form .ol-closebox {
  right: auto;
  left: 16.5em;
  z-index: 1;
  color: #999;
}
.ol-ext-print-dialog .ol-content[data-status="printing"] {
  opacity: .5;
}
.ol-ext-print-dialog .ol-content .ol-error {
  display: none;
  background: #b00;
  color: yellow;
  text-align: center;
  padding: 1em .5em;
  font-weight: bold;
  margin: 0 -1em;
}
.ol-ext-print-dialog .ol-content[data-status="error"] .ol-error {
  display: block;
}


.ol-ext-print-dialog > form,
.ol-ext-print-dialog.ol-visible > form {
  -webkit-transition: none;
  transition: none;
  top: 1em;
  left: 1em;
  bottom: 1em;
  right: 1em;
  -webkit-transform: none;
          transform: none;
  max-width: 100%;
  max-height: 100%;
  background-color: #eee;
  padding: 0;
}
.ol-ext-print-dialog .ol-print-map {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(100% - 18em);
  overflow: hidden;
}
.ol-ext-print-dialog .ol-print-map .ol-page {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-ext-print-dialog .ol-print-map .ol-page.margin {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.ol-ext-print-dialog .ol-map {
  width: 100%;
  height: 100%;
}
.ol-ext-print-dialog .ol-print-map .ol-control {
  display: none!important;
}

.ol-ext-print-dialog .ol-print-param {
  position: absolute;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  width: 18em;
  background-color: #fff;
  padding: 1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ol-ext-print-dialog .ol-print-param h2 {
  display: block;
  color: rgba(0,60,136,.7);
  font-size: 1.1em;
}
.ol-ext-print-dialog .ol-print-param ul {
  padding: 0;
  list-style: none;
}
.ol-ext-print-dialog .ol-print-param li {
  position: relative;
  margin: .5em 0;
  font-size: .9em;
}
.ol-ext-print-dialog .ol-print-param li.hidden {
  display: none;
}
.ol-ext-print-dialog .ol-print-param label {
  width: 8em;
  display: inline-block;
  vertical-align: middle;
}

.ol-ext-print-dialog select {
  outline: none;
  vertical-align: middle;
}

.ol-ext-print-dialog .ol-orientation {
  text-align: center;
}
.ol-ext-print-dialog .ol-orientation label {
  position: relative;
  width: 7em;
  cursor: pointer;
}
.ol-ext-print-dialog .ol-orientation input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.ol-ext-print-dialog .ol-orientation span {
  position: relative;
  width: 80%;
  display: block;
  padding: 3.5em 0 .2em;
}
.ol-ext-print-dialog .ol-orientation span:before {
  content: "";
  position: absolute;
  width: 2em;
  height: 2.6em;
  bottom: 1.5em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #333;
  background-color: currentColor;
  border: 1px solid currentColor;
  border-radius: 0 1em 0 0;
  opacity: .5;
  overflow: hidden;
  -webkit-box-shadow: inset 1.3em -1.91em #ddd;
          box-shadow: inset 1.3em -1.91em #ddd;
}

.ol-ext-print-dialog .ol-orientation .landscape span:before {
  width: 2.6em;
  height: 2em;
  margin: .2em 0;
  -webkit-box-shadow: inset 1.91em -1.3em #ddd;
          box-shadow: inset 1.91em -1.3em #ddd;
}
.ol-ext-print-dialog .ol-orientation input:checked + span {
  opacity: 1;
  -webkit-box-shadow: 0 0 .2em rgba(0,0,0,.5);
          box-shadow: 0 0 .2em rgba(0,0,0,.5);
}

.ol-ext-print-dialog .ol-ext-toggle-switch span {
  position: absolute;
  right: -2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ol-print-title input[type=text] {
  margin-top: .5em;
  width: calc(100% - 6em);
  margin-left: 6em;
}

.ol-ext-print-dialog .ol-size option:first-child {
  font-style: italic;
}

.ol-ext-print-dialog .ol-saveas,
.ol-ext-print-dialog .ol-savelegend {
  text-align: center;
}
.ol-ext-print-dialog .ol-saveas select,
.ol-ext-print-dialog .ol-savelegend select {
  background-color: rgba(0,60,136,.7);
  color: #fff;
  padding: .5em;
  margin: 1em 0 0;
  font-size: 1em;
  border: 0;
  font-weight: bold;
  max-width: 12em;
}
.ol-ext-print-dialog .ol-saveas select option,
.ol-ext-print-dialog .ol-savelegend select option {
  background-color: #fff;
  color: #666;
}
.ol-ext-print-dialog .ol-savelegend select {
  margin-top: 0;
}

.ol-ext-print-dialog .ol-ext-buttons {
  text-align: right;
  border-top: 1px solid #ccc;
  padding: .8em .5em;
  margin: 0 -1em;
}
.ol-ext-print-dialog button {
  font-size: 1em;
  margin: 0 .2em;
  border: 1px solid #999;
  background: none;
  padding: .3em 1em;
  color: #333;
}
.ol-ext-print-dialog button[type="submit"] {
  background-color: rgba(0,60,136,.7);
  color: #fff;
  font-weight: bold;
}

.ol-ext-print-dialog .ol-clipboard-copy {
  position: absolute;
  pointer-events: none;
  top: 0;
  background-color: rgba(0,0,0,.5);
  color: #fff;
  padding: .5em 1em;
  border-radius: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: 0s;
  transition: 0s;
  opacity: 0;
}
.ol-ext-print-dialog .ol-clipboard-copy.visible {
  -webkit-animation: 1s ol-clipboard-copy;
          animation: 1s ol-clipboard-copy;
}
.ol-ext-print-dialog .ol-print-map .ol-control.ol-canvas-control {
  display: block!important;
}
.ol-ext-print-dialog .ol-print-map .ol-control.ol-print-compass {
  display: block!important;
}
.ol-ext-print-dialog .ol-print-map .ol-control.olext-print-compass {
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  margin: 20px;
}

@-webkit-keyframes ol-clipboard-copy { 
  0% { opacity: 0; top: 0; }
  80% { opacity: 1; top: -3em; }
  100% { opacity: 0; top: -3em; }  
}

@keyframes ol-clipboard-copy { 
  0% { opacity: 0; top: 0; }
  80% { opacity: 1; top: -3em; }
  100% { opacity: 0; top: -3em; }  
}

@media print {
  body.ol-print-document {
    margin: 0!important;
    padding: 0!important;
  }
  body.ol-print-document > * {
    display: none!important;
  }
  body.ol-print-document > .ol-ext-print-dialog {
    display: block!important;
  }
  body.ol-print-document > .ol-ext-print-dialog .ol-content {
    max-height: unset!important;
  }
  .ol-ext-print-dialog > form,
  .ol-ext-print-dialog {
    position: unset;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: none!important;
    border: 0;
  }
  .ol-ext-print-dialog > form > *,
  .ol-ext-print-dialog .ol-print-param {
    display: none!important;
    background: none;
  } 
  .ol-ext-print-dialog .ol-content {
    display: block!important;
    border: 0;
    background: none;
  }
  .ol-ext-print-dialog .ol-print-map {
    position: unset; 
    background: none;
    width: auto;
    overflow: visible;
  }
  .ol-ext-print-dialog .ol-print-map .ol-page {
    -webkit-transform: none!important;
            transform: none!important;
    -webkit-box-shadow: none!important;
            box-shadow: none!important;
    position: unset;
  }
}

@media (max-width: 25em) {
  .ol-ext-print-dialog .ol-print-param {
    width: 13em;
  }
  .ol-ext-print-dialog .ol-print-map {
    width: calc(100% - 13em);
  }
  .ol-ext-print-dialog .ol-print-param .ol-print-title input[type="text"] {
    width: 100%;
    margin: 0;
  }
}
.ol-profil {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ol-control.ol-profil {
  position: absolute;
  top: 0.5em;
  right: 3em;
  text-align: right;
  overflow: hidden;
}
.ol-profil .ol-zoom-out {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1em;
  height: 1em;
  padding: 0;
  border: 1px solid #000;
  border-radius: 2px;
  cursor: pointer;
}
.ol-profil .ol-zoom-out:before {
  content: '';
  height: 2px;
  width: 60%;
  background: currentColor;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ol-profil .ol-inner  {
  position: relative;
  padding: 0.5em;
  font-size: 0.8em;
}
.ol-control.ol-profil .ol-inner {
  display: block;
  background-color: rgba(255,255,255,0.7);
  margin: 2.3em 2px 2px;
}
.ol-control.ol-profil.ol-collapsed .ol-inner {
  display: none;
}

.ol-profil canvas {
  display: block;
}
.ol-profil button {
  display: block;
  position: absolute;
  right: 2px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAYAAAALWoRrAAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AgXCR4dn7j9TAAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAz0lEQVQ4y7WTMU4CURRFz0xIpLUBEhdAY2tJYW1jaWlsXYVxDWyBhWCFCYugYgnDFPMOhTMJGf3AwHiqn/uTk5v/3gfAH6b0RH7sMiIe1Ts162z+q2lVVbd1XqijLuJk0zzP1/VxCGyApLgsy+HJphGx8DeFOm6L1bn6eVQaEW+m2amTRqx+1fkqKY2Ie0+zUx/U7WGYfNMsy57PmMMN8A1MWsWeUoPyivV8PWtPOzL7D+lYHfUtBXgHGLTCJfBxodD6k9Dsm8BLE17LobQ39nJC61aLVoVsAAAAAElFTkSuQmCC');
}

.ol-profil.ol-collapsed button {
  position: static;
}

.ol-profil .ol-profilbar,
.ol-profil .ol-profilcursor {
  position:absolute;
  pointer-events: none;
  width: 1px;
  display: none;
}
.ol-profil .ol-profilcursor {
  width: 0;
  height: 0;
}
.ol-profil .ol-profilcursor:before {
  content:"";
  pointer-events: none;
  display: block;
  margin: -2px;
  width:5px;
  height:5px;
}
.ol-profil .ol-profilbar,
.ol-profil .ol-profilcursor:before {
  background: red;
}

.ol-profil table {
  text-align: center;
  width: 100%;
}

.ol-profil table span {
  display: block;
}

.ol-profilpopup {
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0.5em;
  padding: 0 0.5em;
  position: absolute;
  top:-1em;
  white-space: nowrap;
}
.ol-profilpopup.ol-left {
  right:0;
}


.ol-profil table td {
  padding: 0 2px;
}

.ol-profil table .track-info {
  display: table-row;
}
.ol-profil table .point-info {
  display: none;
}
.ol-profil .over table .track-info {
  display: none;
}
.ol-profil .over table .point-info {
  display: table-row;
}

.ol-profil p {
  text-align: center;
  margin:0;
}

.ol-control.ol-progress-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  pointer-events: none!important;
  background-color: transparent;
}

.ol-control.ol-progress-bar > .ol-bar {
  position: absolute;
  background-color: rgba(0,60,136,.5);
  left: 0;
  bottom: 0;
  height: .5em;
  width: 0;
  -webkit-transition: width .2s;
  transition: width .2s;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ol-progress-bar > .ol-waiting {
  display: none;
}

.ol-viewport .ol-control.ol-progress-bar > .ol-waiting {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2em;
  display: block;
  -webkit-animation: 1s linear infinite ol-progress-bar-blink;
          animation: 1s linear infinite ol-progress-bar-blink;
}

@-webkit-keyframes ol-progress-bar-blink {
  0%, 30% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes ol-progress-bar-blink {
  0%, 30% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.ol-control.ol-routing {
  top: 0.5em;
  left: 3em;
  max-height: 90%;
  overflow-y: auto;
}
.ol-touch .ol-control.ol-routing {
  left: 3.5em;
}
.ol-control.ol-routing.ol-searching {
  opacity: .5;
}

.ol-control.ol-routing .ol-car,
.ol-control.ol-routing > button {
  position: relative;
}
.ol-control.ol-routing .ol-car:after,
.ol-control.ol-routing > button:after {
  content: "";
  position: absolute;
  width: .78em;
  height: 0.6em;
  border-radius: 40% 50% 0 0 / 50% 70% 0 0;
  -webkit-box-shadow: inset 0 0 0 0.065em, -0.35em 0.14em 0 -0.09em, inset 0 -0.37em, inset -0.14em 0.005em;
          box-shadow: inset 0 0 0 0.065em, -0.35em 0.14em 0 -0.09em, inset 0 -0.37em, inset -0.14em 0.005em;
  clip: rect(0 1em .5em -1em);
  top: .35em;
  left: .4em;
}
.ol-control.ol-routing .ol-car:before,
.ol-control.ol-routing > button:before {
  content: "";
  position: absolute;
  width: .28em;
  height: .28em;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 1em, 0.65em 0;
          box-shadow: inset 0 0 0 1em, 0.65em 0;
  top: 0.73em;
  left: .20em;
}
.ol-control.ol-routing .ol-pedestrian:after {
  content: "";
  position: absolute;
  width: .3em;
  height: .4em;
  top: .25em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-box-shadow: inset 0.3em 0, 0.1em 0.5em 0 -0.1em, -0.1em 0.5em 0 -0.1em, 0.25em 0.1em 0 -0.1em, -0.25em 0.1em 0 -0.1em;
          box-shadow: inset 0.3em 0, 0.1em 0.5em 0 -0.1em, -0.1em 0.5em 0 -0.1em, 0.25em 0.1em 0 -0.1em, -0.25em 0.1em 0 -0.1em;
  border-top: .2em solid transparent;
}
.ol-control.ol-routing .ol-pedestrian:before {
  content: "";
  position: absolute;
  width: .3em;
  height: .3em;
  top: .1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50%;
  background-color: currentColor;
}

.ol-control.ol-routing.ol-collapsed .content {
  display: none;
}

.ol-routing .ol-search.ol-collapsed ul {
	display: none;
}
.ol-routing .ol-search ul .copy {
  display: none;
}
.ol-routing .ol-search ul.history {
  /* display: none; */
}
.ol-routing .content .search-input > div > * {
  display: inline-block;
  vertical-align: top;
}
.ol-routing .ol-result ul {
  list-style: none;
  display: block;
}
.ol-routing .ol-result li {
  position: relative;
  min-height: 1.65em;
}
.ol-routing .ol-result li i {
  display: block;
  font-size: .8em;
  font-weight: bold;
}

.ol-routing .ol-result li:before {
  content: "";
  border: 5px solid transparent;
  position: absolute;
  left: -1.75em;
  border-bottom-color: #369;
  border-width: .6em .4em .6em;
  -webkit-transform-origin: 50% 125%;
          transform-origin: 50% 125%;
  -webkit-box-shadow: 0 0.65em 0 -0.25em #369;
          box-shadow: 0 0.65em 0 -0.25em #369;
  top: -.8em;
}
.ol-routing .ol-result li:after {
  content: "";
  position: absolute;
  width: 0.3em;
  height: .6em;
  left: -1.5em;
  background: #369;
  top: .6em;
}
.ol-routing .ol-result li.R:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ol-routing .ol-result li.FR:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ol-routing .ol-result li.L:before {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ol-routing .ol-result li.FL:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ol-routing .content > i {
  vertical-align: middle;
  margin: 0 .3em 0 .1em;
  font-style: normal;
}
.ol-routing .ol-button,
.ol-routing .ol-button:focus,
.ol-routing .ol-pedestrian,
.ol-routing .ol-car {
  font-size: 1.1em;
  position: relative;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  color: rgba(0,60,136,1);
  background-color: transparent;
  margin: 0 .1em;
  opacity: .5;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ol-routing .ol-button:hover,
.ol-routing .ol-button.selected,
.ol-routing i.selected {
  opacity: 1;
  background: transparent;
}

.ol-control.ol-routing:hover {
  background-color: rgba(255,255,255,.85);
}

.search-input > div > button:before {
  content: '\00b1';
}
.ol-viewport .ol-scale {
	left: .5em;
	bottom: 2.5em;
	text-align: center;
	-webkit-transform: scaleX(.8);
	-webkit-transform-origin: 0 0;
	transform: scaleX(.8);
	transform-origin: 0 0;
}
.ol-viewport .ol-scale input {
	background: none;
    border: 0;
    width: 8em;
    text-align: center;
}

.ol-search{
  top: 0.5em;
  left: 3em;
}
.ol-touch .ol-search {
  left: 3.5em;
}
.ol-search button {
  top: 2px;
  left: 2px;
  float: left;
}
.ol-control.ol-search > button:before {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: .7em;
  height: .7em;
  background-color: transparent;
  border: .12em solid currentColor;
  border-radius: 100%;
  top: .35em;
  left: .35em;
}
.ol-control.ol-search > button:after {
  content: "";
  position: absolute;
  top: 1.1em;
  left: .95em;
  width: .45em;
  height: .15em;
  background-color: currentColor;
  border-radius: .05em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-box-shadow: -0.18em 0 0 -0.03em;
          box-shadow: -0.18em 0 0 -0.03em;
}

.ol-search button.ol-revers {
  float: none;
  background-image: none;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  top: 0;
  left: 0;
}
.ol-search.ol-revers button.ol-revers {
  background-color: rgba(0,136,60,.5)
}

.ol-control.ol-search.ol-collapsed button.ol-revers {
  display: none;
}
.ol-search button.ol-revers:before {
  content: "";
  border: .1em solid currentColor;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  border-radius: 50%;
  width: .55em;
  height: .55em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-search button.ol-revers:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: .2em;
  height: .2em;
  background-color: transparent;
  -webkit-box-shadow: .35em 0 currentColor, 0 .35em currentColor, -.35em 0 currentColor, 0 -.35em currentColor;
          box-shadow: .35em 0 currentColor, 0 .35em currentColor, -.35em 0 currentColor, 0 -.35em currentColor;
}

.ol-search input {
  display: inline-block;
  border: 0;
  margin: 1px 1px 1px 2px;
  font-size: 1.14em;
  padding-left: 0.3em;
  height: 1.375em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.ol-touch .ol-search input,
.ol-touch .ol-search ul {
  font-size: 1.5em;
}
.ol-search.ol-revers > ul,
.ol-control.ol-search.ol-collapsed > * {
  display: none;
}
.ol-control.ol-search.ol-collapsed > button {
  display: block;
}

.ol-search ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  clear: both;
  cursor: pointer;
  max-width: 17em;
  overflow-x: hidden;
  z-index: 1;
  background: #fff;
}
/*
.ol-control.ol-search ul {
  position: absolute;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}
*/
.ol-search ul li {
  padding: 0.1em 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ol-search ul li.select,
.ol-search ul li:hover {
  background-color: rgba(0,60,136,.5);
  color: #fff;
}
.ol-search ul li img {
  float: left;
  max-height: 2em;
}
.ol-search li.copy {
    background: rgba(0,0,0,.5);
  color: #fff;
}
.ol-search li.copy a {
  color: #fff;
  text-decoration: none;
}

.ol-search.searching:before {
  content: '';
  position: absolute;
  height: 3px;
  left: 0;
  top: 1.6em;
  -webkit-animation: pulse .5s infinite alternate linear;
          animation: pulse .5s infinite alternate linear;
  background: red;
  z-index: 2;
}

@-webkit-keyframes pulse {
  0% { left:0; right: 95%; }
  50% {	left: 30%; right: 30%; }
  100% {	left: 95%; right: 0; }
}

@keyframes pulse {
  0% { left:0; right: 95%; }
  50% {	left: 30%; right: 30%; }
  100% {	left: 95%; right: 0; }
}


.ol-search.IGNF-parcelle input {
  width: 13.5em;
}
.ol-search.IGNF-parcelle input:-moz-read-only {
  background: #ccc;
  opacity: .8;
}
.ol-search.IGNF-parcelle input:read-only {
  background: #ccc;
  opacity: .8;
}
.ol-search.IGNF-parcelle.ol-collapsed-list > ul.autocomplete {
  display: none;
}

.ol-search.IGNF-parcelle label {
  display: block;
  clear: both;
}
.ol-search.IGNF-parcelle > div input,
.ol-search.IGNF-parcelle > div label {
  width: 5em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin: .1em;
  font-size: 1em;
}
.ol-search.IGNF-parcelle ul.autocomplete-page {
  margin-top:.5em;
  width:100%;
  text-align: center;
  display: none;
}
.ol-search.IGNF-parcelle.ol-collapsed-list ul.autocomplete-parcelle,
.ol-search.IGNF-parcelle.ol-collapsed-list ul.autocomplete-page {
  display: block;
}
.ol-search.IGNF-parcelle.ol-collapsed ul.autocomplete-page,
.ol-search.IGNF-parcelle.ol-collapsed ul.autocomplete-parcelle,
.ol-search.IGNF-parcelle ul.autocomplete-parcelle {
  display: none;
}
.ol-search.IGNF-parcelle ul.autocomplete-page li {
  display: inline-block;
  color: #fff;
  background: rgba(0,60,136,.5);
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  padding: .1em;
  margin: 0 .1em;
}
.ol-search.IGNF-parcelle ul.autocomplete-page li.selected {
  background: rgba(0,60,136,1);
}

/* GPS */
.ol-searchgps input.search {
  display: none;
}
.ol-control.ol-searchgps > button:first-child {
  background-image: none;
}
.ol-control.ol-searchgps > button:first-child:before {
  content: "x/y";
  position: unset;
  display: block;
  -webkit-transform: scaleX(.8);
          transform: scaleX(.8);
  border: unset;
  border-radius: 0;
  width: auto;
  height: auto;
}
.ol-control.ol-searchgps > button:first-child:after {
  content: unset;
}
.ol-control.ol-searchgps .ol-latitude,
.ol-control.ol-searchgps .ol-longitude {
  clear: both;
}
.ol-control.ol-searchgps .ol-latitude label,
.ol-control.ol-searchgps .ol-longitude label {
  width: 5.5em;
  display: inline-block;
  text-align: right;
  -webkit-transform: scaleX(.8);
          transform: scaleX(.8);
  margin: 0 -.8em 0 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ol-control.ol-searchgps .ol-latitude input,
.ol-control.ol-searchgps .ol-longitude input {
  max-width: 10em;
}

.ol-control.ol-searchgps .ol-ext-toggle-switch {
  cursor: pointer;
  float: right;
  margin: .5em;
  font-size: .9em;
}

.ol-searchgps .ol-decimal{
  display: inline-block;
  margin-right: .7em;
}
.ol-searchgps .ol-dms,
.ol-searchgps.ol-dms .ol-decimal {
  display: none;
  width: 3em;
  text-align: right;
}
.ol-searchgps.ol-dms .ol-dms {
  display: inline-block;
}

.ol-searchgps span.ol-dms {
  width: .5em;
  text-align: left;
}
.ol-searchgps.ol-control.ol-collapsed button.ol-geoloc {
  display: none;
}
.ol-searchgps button.ol-geoloc {
  top: 0;
  float: right;
  margin-right: 3px;
  background-image: none;
  position: relative;
}
.ol-searchgps button.ol-geoloc:before {
  content:"";
  position: absolute;
  left: 50%;
  top: 50%;
  width: .6em;
  height: .6em;
  border: .1em solid currentColor;
  border-radius: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.ol-searchgps button.ol-geoloc:after {
  content:"";
  position: absolute;
  left: 50%;
  top: 50%;
  width: .2em;
  height: .2em;
  background-color: transparent;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  -webkit-box-shadow: 
    .45em 0 currentColor, -.45em 0 currentColor, 0 -.45em currentColor, 0 .45em currentColor,
    .25em 0 currentColor, -.25em 0 currentColor, 0 -.25em currentColor, 0 .25em currentColor;
          box-shadow: 
    .45em 0 currentColor, -.45em 0 currentColor, 0 -.45em currentColor, 0 .45em currentColor,
    .25em 0 currentColor, -.25em 0 currentColor, 0 -.25em currentColor, 0 .25em currentColor;
}
.ol-control.ol-select {
  top: .5em;
  left: 3em;
}
.ol-touch .ol-control.ol-select {
  left: 3.5em;
}
.ol-control.ol-select > button:before {
  content: "A";
  font-size: .6em;
  font-weight: normal;
  position: absolute;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border: .2em solid currentColor;
  border-radius: 100%;
  top: .5em;
  left: .5em;
  line-height: 1em;
  text-align: center;
}
.ol-control.ol-select > button:after {
  content: "";
  position: absolute;
  top: 1.15em;
  left: 1em;
  width: .45em;
  height: .15em;
  background-color: currentColor;
  border-radius: .05em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-box-shadow: -0.18em 0 0 -0.03em;
          box-shadow: -0.18em 0 0 -0.03em;
}
.ol-select > div button {
    width: auto;
    padding: 0 .5em;
    float: right;
    font-weight: normal;
}
.ol-select .ol-delete {
    width: 1.5em;
  height: 1em;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.ol-select .ol-delete:before {
  content:'\00d7';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.ol-control.ol-select input {
  font-size: 1em;
}
.ol-control.ol-select select {
  font-size: 1em;
  max-width: 10em;
}
.ol-control.ol-select select option.ol-default {
  color: #999;
  font-style: italic;
}
.ol-control.ol-select > div {
  display: block;
}
.ol-control.ol-select.ol-collapsed > div {
  display: none;
}

.ol-control.ol-select.ol-select-check {
  max-width: 20em;
}
.ol-control.ol-select label.ol-ext-check {
  margin-right: 1em;
}
.ol-control.ol-select label.ol-ext-toggle-switch span {
  font-size: 1.1em;
}

.ol-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ol-control.ol-select input[type="search"],
.ol-control.ol-select input[type="text"]  {
  width: 8em;
}

.ol-select .ol-autocomplete {
  display: inline;
}
.ol-select .ol-autocomplete ul {
  position: absolute;
  display: block;
  background: #fff;
  border: 1px solid #999;
  min-width: 10em;
  font-size: .85em;
}
.ol-select .ol-autocomplete ul li {
  padding: 0 .5em;
}
.ol-select .ol-autocomplete ul li:hover {
  color: #fff;
  background: rgba(0,60,136,.5);
}
.ol-select ul.ol-hidden {
  display: none;
}

.ol-select-multi li > div:hover,
.ol-select-multi li > div.ol-control.ol-select {
  position: relative;
  top: unset;
  left: unset;
  background: transparent;
}
.ol-select-multi li > div  > button,
.ol-select-multi li > div  .ol-ok {
  display: none;
}
.ol-select-multi li .ol-control.ol-select.ol-collapsed > div,
.ol-select-multi li > div  > div {
  display: block;
}

.ol-control.ol-status {
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  color: #fff;
  font-size: .9em;
  padding: .3em 3em;
  border-radius: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  pointer-events: none!important;
  display: none;
}
.ol-control.ol-status.ol-visible {
  display: initial;
}
.ol-control.ol-status.ol-bottom {
  top: auto;
  bottom: 0;
}
.ol-control.ol-status.ol-left {
  top: 0;
  bottom: 0;
  padding: .3em .5em .3em 3em;
  width: auto;
}
.ol-control.ol-status.ol-right {
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  padding: .3em 3em .3em .5em;
  width: auto;
}
.ol-control.ol-status.ol-center {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ol-control.ol-storymap {
  top: .5em;
  left: .5em;
  bottom: .5em;
  max-width: 35%;
  border-radius: .5em;
  position: absolute;
  height: auto;
}
.ol-storymap {
  overflow: hidden;
  padding: 0;
  height: 100%;
  position: relative;
}
.ol-storymap > div {
  overflow: hidden;
  padding: 0;
  height: 100%;
  position: relative;
  scroll-behavior: smooth;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ol-storymap >div.ol-move {
  scroll-behavior: unset;
}

.ol-control.ol-storymap .chapter {
  padding: .5em;
}
.ol-storymap .chapter {
  cursor: pointer;
  opacity: .4;
}
.ol-storymap .chapter.ol-select {
  cursor: default;
  opacity: 1;
}

.ol-storymap .ol-scroll-top,
.ol-storymap .ol-scroll-next {
  position: relative;
  min-height: 1.7em;
  color: rgba(0,60,136,.5);
  text-align: center;
  cursor: pointer;
}
.ol-storymap .ol-scroll-next span {
  padding-bottom: 1.4em;
  display: block;
}
.ol-storymap .ol-scroll-top span {
  padding-top: 1.4em;
  display: block;
}

.ol-storymap .ol-scroll-top:before,
.ol-storymap .ol-scroll-next:before {
  content: "";
  border: .3em solid currentColor;
  border-radius: .3em;
  border-color: transparent currentColor currentColor transparent;
  width: .8em;
  height: .8em;
  display: block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
  -webkit-animation: ol-bounce-bottom 0.35s linear infinite alternate;
          animation: ol-bounce-bottom 0.35s linear infinite alternate;
  pointer-events: none;
}
.ol-storymap .ol-scroll-top:before {
  border-color: currentColor transparent transparent currentColor;
  -webkit-animation: ol-bounce-top 0.35s linear infinite alternate;
          animation: ol-bounce-top 0.35s linear infinite alternate;
}

@-webkit-keyframes ol-bounce-top{
  from {top: -.2em;}
  to   {top: .5em;}
}

@keyframes ol-bounce-top{
  from {top: -.2em;}
  to   {top: .5em;}
}
@-webkit-keyframes ol-bounce-bottom{
  from {bottom: -.2em;}
  to   {bottom: .5em;}
}
@keyframes ol-bounce-bottom{
  from {bottom: -.2em;}
  to   {bottom: .5em;}
}

.ol-storymap img[data-title] {
  cursor: pointer;
}

.ol-swipe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-touch-action: none;
      touch-action: none;
}

.ol-swipe:before {
  content: "";
  position: absolute;
  top: -5000px;
  bottom: -5000px;
  left: 50%;
  width: 4px;
  background: #fff;
  z-index:-1;
  -webkit-transform: translate(-2px, 0);
          transform: translate(-2px, 0);
}
.ol-swipe.horizontal:before {
  left: -5000px;
  right: -5000px;
  top: 50%;
  bottom: auto;
  width: auto;
  height: 4px;
}

.ol-swipe,
.ol-swipe button {
  cursor: ew-resize;
}
.ol-swipe.horizontal,
.ol-swipe.horizontal button {
  cursor: ns-resize;
}

.ol-swipe:after,
.ol-swipe button:before,
.ol-swipe button:after {
  content: "";
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 50%;
  width: 2px;
  background: rgba(255,255,255,0.8);
  transform: translate(-1px, 0);
  -webkit-transform: translate(-1px, 0);
}
.ol-swipe button:after {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
}
.ol-swipe button:before {
  transform: translateX(-7px);
  -webkit-transform: translateX(-7px);
}

.ol-control.ol-timeline {
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: .3s;
  transition: .3s;
}
.ol-control.ol-timeline.ol-collapsed {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.ol-timeline {
  overflow: hidden;
  padding: 2px 0 0;
}
.ol-timeline .ol-scroll {
  overflow: hidden;
  padding: 0;
  scroll-behavior: smooth;
  line-height: 1em;
  height: 6em;
  padding: 0 50%;
}
.ol-timeline .ol-scroll.ol-move {
  scroll-behavior: unset;
}

.ol-timeline.ol-hasbutton .ol-scroll {
  margin-left: 1.5em;
  padding: 0 calc(50% - .75em);
}
.ol-timeline .ol-buttons {
  display: none;
  position: absolute;
  top: 0;
  background: rgba(255,255,255,.5);
  width: 1.5em;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.ol-timeline.ol-hasbutton .ol-buttons {
  display: block;
}
.ol-timeline .ol-buttons button {
  font-size: 1em;
  margin: 1px;
  position: relative;
}
.ol-timeline .ol-buttons .ol-zoom-in:before,
.ol-timeline .ol-buttons .ol-zoom-out:before {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-timeline .ol-buttons .ol-zoom-out:before{
  content: '−';
}

.ol-timeline .ol-scroll > div {
  height: 100%;
  position: relative;
}

.ol-timeline .ol-scroll .ol-times {
  background: rgba(255,255,255,.5);
  height: 1em;
  bottom: 0;
  position: absolute;
  left: -1000px;
  right: -1000px;
}
.ol-timeline .ol-scroll .ol-time {
  position: absolute;
  font-size: .7em;
  color: #999;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ol-timeline .ol-scroll .ol-time.ol-year {
  color: #666;
  z-index: 1;
}
.ol-timeline .ol-scroll .ol-time:before {
  content: "";
  position: absolute;
  bottom: 1.2em;
  left: 50%;
  height: 500px;
  border-left: 1px solid currentColor;
}

.ol-timeline .ol-scroll .ol-features {
  position: absolute;
  top: 0;
  bottom: 1em;
  left: -200px;
  right: -1000px;
  margin: 0 0 0 200px;
  overflow: hidden;
}

.ol-timeline .ol-scroll .ol-feature {
  position: absolute;
  font-size: .7em;
  color: #999;
  top: 0;
  background: #fff;
  max-width: 3em;
  max-height: 2.4em;
  min-height: 1em;
  line-height: 1.2em;
  border: 1px solid #ccc;
  overflow: hidden;
  padding: 0 .5em 0 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ol-timeline.ol-zoomhover .ol-scroll .ol-feature:hover,
.ol-timeline.ol-zoomhover .ol-scroll .ol-feature.ol-select {
  z-index: 1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background: #eee;
  /* max-width: 14em!important; */
}

/* Center */
.ol-timeline .ol-center-date {
  display: none;
  position: absolute;
  left: 50%;
  height: 100%;
  width: 2px;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #f00;
  opacity: .4;
}
.ol-timeline.ol-hasbutton .ol-center-date {
  left: calc(50% + .75em);
}

/* Show center */ 
.ol-timeline.ol-pointer .ol-center-date {
  display: block;
}
.ol-timeline.ol-pointer .ol-center-date:before, 
.ol-timeline.ol-pointer .ol-center-date:after {
  content: '';
  border: 0.3em solid transparent;
  border-width: .3em .25em;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ol-timeline.ol-pointer .ol-center-date:before {
  border-top-color: #f00;
  top: 0;
}
.ol-timeline.ol-pointer .ol-center-date:after {
  border-bottom-color: #f00;
  bottom: 0
}

/* show interval */
.ol-timeline.ol-interval .ol-center-date {
  display: block;
  background-color: transparent;
  border: 0 solid #000;
  border-width: 0 10000px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  opacity: .2;
}
.ol-control.ol-videorec {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
}

.ol-control.ol-videorec button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.ol-control.ol-videorec button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: .8em;
  height: .8em;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: currentColor;
}
.ol-control.ol-videorec button.ol-start:before {
  width: .9em;
  height: .9em;
  border-radius: 50%;
  background-color: #c00;
}
.ol-control.ol-videorec button.ol-pause:before {
  width: .2em;
  background-color: transparent;
  -webkit-box-shadow: -.2em 0, .2em 0;
          box-shadow: -.2em 0, .2em 0;
}
.ol-control.ol-videorec button.ol-resume:before {
  border-style: solid;
  background: transparent;
  width: auto;
  border-width: .4em 0 .4em .6em;
  border-color: transparent transparent transparent currentColor;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ol-control.ol-videorec button.ol-stop,
.ol-control.ol-videorec button.ol-pause,
.ol-control.ol-videorec button.ol-resume,
.ol-control.ol-videorec[data-state="rec"] .ol-start,
.ol-control.ol-videorec[data-state="pause"] .ol-start {
  display: none;
}
.ol-control.ol-videorec[data-state="rec"] .ol-stop,
.ol-control.ol-videorec[data-state="pause"] .ol-stop,
.ol-control.ol-videorec[data-state="rec"] .ol-pause,
.ol-control.ol-videorec[data-state="pause"] .ol-resume {
  display: inline-block;
}

.ol-control.ol-wmscapabilities {
  top: .5em;
  right: 2.5em;
}
.ol-touch .ol-control.ol-wmscapabilities {
  right: 3em;
}
.ol-control.ol-wmscapabilities.ol-hidden {
  display: none;
}
.ol-control.ol-wmscapabilities button:before {
  content: "+";
  position: absolute;
  top: calc(50% - .35em);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-control.ol-wmscapabilities button:after {
  content: "";
  width: .75em;
  height: .75em;
  position: absolute;
  background: transparent;
  top: calc(50% - .05em);
  left: 50%;
  -webkit-transform: scaleY(.6) translate(-50%, -50%) rotate(45deg);
          transform: scaleY(.6) translate(-50%, -50%) rotate(45deg);
  -webkit-box-shadow: inset -.18em -.18em currentColor, -.4em .1em 0 -.25em currentColor, .1em -.35em 0 -.25em currentColor, .15em .15em currentColor;
          box-shadow: inset -.18em -.18em currentColor, -.4em .1em 0 -.25em currentColor, .1em -.35em 0 -.25em currentColor, .15em .15em currentColor;
  border-radius: .1em 0;
  border: .15em solid transparent;
  border-width: 0 .15em .15em 0;
}

.ol-wmscapabilities .ol-searching {
  opacity: .5;
}
.ol-wmscapabilities .ol-searching .ol-url:after{
  content: "";
  width: .7em;
  height: .7em;
  background-color: currentColor;
  position: absolute;
  top: 6em;
  border-radius: 50%;
  display: block;
  left: calc(50% - .35em);
  -webkit-box-shadow: 0 1em currentColor, 0 -1em currentColor, 1em 0 currentColor, -1em 0 currentColor;
          box-shadow: 0 1em currentColor, 0 -1em currentColor, 1em 0 currentColor, -1em 0 currentColor;
  -webkit-animation:ol-wmscapabilities-rotate 2s linear infinite;
          animation:ol-wmscapabilities-rotate 2s linear infinite;
}
@-webkit-keyframes ol-wmscapabilities-rotate { 
  100% { -webkit-transform:rotate(360deg); transform:rotate(360deg); } 
}
@keyframes ol-wmscapabilities-rotate { 
  100% { -webkit-transform:rotate(360deg); transform:rotate(360deg); } 
}

.ol-wmscapabilities .ol-url input {
  width: calc(100% - 10em);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: Min(100%, 20em);
}
.ol-wmscapabilities .ol-url select {
  width: 2em;
  height: 100%;
  padding: 1px;
}
.ol-wmscapabilities .ol-url button {
  width: 7.5em;
  margin-left: .5em;
}
.ol-wmscapabilities .ol-result {
  display: none;
  margin-top: .5em;
}
.ol-wmscapabilities .ol-result.ol-visible {
  display: block;
}

.ol-wmscapabilities .ol-select-list {
  position: relative;
  border: 1px solid #369;
  overflow-x: hidden;
  width: calc(100% - 120px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-height: 14.5em;
}
.ol-wmscapabilities .ol-select-list div {
  padding: 0 .5em;
  cursor: pointer;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ol-wmscapabilities .ol-select-list .level-1 {
  padding-left: 1em;
}
.ol-wmscapabilities .ol-select-list .level-2 {
  padding-left: 1.5em;
}
.ol-wmscapabilities .ol-select-list .level-3 {
  padding-left: 2em;
}
.ol-wmscapabilities .ol-select-list .level-4 {
  padding-left: 2.5em;
}
.ol-wmscapabilities .ol-select-list .level-5 {
  padding-left: 3em;
}

.ol-wmscapabilities .ol-select-list .ol-info {
  font-style: italic;
}
.ol-wmscapabilities .ol-select-list .ol-title {
  background-color: rgba(0,60,136,.1);
}
.ol-wmscapabilities .ol-select-list div:hover {
  background-color: rgba(0,60,136,.5);
  color: #fff;
}
.ol-wmscapabilities .ol-select-list div.selected {
  background-color: rgba(0,60,136,.7);
  color: #fff;
}

.ol-wmscapabilities .ol-preview {
  width: 100px;
  float: right;
  background: rgba(0,60,136,.1);
  color: #666;
  padding: 0 5px 5px;
  text-align: center;
  margin-left: 10px;
}
.ol-wmscapabilities .ol-preview.tainted {
  width: 100px;
  float: right;
  background: rgba(136,0,60,.1);
  color: #666;
  padding: 0 5px 5px;
  text-align: center;
  margin-left: 10px;
}
.ol-wmscapabilities .ol-preview img {
  width: 100%;
  display: block;
  background: #fff;
}
.ol-wmscapabilities .ol-legend {
  max-width: 100%;
  display: none;
}
.ol-wmscapabilities .ol-legend.visible {
  display: block;
}
.ol-wmscapabilities .ol-buttons {
  clear: both;
  text-align: right;
}
.ol-wmscapabilities .ol-data p {
  margin: 0;
}
.ol-wmscapabilities .ol-data p.ol-title {
  font-weight: bold;
  margin: 1em 0 .5em;
}
.ol-wmscapabilities .ol-error {
  color: #800;
}

.ol-wmscapabilities ul.ol-wmsform {
  display: none;
  list-style: none;
  padding: 0;
}
.ol-wmscapabilities ul.ol-wmsform.visible {
  display: block;
}
.ol-wmscapabilities .ol-wmsform label {
  display: inline-block;
  text-align: right;
  width: calc(40% - .5em);
  margin-right: .5em;
}
.ol-wmscapabilities .ol-wmsform input {
  display: inline-block;
  width: 60%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-wmscapabilities .ol-wmsform input[type="checkbox"] {
  width: auto;
}
.ol-wmscapabilities .ol-wmsform button {
  float: right;
  margin: 1em 0;
}

.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] input {
  width: calc(60% - 2em);
}
.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button {
  position: relative;
  width: 2em;
  height: 1.6em;
  margin: 0;
  vertical-align: middle;
  color: #444;
}
.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button:before,
.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button:after {
  content: "";
  position: absolute;
  width: .25em;
  height: .9em;
  border: .1em solid currentColor;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) skewY(-15deg);
          transform: translate(-50%, -50%) skewY(-15deg);
}
.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button:after {
  -webkit-transform: translateX(.4em) translate(-50%, -50%) skewY(15deg);
          transform: translateX(.4em) translate(-50%, -50%) skewY(15deg);
  -webkit-box-shadow: -0.8em 0.25em;
          box-shadow: -0.8em 0.25em;
}

.ol-ext-dialog.ol-wmscapabilities form {
  width: 600px;
  min-height: 15em;
  top: 15%;
  -webkit-transform: translate(-50%, -15%);
          transform: translate(-50%, -15%);
}
.ol-ext-dialog.ol-wmscapabilities .ol-content {
  max-height: calc(100vh - 6em);
}

.ol-ext-dialog.ol-wmtscapabilities [data-param="map"] {
  display: none;
}
.ol-ext-dialog [data-param="style"] {
  display: none;
}
.ol-ext-dialog.ol-wmtscapabilities [data-param="style"] {
  display: list-item;
}
.ol-ext-dialog.ol-wmtscapabilities [data-param="proj"],
.ol-ext-dialog.ol-wmtscapabilities [data-param="version"] {
  opacity: .6;
  pointer-events: none;
}

.ol-ext-dialog.ol-wmscapabilities button.ol-wmsform {
  width: 1.8em;
  text-align: center;
}
.ol-ext-dialog.ol-wmscapabilities button.ol-wmsform:before {
  content: "+";
}
.ol-ext-dialog.ol-wmscapabilities .ol-form button.ol-wmsform:before {
  content: "-";
}

.ol-ext-dialog.ol-wmscapabilities .ol-form button.ol-load,
.ol-ext-dialog.ol-wmscapabilities .ol-form .ol-legend {
  display: none;
}
.ol-ext-dialog.ol-wmscapabilities .ol-form ul.ol-wmsform {
  display: block;
  clear: both;
}
.ol-target-overlay .ol-target 
{	border: 1px solid transparent;
	-webkit-box-shadow: 0 0 1px 1px #fff;
	        box-shadow: 0 0 1px 1px #fff;
	display: block;
	height: 20px;
	width: 0;
}

.ol-target-overlay .ol-target:after,
.ol-target-overlay .ol-target:before
{	content:"";
	border: 1px solid #369;
	-webkit-box-shadow: 0 0 1px 1px #fff;
	        box-shadow: 0 0 1px 1px #fff;
	display: block;
	width: 20px;
	height: 0;
	position:absolute;
	top:10px;
	left:-10px;
}
.ol-target-overlay .ol-target:after
{	-webkit-box-shadow: none;	box-shadow: none;
	height: 20px;
	width: 0;
	top:0px;
	left:0px;
}

.ol-overlaycontainer .ol-touch-cursor {
  /* human fingertips are typically 16x20 mm = 45x57 pixels
    source: http://touchlab.mit.edu/publications/2003_009.pdf
  */
  width: 56px;
  height: 56px;
  margin: 6px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(255,255,255,.4);
  -webkit-box-shadow: inset 0 0 0 5px #369;
          box-shadow: inset 0 0 0 5px #369;
}

.ol-overlaycontainer .ol-touch-cursor:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background: radial-gradient(circle at 100% 100%, transparent, transparent 70%, #369 70%, #369)
}

.ol-overlaycontainer .ol-touch-cursor .ol-button {
  position: absolute;
  color: #369;
  height: 55%;
  width: 55%;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(255,255,255,.4);
  -webkit-box-shadow: inset 0 0 0 3px currentColor;
          box-shadow: inset 0 0 0 3px currentColor;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  -webkit-transition: all .5s, opacity 0s, background 0s;
  transition: all .5s, opacity 0s, background 0s;
  overflow: hidden;
}
.ol-overlaycontainer .ol-touch-cursor.active.disable .ol-button {
  opacity: .8;
  background: rgba(51, 102, 153, .2);
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-0 {
  top: -18%;
  left: 118%;
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-1 {
  top: 50%;
  left: 140%;
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-2 {
  top: 120%;
  left: 120%;
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-3 {
  top: 140%;
  left: 50%;
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-4 {
  top: 118%;
  left: -18%;
}
/* extra buttons */
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-5 {
  top: 50%;
  left: -40%;
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-6 {
  top: -18%;
  left: -18%;
}
.ol-overlaycontainer .ol-touch-cursor.active .ol-button-7 {
  top: -40%;
  left: 50%;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button:before {
  content: "";
  width: 1.5em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1em;
  text-align: center;
}
.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-add:before,
.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-remove:before {
  content: "−";
  line-height: .95em;
  font-size: 1.375em;
  font-weight: bold;
}
.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-add:before {
  content: "+";
}
.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-x:before {
  content: "\00D7";
  font-size: 1.2em;
  font-weight: bold;
}
.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-move:before {
  content: "\2725";
  font-size: 1.2em;
}
.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-check:before {
  content: "\2713";
  font-weight: bold;
}

.ol-overlaycontainer .ol-touch-cursor.nodrawing .ol-button.ol-button-x,
.ol-overlaycontainer .ol-touch-cursor.nodrawing .ol-button.ol-button-remove,
.ol-overlaycontainer .ol-touch-cursor.nodrawing .ol-button.ol-button-check {
  opacity: .8;
  background: rgba(51, 102, 153, .2);
}

.ol-overlaycontainer .ol-touch-cursor .ol-button > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ol-overlaycontainer .ol-touch-cursor .ol-button-type:before {
  content: "\21CE";
  font-weight: bold;
}



/* remove outline on focus */
.mapboxgl-canvas:focus {
  outline: none;
}
.ol-perspective-map {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200%;
  height: 200%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-perspective-map .ol-layer {
  z-index: -1!important; /* bug using Chrome ? */
}
.ol-perspective-map .ol-layers {
  -webkit-transform: translateY(0) perspective(200px) rotateX(0deg) scaleY(1);
          transform: translateY(0) perspective(200px) rotateX(0deg) scaleY(1);
}

.ol-perspective-map .ol-overlaycontainer,
.ol-perspective-map .ol-overlaycontainer-stopevent {
  width: 50%!important;
  height: 50%!important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ol-overlay-container .ol-magnify 
{	background: rgba(0,0,0, 0.5);
	border:3px solid #369;
	border-radius: 50%;
	height: 150px;
	width: 150px;
	overflow: hidden;
	-webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
	        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
	position:relative;
	z-index:0;
}

.ol-overlay-container .ol-magnify:before 
{	border-radius: 50%;
	-webkit-box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.25) inset;
	        box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.25) inset;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.ol-overlay-container .ol-magnify:after 
{
	border-radius: 50%;
	-webkit-box-shadow: 0 0 20px 7px rgba(255, 255, 255, 1);
	        box-shadow: 0 0 20px 7px rgba(255, 255, 255, 1);
	content: "";
	display: block;
	height: 0;
	left: 23%;
	position: absolute;
	top: 20%;
	width: 20%;
	z-index: 1;
	transform: rotate(-40deg);
	-webkit-transform: rotate(-40deg);
}
/** popup animation using visible class
*/
.ol-popup.anim {
  visibility: hidden;
}

.ol-popup.anim.visible {
  visibility: visible;
}
/** No transform when visible 
*/
.ol-popup.anim.visible > div {
  visibility: visible;
  -webkit-transform: none;
          transform: none;
  -webkit-animation: ol-popup_bounce 0.4s ease 1;
          animation: ol-popup_bounce 0.4s ease 1;
}

@-webkit-keyframes ol-popup_bounce {
  from { -webkit-transform: scale(0); transform: scale(0); }
  50%  { -webkit-transform: scale(1.1); transform: scale(1.1) }
  80%  { -webkit-transform: scale(0.95); transform: scale(0.95) }
  to   { -webkit-transform: scale(1); transform: scale(1); }
}

@keyframes ol-popup_bounce {
  from { -webkit-transform: scale(0); transform: scale(0); }
  50%  { -webkit-transform: scale(1.1); transform: scale(1.1) }
  80%  { -webkit-transform: scale(0.95); transform: scale(0.95) }
  to   { -webkit-transform: scale(1); transform: scale(1); }
}

/** Transform Origin */
.ol-popup.anim.ol-popup-bottom.ol-popup-left > div  {
  -webkit-transform-origin:0 100%;
          transform-origin:0 100%;
}
.ol-popup.anim.ol-popup-bottom.ol-popup-right > div {
  -webkit-transform-origin:100% 100%;
          transform-origin:100% 100%;
}
.ol-popup.anim.ol-popup-bottom.ol-popup-center > div {
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%;
}
.ol-popup.anim.ol-popup-top.ol-popup-left > div {
  -webkit-transform-origin:0 0;
          transform-origin:0 0;
}
.ol-popup.anim.ol-popup-top.ol-popup-right > div {
  -webkit-transform-origin:100% 0;
          transform-origin:100% 0;
}
.ol-popup.anim.ol-popup-top.ol-popup-center > div {
  -webkit-transform-origin:50% 0;
          transform-origin:50% 0;
}
.ol-popup.anim.ol-popup-middle.ol-popup-left > div {
  -webkit-transform-origin:0 50%;
          transform-origin:0 50%;
}
.ol-popup.anim.ol-popup-middle.ol-popup-right > div {
  -webkit-transform-origin:100% 50%;
          transform-origin:100% 50%;
}

.ol-overlaycontainer-stopevent {
  /* BOUG ol6.1 to enable DragOverlay interaction 
  position: initial!important;
  */
}

/** ol.popup */
.ol-popup {
  font-size:0.9em;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}
.ol-popup .ol-popup-content {
  overflow:hidden;
  cursor: default;
  padding: 0.25em 0.5em;
}
.ol-popup.hasclosebox .ol-popup-content {
  margin-right: 1.7em;
}
.ol-popup .ol-popup-content:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  height: 0;
}

/** Anchor position */
.ol-popup .anchor {
  display: block;
  width: 0px;
  height: 0px;
  background:red;
  position: absolute;
  margin: -11px 22px;
  pointer-events: none;
}
.ol-popup .anchor:after,
.ol-popup .anchor:before {
  position:absolute;
}
.ol-popup-right .anchor:after,
.ol-popup-right .anchor:before {
  right:0;
}
.ol-popup-top .anchor { top:0; }
.ol-popup-bottom .anchor { bottom:0; }
.ol-popup-right .anchor { right:0; }
.ol-popup-left .anchor { left:0; }
.ol-popup-center .anchor { 
  left:50%; 
  margin-left: 0!important;
}
.ol-popup-middle .anchor { 
  top:50%; 
  margin-top: 0!important;
}
.ol-popup-center.ol-popup-middle .anchor { 
  display:none; 
}

/** Fixed popup */
.ol-popup.ol-fixed {
  margin: 0!important;
  top: .5em!important;
  right: .5em!important;
  left: auto!important;
  bottom: auto!important;
  -webkit-transform: none!important;
          transform: none!important;
}
.ol-popup.ol-fixed .anchor {
  display: none;
}
.ol-popup.ol-fixed.anim > div {
  -webkit-animation: none;
          animation: none;
}

.ol-popup .ol-fix {
  width: 1em;
  height: .9em;
  background: #fff;
  position: relative;
  float: right;
  margin: .2em;
  cursor: pointer;
}
.ol-popup .ol-fix:before {
  content: "";
  width: .8em;
  height: .7em;
  display: block;
  border: .1em solid #666;
      border-right-width: 0.1em;
  border-right-width: .3em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: .1em;
}

/** Add a shadow to the popup */
.ol-popup.shadow {
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.5);
          box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.5);
}

/** Close box */
.ol-popup .closeBox {
  background-color: rgba(0, 60, 136, 0.5);
  color: #fff;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  float: right;
  font-size: 0.9em;
  font-weight: 700;
  width: 1.4em;
  height: 1.4em;
  margin: 5px 5px 0 0;
  padding: 0;
  position: relative;
  display:none;
}
.ol-popup.hasclosebox .closeBox {
  display:block;
}

.ol-popup .closeBox:hover {
  background-color: rgba(0, 60, 136, 0.7);
}
/* the X */
.ol-popup .closeBox:after {
  content: "\00d7";
  font-size:1.5em;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  line-height: 1em;
  margin: -0.5em 0;
  position: absolute;
}

/** Modify touch poup */
.ol-popup.modifytouch {
  background-color: #eee;
}
.ol-popup.modifytouch .ol-popup-content {	
  padding: 0 0.25em;
  font-size: 0.85em;
  white-space: nowrap;
}
.ol-popup.modifytouch .ol-popup-content a {
  text-decoration: none;
}

/** Tool tips popup*/
.ol-popup.tooltips {
  background-color: #ffa;
}
.ol-popup.tooltips .ol-popup-content{
  padding: 0 0.25em;
  font-size: 0.85em;
  white-space: nowrap;
}

/** Default popup */
.ol-popup.default > div {
  background-color: #fff;
  border:1px solid #69f;
  border-radius: 5px;
}
.ol-popup.default {
  margin: -11px 0;
  -webkit-transform: translate(0, -22px);
          transform: translate(0, -22px);
}
.ol-popup-top.ol-popup.default {
  margin: 11px 0;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.default {
  margin: -11px -22px;
  -webkit-transform: translate(0, -22px);
          transform: translate(0, -22px);
}
.ol-popup-top.ol-popup-left.default {
  margin: 11px -22px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.default {
  margin: -11px 22px;
  -webkit-transform: translate(44px, -22px);
          transform: translate(44px, -22px);
}
.ol-popup-top.ol-popup-right.default {
  margin: 11px 22px;
  -webkit-transform: translate(44px, 0);
          transform: translate(44px, 0);
}
.ol-popup-middle.default {
  margin:0 10px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-middle.ol-popup-right.default {
  margin:0 -10px;
  -webkit-transform: translate(-20px, 0);
          transform: translate(-20px, 0);
}

.ol-popup.default .anchor {
  color: #69f;
}
.ol-popup.default .anchor:after,
.ol-popup.default .anchor:before {
  content:"";
  border-color: currentColor transparent;
  border-style: solid;
  border-width: 11px;
  margin: 0 -11px;
}
.ol-popup.default .anchor:after {
  border-color: #fff transparent;
  border-width: 11px;
  margin: 2px -11px;
}

.ol-popup-top.default .anchor:before,
.ol-popup-top.default .anchor:after {
  border-top:0;
  top:0;
}

.ol-popup-bottom.default .anchor:before,
.ol-popup-bottom.default .anchor:after {
  border-bottom:0;
  bottom:0;
}

.ol-popup-middle.default .anchor:before {
  margin: -11px -33px;
  border-color: transparent currentColor;
}
.ol-popup-middle.default .anchor:after {
  margin: -11px -31px;
  border-color: transparent #fff;
}
.ol-popup-middle.ol-popup-left.default .anchor:before,
.ol-popup-middle.ol-popup-left.default .anchor:after {
  border-left:0;
}
.ol-popup-middle.ol-popup-right.default .anchor:before,
.ol-popup-middle.ol-popup-right.default .anchor:after {
  border-right:0;
}

/** Placemark popup */
.ol-popup.placemark {
  color: #c00;
  margin: -.65em 0;
  -webkit-transform: translate(0, -1.3em);
          transform: translate(0, -1.3em);
}
.ol-popup.placemark > div {
  position: relative;
  font-size: 15px;	
  background-color: #fff;
  border: 0;
  -webkit-box-shadow: inset 0 0 0 0.45em;
          box-shadow: inset 0 0 0 0.45em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  min-width: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-popup.placemark .ol-popup-content {
  overflow: hidden;
  cursor: default;
  text-align: center;
  padding: .25em 0;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  line-height: 1em;
}
.ol-popup.placemark .anchor {
  margin: 0;
}

.ol-popup.placemark .anchor:before {
  content: "";
  margin: -.5em -.5em;
  background: transparent;
  width: 1em;
  height: .5em;
  border-radius: 50%;
  -webkit-box-shadow: 0 1em 0.5em rgba(0,0,0,.5);
          box-shadow: 0 1em 0.5em rgba(0,0,0,.5);
}
.ol-popup.placemark .anchor:after {
  content: "";
  border-color: currentColor transparent;
  border-style: solid;
  border-width: 1em .7em 0;
  margin: -.75em -.7em;
  bottom:0;
}

/** Placemark Shield */
.ol-popup.placemark.shield > div {
  border-radius: .2em;
}

.ol-popup.placemark.shield .anchor:after {
    border-width: .8em 1em 0;
    margin: -.7em -1em;
}

/** Placemark Blazon */
.ol-popup.placemark.blazon > div {
  border-radius: .2em;
}

/** Placemark Needle/Pushpin */
.ol-popup.placemark.pushpin {	
  margin: -2.2em 0;
  -webkit-transform: translate(0, -4em);
          transform: translate(0, -4em);
}
.ol-popup.placemark.pushpin > div {	
  border-radius: 0;
  background: transparent!important;
  -webkit-box-shadow: inset 2em 0 currentColor;
          box-shadow: inset 2em 0 currentColor;
  width: 1.1em;
}
.ol-popup.placemark.pushpin > div:before {
  content: "";
  width: 1.3em;
  height: 1.5em;
  border-style: solid;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  border-color: currentColor transparent;
  border-width: .3em .5em .5em;
  pointer-events: none;
}

.ol-popup.placemark.needle {	
  margin: -2em 0;
  -webkit-transform: translate(0, -4em);
          transform: translate(0, -4em);
}
.ol-popup.placemark.pushpin .anchor,
.ol-popup.placemark.needle .anchor {
  margin: -1.2em;
}
.ol-popup.placemark.pushpin .anchor:after,
.ol-popup.placemark.needle .anchor:after {
  border-style: solid;
    border-width: 2em .15em 0;
    margin: -.55em -0.2em;
    width: .1em;
}
.ol-popup.placemark.pushpin .anchor:before,
.ol-popup.placemark.needle .anchor:before {
    margin: -.75em -.5em;
}

/** Placemark Flag */
.ol-popup.placemark.flagv {
  margin: -2em 1em;
  -webkit-transform: translate(0, -4em);
          transform: translate(0, -4em);
}
.ol-popup.placemark.flagv > div {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
}
.ol-popup.placemark.flagv > div:before {
  content: "";
  border: 1em solid transparent;
  position: absolute;
  border-left: 2em solid currentColor;
  pointer-events: none;
}
.ol-popup.placemark.flagv .anchor {
  margin: -1.4em;
}

.ol-popup.placemark.flag {	
  margin: -2em 1em;
  -webkit-transform: translate(0, -4em);
          transform: translate(0, -4em);
}
.ol-popup.placemark.flag > div {	
  border-radius: 0;
  -webkit-transform-origin: 0% 150%!important;
          transform-origin: 0% 150%!important;
}
.ol-popup.placemark.flag .anchor {
  margin: -1.4em;
}
.ol-popup.placemark.flagv .anchor:after, 
.ol-popup.placemark.flag .anchor:after {
  border-style: solid;
  border-width: 2em .15em 0;
  margin: -.55em -1em;
  width: .1em;
}
.ol-popup.placemark.flagv .anchor:before,
.ol-popup.placemark.flag .anchor:before {
  margin: -.75em -1.25em;
}

.ol-popup.placemark.flag.finish {
  margin: -2em 1em;
}
.ol-popup.placemark.flag.finish > div {
  background-image: 
    linear-gradient(45deg, currentColor 25%, transparent 25%, transparent 75%, currentColor 75%, currentColor), 
    linear-gradient(45deg, currentColor 25%, transparent 25%, transparent 75%, currentColor 75%, currentColor);
  background-size: 1em 1em;
  background-position: .5em 0, 0 .5em;
  -webkit-box-shadow: inset 0 0 0 .25em;
          box-shadow: inset 0 0 0 .25em;
}

/** Black popup */
.ol-popup.black .closeBox {
  background-color: rgba(0,0,0, 0.5);
  border-radius: 5px;
  color: #f80;
}
.ol-popup.black .closeBox:hover {
  background-color: rgba(0,0,0, 0.7);
  color:#da2;
}

.ol-popup.black {
  margin: -20px 0;
  -webkit-transform: translate(0, -40px);
          transform: translate(0, -40px);
}
.ol-popup.black > div{
  background-color: rgba(0,0,0,0.6);
  border-radius: 5px;
  color:#fff;
}
.ol-popup-top.ol-popup.black {
  margin: 20px 0;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.black {
  margin: -20px -22px;
  -webkit-transform: translate(0, -40px);
          transform: translate(0, -40px);
}
.ol-popup-top.ol-popup-left.black {
  margin: 20px -22px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.black {
  margin: -20px 22px;
  -webkit-transform: translate(44px, -40px);
          transform: translate(44px, -40px);
}
.ol-popup-top.ol-popup-right.black {
  margin: 20px 22px;
  -webkit-transform: translate(44px, 0);
          transform: translate(44px, 0);
}
.ol-popup-middle.black {
  margin: 0 11px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.ol-popup-middle.black {
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.ol-popup-middle.black {
  margin:0 -11px;
  -webkit-transform: translate(-22px, 0);
          transform: translate(-22px, 0);
}

.ol-popup.black .anchor {
  margin: -20px 11px;
  color: rgba(0,0,0,0.6);
} 
.ol-popup.black .anchor:before {
  content:"";
  border-color: currentColor transparent;
  border-style: solid;
  border-width: 20px 11px;
}

.ol-popup-top.black .anchor:before {
  border-top:0;
  top:0;
}

.ol-popup-bottom.black .anchor:before {
  border-bottom:0;
  bottom:0;
}

.ol-popup-middle.black .anchor:before {
  margin: -20px -22px;
  border-color: transparent currentColor;
}
.ol-popup-middle.ol-popup-left.black .anchor:before {
  border-left:0;
}
.ol-popup-middle.ol-popup-right.black .anchor:before {
  border-right:0;
}

.ol-popup-center.black .anchor:before {
  margin: 0 -10px;
}


/** Green tips popup */
.ol-popup.tips .closeBox {
  background-color: #f00;
  border-radius: 50%;
  color: #fff;
  width:1.2em;
  height:1.2em;
}
.ol-popup.tips .closeBox:hover {
  background-color: #f40;
}

.ol-popup.tips {
  margin: -20px 0;
  -webkit-transform: translate(0,-40px);
          transform: translate(0,-40px);
}
.ol-popup.tips > div {
  background-color: #cea;
  border: 5px solid #ad7;
  border-radius: 5px;
  color:#333;
}
.ol-popup-top.ol-popup.tips {
  margin: 20px 0;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.tips {
  margin: -20px -22px;
  -webkit-transform: translate(0,-40px);
          transform: translate(0,-40px);
}
.ol-popup-top.ol-popup-left.tips {
  margin: 20px -22px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.tips {
  margin: -20px 22px;
  -webkit-transform: translate(44px,-40px);
          transform: translate(44px,-40px);
}
.ol-popup-top.ol-popup-right.tips {
  margin: 20px 22px;
  -webkit-transform: translate(44px,0);
          transform: translate(44px,0);
}
.ol-popup-middle.tips {
  margin:0;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.ol-popup-middle.tips {
  margin: 0 22px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.ol-popup-middle.tips {
  margin: 0 -22px;
  -webkit-transform: translate(-44px,0);
          transform: translate(-44px,0);
}

.ol-popup.tips .anchor {
  margin: -18px 22px;
  color: #ad7;
} 
.ol-popup.tips .anchor:before {
  content:"";
  border-color: currentColor transparent;
  border-style: solid;
  border-width: 20px 11px;
}

.ol-popup-top.tips .anchor:before {
  border-top:0;
  top:0;
}
.ol-popup-bottom.tips .anchor:before {
  border-bottom:0;
  bottom:0;
}
.ol-popup-center.tips .anchor:before {
  border-width: 20px 6px;
  margin: 0 -6px;
}
.ol-popup-left.tips .anchor:before {
  border-left:0;
  margin-left:0;
}
.ol-popup-right.tips .anchor:before {
  border-right:0;
  margin-right:0;
}

.ol-popup-middle.tips .anchor:before {
  margin: -6px -41px;
  border-color: transparent currentColor;
  border-width:6px 20px;
}
.ol-popup-middle.ol-popup-left.tips .anchor:before {
  border-left:0;
}
.ol-popup-middle.ol-popup-right.tips .anchor:before {
  border-right:0;
}

/** Warning popup */
.ol-popup.warning .closeBox {
  background-color: #f00;
  border-radius: 50%;
  color: #fff;
  font-size: 0.83em;
}
.ol-popup.warning .closeBox:hover {
  background-color: #f40;
}

.ol-popup.warning {
  background-color: #fd0;
  border-radius: 3px;
  border:4px dashed #f00;
  margin:20px 0;
  color:#900;
  margin: -28px 10px;
  -webkit-transform: translate(0, -56px);
          transform: translate(0, -56px);
}
.ol-popup-top.ol-popup.warning {
  margin: 28px 10px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.warning {
  margin: -28px -22px;
  -webkit-transform: translate(0, -56px);
          transform: translate(0, -56px);
}
.ol-popup-top.ol-popup-left.warning {
  margin: 28px -22px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.warning {
  margin: -28px 22px;
  -webkit-transform: translate(44px, -56px);
          transform: translate(44px, -56px);
}
.ol-popup-top.ol-popup-right.warning {
  margin: 28px 22px;
  -webkit-transform: translate(44px, 0);
          transform: translate(44px, 0);
}
.ol-popup-middle.warning {
  margin:0;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-left.ol-popup-middle.warning {
  margin:0 22px;
  -webkit-transform: none;
          transform: none;
}
.ol-popup-right.ol-popup-middle.warning {
  margin:0 -22px;
  -webkit-transform: translate(-44px, 0);
          transform: translate(-44px, 0);
}

.ol-popup.warning .anchor {
  margin: -33px 7px;
} 
.ol-popup.warning .anchor:before {
  content:"";
  border-color: #f00 transparent;
  border-style: solid;
  border-width: 30px 11px;
}

.ol-popup-top.warning .anchor:before {
  border-top:0;
  top:0;
}
.ol-popup-bottom.warning .anchor:before {
  border-bottom:0;
  bottom:0;
}

.ol-popup-center.warning .anchor:before {
  margin: 0 -21px;
}
.ol-popup-middle.warning .anchor:before {
  margin: -10px -33px;
  border-color: transparent #f00;
  border-width:10px 22px;
}
.ol-popup-middle.ol-popup-left.warning .anchor:before {
  border-left:0;
}
.ol-popup-middle.ol-popup-right.warning .anchor:before {
  border-right:0;
}

.ol-popup .ol-popupfeature table {
  width: 100%;
}
.ol-popup .ol-popupfeature table td {
  max-width: 25em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ol-popup .ol-popupfeature table td img {
  max-width: 100px;
  max-height: 100px;
}
.ol-popup .ol-popupfeature tr:nth-child(2n+1) {
  background-color: #eee;
}
.ol-popup .ol-popupfeature .ol-zoombt {
  border: 0;
  width: 2em;
  height: 2em;
  display: inline-block;
  color: rgba(0,60,136,.5);
  position: relative;
  background: transparent;
  outline: none;
}
.ol-popup .ol-popupfeature .ol-zoombt:before {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border: .17em solid currentColor;
  border-radius: 100%;
  top: .3em;
  left: .3em;
}
.ol-popup .ol-popupfeature .ol-zoombt:after {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  top: 1.35em;
  left: 1.15em;
  border-width: .1em .3em;
  border-style: solid;
  border-radius: .03em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-box-shadow: -0.2em 0 0 -0.04em;
          box-shadow: -0.2em 0 0 -0.04em;
}

.ol-popup .ol-popupfeature .ol-count{
  float: right;
  margin: .25em 0;
}
.ol-popup .ol-popupfeature .ol-prev,
.ol-popup .ol-popupfeature .ol-next {
  border-style: solid;
  border-color: transparent rgba(0,60,136,.5);
  border-width: .5em 0 .5em .5em;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 .5em;
  cursor: pointer;
}
.ol-popup .ol-popupfeature .ol-prev{
  border-width: .5em .5em .5em 0;
}

.ol-popup.tooltips.black {
  background-color: transparent;
}
.ol-popup.tooltips.black > div {
  -webkit-transform: scaleY(1.3);
          transform: scaleY(1.3);
  padding: .2em .5em;
  background-color: rgba(0,0,0, 0.5);
}
.ol-popup-middle.tooltips.black .anchor:before {
  border-width: 5px 10px;
  margin: -5px -21px;
}

.ol-popup-center.ol-popup-middle { 
  margin: 0;
}

.ol-popup-top.ol-popup-left.ol-fixPopup,
.ol-popup-top.ol-popup-right.ol-fixPopup,
.ol-popup.ol-fixPopup {
  margin: 0;
}

.ol-miniscroll {
  position: relative;
}
.ol-miniscroll:hover .ol-scroll {
  opacity: .5;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.ol-miniscroll .ol-scroll {
  -ms-touch-action: none;
      touch-action: none;
  position: absolute;
  right: 0px;
  width: 9px;
  height: auto;
  max-height: 100%;
  opacity: 0;
  border-radius: 9px;
  -webkit-transition: opacity 1s .5s;
  transition: opacity 1s .5s;
  overflow: hidden;
  z-index: 1;
}
.ol-miniscroll .ol-scroll > div {
  -ms-touch-action: none;
      touch-action: none;
  position: absolute;
  top: 0;
  right: 0px;
  width: 9px;
  height: 9px;
  -webkit-box-shadow: inset 10px 0 currentColor;
          box-shadow: inset 10px 0 currentColor;
  border-radius: 9px / 12px;
  border: 2px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}
.ol-miniscroll .ol-scroll.ol-100pc {
  opacity: 0;
}

.ol-viewport canvas.ol-fixedoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Toggle Switch */
.ol-ext-toggle-switch {
  cursor: pointer;
  position: relative;
}
.ol-ext-toggle-switch input[type="radio"],
.ol-ext-toggle-switch input[type="checkbox"] {
  display: none;
}
.ol-ext-toggle-switch span {
  color: rgba(0,60,136,.5);
  position: relative;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: .4s, background-color 0s, border-color 0s;
  transition: .4s, background-color 0s, border-color 0s;
  width: 1.6em;
  height: 1em;
  display: inline-block;
  border-radius: 1em;
  font-size: 1.3em;
  vertical-align: middle;
  margin: -.15em .2em .15em;
}
.ol-ext-toggle-switch span:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 0;
  top: 50%;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 1em;
  display: block;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 2px solid #ccc;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-ext-toggle-switch:hover span {
  background-color: #999;
}
.ol-ext-toggle-switch:hover span:before {
  border-color: #999;
}

.ol-ext-toggle-switch input:checked + span {
  background-color: currentColor;
}
.ol-ext-toggle-switch input:checked + span:before {
  -webkit-transform: translate(.6em,-50%);
          transform: translate(.6em,-50%);
  border-color: currentColor;
}

/* Check/radio buttons */
.ol-ext-check {
  position: relative;
  display: inline-block;
}
.ol-ext-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ol-ext-check span {
  color: rgba(0,60,136,.5);
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: -.1em .5em .1em;
  background-color: #ccc;
  vertical-align: middle;
}
.ol-ext-check:hover span {
  background-color: #999;
}
.ol-ext-checkbox input:checked ~ span {
  background-color: currentColor;
}
.ol-ext-checkbox input:checked ~ span:before {
  content: "";
  position: absolute;
  width: .5em;
  height: .8em;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-.1em) translate(-50%, -50%) rotate(45deg);
          transform: translateY(-.1em) translate(-50%, -50%) rotate(45deg);
  -webkit-box-shadow: inset -0.2em -0.2em #fff;
          box-shadow: inset -0.2em -0.2em #fff;
}

.ol-ext-radio span {
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
}
.ol-ext-radio:hover input:checked ~ span {
  background-color: #ccc;
}
.ol-ext-radio input:checked ~ span:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: currentColor;
}

.ol-collection-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ol-collection-list li {
  position: relative;
  padding: 0 2em 0 1em;
}
.ol-collection-list li:hover {
  background-color: rgba(0,60,136,.2);
}
.ol-collection-list li.ol-select {
  background-color: rgba(0,60,136,.5);
  color: #fff;
}

.ol-collection-list li .ol-order {
  position: absolute;
  -ms-touch-action: none;
      touch-action: none;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2em;
  height: 100%;
  cursor: n-resize;
}
.ol-collection-list li .ol-order:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 18px;
  height: 2px;
  background-color: currentColor;
  -webkit-box-shadow: 0 5px, 0 -5px;
          box-shadow: 0 5px, 0 -5px;
  border-radius: 2px;
}

.ol-ext-colorpicker.ol-popup {
  width: 2em;
  height: 1.5em;
  background-color: transparent;
  background-image: 
    linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%), 
    linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%);
  background-size: 10px 10px;
  background-position: 0 -1px, 5px 4px;
}

.ol-ext-colorpicker .ol-tabbar {
  background-color: #eee;
  border-bottom: 1px solid #999;
  display: none;
}
.ol-ext-colorpicker.ol-tab .ol-tabbar {
  display: block;
}

.ol-ext-colorpicker .ol-tabbar > div {
  display: inline-block;
  background-color: #fff;
  padding: 0 .5em;
  border: 1px solid #999;
  border-radius: 2px 2px 0 0;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.ol-ext-colorpicker .ol-tabbar > div:nth-child(1) {
  border-bottom-color: #fff;
}
.ol-ext-colorpicker.ol-picker-tab .ol-tabbar > div:nth-child(1) {
  border-bottom-color: #999;
}
.ol-ext-colorpicker.ol-picker-tab .ol-tabbar > div:nth-child(2) {
  border-bottom-color: #fff;
}

.ol-ext-colorpicker.ol-popup.ol-tab .ol-popup {
  width: 180px;
}
.ol-ext-colorpicker.ol-tab .ol-palette {
  margin: 0 10px;
}
.ol-ext-colorpicker.ol-tab .ol-container {
  display: none;
}
.ol-ext-colorpicker.ol-tab.ol-picker-tab .ol-container {
  display: block;
}
.ol-ext-colorpicker.ol-tab.ol-picker-tab .ol-palette {
  display: none;
}

.ol-ext-colorpicker.ol-popup .ol-popup {
  width: 340px;
}

.ol-ext-colorpicker.ol-popup .ol-vignet {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  background-color: currentColor;
  pointer-events: none;
}

.ol-ext-colorpicker .ol-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.ol-ext-colorpicker .ol-cursor {
  pointer-events: none;
}

.ol-ext-colorpicker .ol-picker {
  position: relative;
  cursor: crosshair;
  width: 150px;
  height: 150px;
  border: 5px solid #fff;
  background-color: currentColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(#000), to(transparent)),
    -webkit-gradient(linear, left top, right top, from(#fff), to(transparent));
  background-image: linear-gradient(0, #000, transparent),
    linear-gradient(90deg, #fff, transparent);
}
.ol-ext-colorpicker .ol-picker .ol-cursor {
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid rgba(0,0,0,.7);
  -webkit-box-shadow: 0 0 0 1px rgba(255,255,255,.7);
          box-shadow: 0 0 0 1px rgba(255,255,255,.7);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.ol-ext-colorpicker .ol-slider {
  position: relative;
  cursor: crosshair;
  background-color: #fff;
  height: 10px;
  width: 150px;
  margin: 5px 0 10px;
  border: 5px solid #fff;
  border-width: 0 5px;
  background-image: 
    linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%), 
    linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%);
  background-size: 10px 10px;
  background-position: 0 -1px, 5px 4px;
}
.ol-ext-colorpicker .ol-slider > div {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, transparent, #fff);
  pointer-events: none;
}
.ol-ext-colorpicker .ol-slider .ol-cursor {
  position: absolute;
  width: 4px;
  height: 12px;
  border: 1px solid #000;
  top: 50%;
  left: 0;
  background: transparent;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-ext-colorpicker .ol-tint {
  position: absolute;
  cursor: crosshair;
  width: 10px;
  height: 150px;
  border: 5px solid #fff;
  border-width: 5px 0;
  -webkit-box-sizing: border;
          box-sizing: border;
  top: 0;
  right: 5px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(#f00), color-stop(#f0f), color-stop(#00f), color-stop(#0ff), color-stop(#0f0), color-stop(#ff0), to(#f00));
  background-image: linear-gradient(0, #f00, #f0f, #00f, #0ff, #0f0, #ff0, #f00)
}
.ol-ext-colorpicker .ol-tint .ol-cursor {
  position: absolute;
  top: 0;
  left: 50%;
  border: 1px solid #000;
  height: 4px;
  width: 12px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ol-ext-colorpicker .ol-clear {
  position: absolute;
  border: 2px solid #999;
  right: 4px;
  top: 163px;
  width: 10px;
  height: 10px;
}
.ol-ext-colorpicker .ol-clear:before,
.ol-ext-colorpicker .ol-clear:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.ol-ext-colorpicker .ol-clear:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.ol-ext-colorpicker.ol-nopacity .ol-slider,
.ol-ext-colorpicker.ol-nopacity .ol-clear {
  display: none;
}
.ol-ext-colorpicker.ol-nopacity .ol-alpha {
  display: none;
}

.ol-ext-colorpicker .ol-rgb {
  position: relative;
  padding: 5px;
  width: 170px;
  display: none;
}
.ol-ext-colorpicker .ol-rgb input {
  width: 25%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 0 0 2px;
  border: 1px solid #999;
  border-radius: 2px;
  font-size: 13px;
}
.ol-ext-colorpicker .ol-rgb input:nth-child(1) {
	background-color: rgba(255,0,0,.1);
}
.ol-ext-colorpicker .ol-rgb input:nth-child(2) {
	background-color: rgba(0,255,0,.1);
}
.ol-ext-colorpicker .ol-rgb input:nth-child(3) {
	background-color: rgba(0,0,255,.12);
}

.ol-ext-colorpicker button,
.ol-ext-colorpicker .ol-txt-color {
  font-size: 13px;
  margin: 0 5px 5px;
  text-align: center;
  width: 170px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  border: 1px solid #999;
  border-radius: 2px;
  display: block;
}
.ol-ext-colorpicker button {
  background-color: #eee;
}
.ol-ext-colorpicker button:hover {
  background-color: #e9e9e9;
}

.ol-ext-colorpicker .ol-txt-color.ol-error {
  background-color: rgba(255,0,0,.2);
}

.ol-ext-colorpicker .ol-palette {
  padding: 2px;
  display: inline-block;
  width: 152px;
}
.ol-ext-colorpicker .ol-palette > div {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: 
    linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%), 
    linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
  margin: 2px;
  -webkit-box-shadow: 0 0 2px 0px #666;
          box-shadow: 0 0 2px 0px #666;
  border-radius: 1px;
  cursor: pointer;
  position: relative;
}
.ol-ext-colorpicker .ol-palette > div:before {
  content: "";
  position: absolute;
  background-color: currentColor;
  width: 100%;
  height: 100%;
}
.ol-ext-colorpicker .ol-palette > div.ol-select:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 12px;
  -webkit-box-shadow: 1px 1px #fff, 2px 2px #000;
          box-shadow: 1px 1px #fff, 2px 2px #000;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.ol-ext-colorpicker .ol-palette > div:hover {
  -webkit-box-shadow: 0 0 2px 1px #d90;
          box-shadow: 0 0 2px 1px #d90;
}
.ol-ext-colorpicker .ol-palette hr {
  margin: 0;
}

.ol-input-popup {
  display: inline-block;
  position: relative;
}
.ol-input-popup .ol-popup {
  position: absolute;
  -webkit-box-shadow: 1px 1px 3px 1px #999;
          box-shadow: 1px 1px 3px 1px #999;
  background-color: #fff;
  z-index: 1;
  display: none;
  left: -5px;
  padding: 0;
  margin: 0;
  list-style: none;
  white-space: nowrap;
}
.ol-input-popup.ol-hover:hover .ol-popup,
.ol-input-popup.ol-focus .ol-popup {
  display: block;
}
.ol-input-popup.ol-right .ol-popup {
  left: auto;
  right: -5px;
}
.ol-input-popup.ol-middle .ol-popup {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


.ol-input-popup .ol-popup li {
  position: relative;
  padding: 10px 5px;
}

.ol-input-popup li:hover {
  background-color: #ccc;
}
.ol-input-popup li.ol-selected {
  background-color: #ccc;
}

.ol-input-popup.ol-fixed:hover .ol-popup,
.ol-input-popup.ol-fixed .ol-popup {
  position: relative;
  left: 0;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
}
.ol-input-popup.ol-fixed.ol-left .ol-popup {
  float: left;
}

.ol-input-popup > div {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  border: 1px solid #999;
  padding: 3px 20px 3px 10px
}
.ol-input-popup > div:before {
  position: absolute;
  content: "";
  right: 5px;
  top: 50%;
  border: 5px solid transparent;
  border-top: 5px solid #999;
}

.ol-ext-popup-input {
  display: inline-block;
  vertical-align: top;
}
.ol-ext-popup-input.ol-popup {
  position: relative;
  width: 2em;
  height: 1.5em;
  display: inline-block;
  border: 3px solid #fff;
  border-right-width: 1em;
  -webkit-box-shadow: 0 0 2px 1px #666;
          box-shadow: 0 0 2px 1px #666;
  border-radius: 2px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
}
.ol-ext-popup-input.ol-popup:after {
  content: "";
  position: absolute;
  border: .5em solid #aaa;
  border-width: .5em .3em 0;
  border-color: #999 transparent;
  right: -.8em;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}

.ol-ext-popup-input * {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.ol-ext-popup-input.ol-popup .ol-popup {
  position: absolute;
  top: 100%;
  min-width: 3em;
  min-height: 3em;
  left: 0;
  -webkit-box-shadow: 1px 1px 3px 1px #999;
          box-shadow: 1px 1px 3px 1px #999;
  display: block;
  background-color: #fff;
  display: none;
  z-index: 1;
}
.ol-ext-popup-input.ol-popup .ol-popup.ol-visible {
  display: block;
}

.ol-ext-popup-input.ol-popup-fixed .ol-popup {
  position: fixed;
  top: auto;
  left: auto;
}

.ol-input-popup.ol-size li {
  display: table-cell;
  height: 100%;
  padding: 5px;
  vertical-align: middle;
}

.ol-input-popup.ol-size li > * {
  background-color: #369;
  border-radius: 50%;
  vertical-align: middle;
  width: 1em;
  height: 1em;
}

.ol-input-popup.ol-size li > .ol-option-0 {
  position: relative;
  width: 1em;
  height: 1em;
  border: 2px solid currentColor;
  color: #aaa;
  background-color: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ol-input-popup.ol-size li > *:before {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ol-input-popup.ol-size li > .ol-option-0:before {
  content: "";
  width: 1em;
  height: 2px;
  background-color: #aaa;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.ol-input-slider {
  display: inline-block;
  position: relative;
}
.ol-input-slider .ol-popup {
  position: absolute;
  -webkit-box-shadow: 1px 1px 3px 1px #999;
          box-shadow: 1px 1px 3px 1px #999;
  background-color: #fff;
  z-index: 1;
  display: none;
  left: -5px;
}
.ol-input-slider.ol-right .ol-popup {
  left: auto;
  right: -5px;
}
.ol-input-slider.ol-hover:hover .ol-popup,
.ol-input-slider.ol-focus .ol-popup {
  display: block;
  white-space: nowrap;
}
.ol-input-slider.ol-hover:hover .ol-popup > *,
.ol-input-slider.ol-focus .ol-popup > * {
  display: inline-block;
  vertical-align: middle;
}
.ol-input-slider.ol-hover:hover .ol-popup > .ol-before,
.ol-input-slider.ol-focus .ol-popup > .ol-before {
  margin-left: 10px;
}
.ol-input-slider.ol-hover:hover .ol-popup > .ol-after,
.ol-input-slider.ol-focus .ol-popup > .ol-after {
  margin-right: 10px;
}
.ol-input-slider .ol-slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100px;
  height: 3px;
  border: 0 solid transparent;
  border-width: 10px 15px;
  -webkit-box-shadow: inset 0 0 0 1px #999;
          box-shadow: inset 0 0 0 1px #999;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  cursor: pointer;
}

.ol-input-slider .ol-slider > .ol-cursor {
  position: absolute;
  width: 5px;
  height: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #999;
  pointer-events: none;
}
.ol-input-range .ol-slider {
  cursor: default;
  width: 150px;
}
.ol-input-range .ol-slider > .ol-cursor {
  pointer-events: all;
  cursor: pointer;
  width: 10px;
  border-radius: 50%;
  background-color: rgb(0,60,136);
}
.ol-input-range.ol-moving .ol-slider > .ol-cursor {
  pointer-events: none;
}
.ol-input-range .ol-slider > .ol-back {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30%;
  right: 20%;
  height: 100%;
  background-color: rgb(0,60,136);
  pointer-events: none;
}

.ol-input-slider.ol-fixed:hover .ol-popup,
.ol-input-slider.ol-fixed .ol-popup {
  position: relative;
  left: 0;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
}
.ol-input-slider.ol-fixed.ol-left .ol-popup {
  float: left;
}

/* Grow */
.ol-input-slider.ol-size .ol-slider {
  height: auto;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ol-input-slider.ol-size .ol-slider .ol-back {
  width: 0;
  color: #aaa;
  border: 0 solid transparent;
  border-width: 0 0 20px 100px;
  border-color: currentColor transparent;
  pointer-events: none;
}

.ol-input-slider.ol-size .ol-slider > .ol-cursor {
  width: 2px;
  height: calc(100% + 4px);
  border-width: 5px 3px;
  border-style: solid;
  border-color: #f00 transparent;
  -o-border-image: initial;
     border-image: initial;
  background-color: transparent;
  -webkit-box-shadow: inset 3px 0px #f00;
          box-shadow: inset 3px 0px #f00;
}

.ol-input-popup.ol-width li {
  padding: 5px;
}


.ol-input-popup.ol-width li > * {
  background-color: #369;
  width: 100px;
  height: 1em;
}

.ol-input-popup.ol-width li > .ol-option-0 {
  position: relative;
  height: 1px;
  background-image: linear-gradient(90deg,#aaa 2px, transparent 2px);
  background-color: transparent;
  background-size: 4px;
}
