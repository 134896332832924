.ol-control i {
  cursor: default;
}

.ol-control.ol-bar {
  white-space: nowrap;
  min-width: 1em;
  min-height: 1em;
  position: absolute;
  top: .5em;
  left: 50%;
  transform: translate(-50%);
}

.ol-control.ol-toggle .ol-option-bar {
  display: none;
}

.ol-control.ol-bar .ol-control {
  vertical-align: middle;
  background-color: #0000;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  inset: auto;
  transform: none;
}

.ol-control.ol-bar .ol-bar {
  position: static;
}

.ol-control.ol-bar .ol-control button {
  outline: none;
  margin: 2px 1px;
}

.ol-control.ol-bar.ol-left {
  top: 50%;
  left: .5em;
  transform: translate(0, -50%);
}

.ol-control.ol-bar.ol-left .ol-control {
  display: block;
}

.ol-control.ol-bar.ol-right {
  top: 50%;
  left: auto;
  right: .5em;
  transform: translate(0, -50%);
}

.ol-control.ol-bar.ol-right .ol-control {
  display: block;
}

.ol-control.ol-bar.ol-bottom {
  top: auto;
  bottom: .5em;
}

.ol-control.ol-bar.ol-top.ol-left, .ol-control.ol-bar.ol-top.ol-right {
  top: 4.5em;
  transform: none;
}

.ol-touch .ol-control.ol-bar.ol-top.ol-left, .ol-touch .ol-control.ol-bar.ol-top.ol-right {
  top: 5.5em;
}

.ol-control.ol-bar.ol-bottom.ol-left, .ol-control.ol-bar.ol-bottom.ol-right {
  top: auto;
  bottom: .5em;
  transform: none;
}

.ol-control.ol-bar.ol-group {
  margin: 1px 1px 1px 0;
}

.ol-control.ol-bar.ol-right .ol-group, .ol-control.ol-bar.ol-left .ol-group {
  margin: 1px 1px 0;
}

.ol-control.ol-bar.ol-group button {
  border-radius: 0;
  margin: 0 0 0 1px;
}

.ol-control.ol-bar.ol-right.ol-group button, .ol-control.ol-bar.ol-left.ol-group button, .ol-control.ol-bar.ol-right .ol-group button, .ol-control.ol-bar.ol-left .ol-group button {
  margin: 0 0 1px;
}

.ol-control.ol-bar.ol-group .ol-control:first-child > button {
  border-radius: 5px 0 0 5px;
}

.ol-control.ol-bar.ol-group .ol-control:last-child > button {
  border-radius: 0 5px 5px 0;
}

.ol-control.ol-bar.ol-left.ol-group .ol-control:first-child > button, .ol-control.ol-bar.ol-right.ol-group .ol-control:first-child > button, .ol-control.ol-bar.ol-left .ol-group .ol-control:first-child > button, .ol-control.ol-bar.ol-right .ol-group .ol-control:first-child > button {
  border-radius: 5px 5px 0 0;
}

.ol-control.ol-bar.ol-left.ol-group .ol-control:last-child > button, .ol-control.ol-bar.ol-right.ol-group .ol-control:last-child > button, .ol-control.ol-bar.ol-left .ol-group .ol-control:last-child > button, .ol-control.ol-bar.ol-right .ol-group .ol-control:last-child > button {
  border-radius: 0 0 5px 5px;
}

.ol-control.ol-bar .ol-rotate {
  opacity: 1;
  visibility: visible;
}

.ol-control.ol-bar .ol-rotate button {
  display: block;
}

.ol-control.ol-bar .ol-toggle.ol-active > button, .ol-control.ol-bar .ol-toggle.ol-active button:hover {
  background-color: #3c8800b3;
}

.ol-control.ol-toggle button:disabled {
  background-color: #003c884d;
}

.ol-control.ol-bar .ol-control.ol-option-bar {
  background-color: #fffc;
  border-radius: 0;
  margin: 5px 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 0 0 1px #003c8880, 1px 1px 2px #00000080;
}

.ol-control.ol-bar .ol-option-bar:before {
  content: "";
  border: .5em solid #0000;
  border-bottom-color: #003c8880;
  position: absolute;
  bottom: 100%;
  left: .3em;
}

.ol-control.ol-bar .ol-option-bar .ol-control {
  display: table-cell;
}

.ol-control.ol-bar .ol-control .ol-bar {
  display: none;
}

.ol-control.ol-bar .ol-control.ol-active > .ol-option-bar {
  display: block;
}

.ol-control.ol-bar .ol-control.ol-collapsed ul {
  display: none;
}

.ol-control.ol-bar .ol-control.ol-text-button > div:hover, .ol-control.ol-bar .ol-control.ol-text-button > div {
  color: #003c8880;
  background-color: #0000;
  width: auto;
  min-width: 1.375em;
  margin: 0;
}

.ol-control.ol-bar .ol-control.ol-text-button {
  border-left: 1px solid #003c88cc;
  border-radius: 0;
  font-size: .9em;
}

.ol-control.ol-bar .ol-control.ol-text-button:first-child {
  border-left: 0;
}

.ol-control.ol-bar .ol-control.ol-text-button > div {
  padding: .11em .3em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.14em;
  font-weight: normal;
}

.ol-control.ol-bar .ol-control.ol-text-button div:hover {
  color: #003c88;
}

.ol-control.ol-bar.ol-bottom .ol-option-bar {
  top: auto;
  bottom: 100%;
}

.ol-control.ol-bar.ol-bottom .ol-option-bar:before {
  border-color: #003c8880 #0000 #0000;
  top: 100%;
  bottom: auto;
}

.ol-control.ol-bar.ol-left .ol-option-bar {
  margin: 0 5px;
  top: 0;
  bottom: auto;
  left: 100%;
}

.ol-control.ol-bar.ol-left .ol-option-bar:before {
  border-color: #0000 #003c8880 #0000 #0000;
  inset: .3em 100% auto auto;
}

.ol-control.ol-bar.ol-right .ol-option-bar {
  margin: 0 5px;
  inset: 0 100% auto auto;
}

.ol-control.ol-bar.ol-right .ol-option-bar:before {
  border-color: #0000 #0000 #0000 #003c8880;
  top: .3em;
  bottom: auto;
  left: 100%;
}

.ol-control.ol-bar.ol-left .ol-option-bar .ol-option-bar, .ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar {
  margin: 5px 0;
  inset: 100% auto auto .3em;
}

.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar {
  left: auto;
  right: .3em;
}

.ol-control.ol-bar.ol-left .ol-option-bar .ol-option-bar:before, .ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar:before {
  border-color: #0000 #0000 #003c8880;
  inset: auto auto 100% .3em;
}

.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar:before {
  left: auto;
  right: .3em;
}

.ol-control-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ol-center-position {
  background-color: #fffc;
  padding: .1em 1em;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ol-compassctrl {
  display: none;
  top: 1em;
  left: auto;
  right: 1em;
}

.ol-compassctrl.ol-visible {
  display: block !important;
}

.ol-ext-dialog {
  opacity: 0;
  z-index: 1000;
  background-color: #00000080;
  width: 150%;
  height: 100%;
  transition: opacity .2s, top 0s .2s;
  position: fixed;
  top: -100%;
  left: 0;
  pointer-events: none !important;
}

.ol-ext-dialog.ol-visible {
  opacity: 1;
  transition: opacity .2s, top;
  top: 0;
  pointer-events: all !important;
}

.ol-viewport .ol-ext-dialog {
  position: absolute;
}

.ol-ext-dialog > form > h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 .5em .5em 0;
  display: none;
  overflow: hidden;
}

.ol-ext-dialog > form.ol-title > h2 {
  display: block;
}

.ol-ext-dialog > form {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #333;
  min-width: 5em;
  max-width: 60%;
  min-height: 3em;
  max-height: 100%;
  padding: 1em;
  transition: top .2s, transform .2s, -webkit-transform .2s;
  position: absolute;
  top: 0;
  left: 33.33%;
  overflow: hidden auto;
  transform: translate(-50%, -30%);
  box-shadow: 3px 3px 4px #00000080;
}

.ol-ext-dialog > form.ol-closebox {
  padding-top: 1.5em;
}

.ol-ext-dialog > form.ol-title {
  padding-top: 1em;
}

.ol-ext-dialog > form.ol-button {
  padding-bottom: .5em;
}

.ol-ext-dialog.ol-zoom > form {
  top: 30%;
  transform: translate(-50%, -30%)scale(0);
}

.ol-ext-dialog.ol-visible > form {
  top: 30%;
}

.ol-ext-dialog.ol-zoom.ol-visible > form {
  transform: translate(-50%, -30%)scale(1);
}

.ol-ext-dialog > form .ol-content {
  overflow: hidden auto;
}

.ol-ext-dialog > form .ol-closebox {
  cursor: pointer;
  width: 1em;
  height: 1em;
  display: none;
  position: absolute;
  top: .5em;
  right: .5em;
}

.ol-ext-dialog > form.ol-closebox .ol-closebox {
  display: block;
}

.ol-ext-dialog > form .ol-closebox:before, .ol-ext-dialog > form .ol-closebox:after {
  content: "";
  background-color: currentColor;
  border-radius: .1em;
  width: 1em;
  height: .1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.ol-ext-dialog > form .ol-closebox:before {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ol-ext-dialog > form .ol-buttons {
  text-align: right;
  overflow-x: hidden;
}

.ol-ext-dialog > form .ol-buttons input {
  color: #003c88;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: .25em;
  outline-width: 0;
  margin-top: .5em;
  padding: .5em;
  font-size: 1em;
}

.ol-ext-dialog > form .ol-buttons input:hover {
  background-color: #003c881a;
}

.ol-ext-dialog > form .ol-buttons input[type="submit"] {
  font-weight: bold;
}

.ol-ext-dialog .ol-progress-message {
  text-align: center;
  padding-bottom: .5em;
  font-size: .9em;
}

.ol-ext-dialog .ol-progress-bar {
  border: 1px solid #369;
  width: 20em;
  max-width: 100%;
  height: 1em;
  margin: .5em auto 0;
  padding: 2px;
  overflow: hidden;
}

.ol-ext-dialog .ol-progress-bar > div {
  background: #369;
  width: 50%;
  height: 100%;
  transition: width .3s;
}

.ol-ext-dialog .ol-progress-bar > div.notransition {
  transition: unset;
}

.ol-ext-dialog.ol-fullscreen-dialog form {
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: none;
  border: none;
  max-width: calc(66.6% - 2em);
  inset: 1em;
  transform: none;
}

.ol-ext-dialog.ol-fullscreen-dialog form .ol-closebox {
  font-size: 2em;
  top: 0;
  right: 0;
}

.ol-ext-dialog.ol-fullscreen-dialog .ol-closebox:before, .ol-ext-dialog.ol-fullscreen-dialog .ol-closebox:after {
  border: .1em solid;
}

.ol-ext-dialog.ol-fullscreen-dialog img, .ol-ext-dialog.ol-fullscreen-dialog video {
  max-width: 100%;
}

body > .ol-ext-dialog .ol-content {
  max-height: calc(100vh - 10em);
}

body > .ol-ext-dialog > form {
  overflow: visible;
}

.ol-editbar .ol-button button {
  box-sizing: border-box;
  vertical-align: middle;
  font-style: normal;
  display: inline-block;
  position: relative;
}

.ol-editbar .ol-button button:before, .ol-editbar .ol-button button:after {
  content: "";
  box-sizing: border-box;
  background-color: currentColor;
  border-width: 0;
  position: absolute;
}

.ol-editbar .ol-button button:focus {
  outline: none;
}

.ol-editbar .ol-selection > button:before {
  background-color: #0000;
  border: .25em solid #0000;
  border-top: 0 solid;
  border-bottom: .65em solid;
  width: .6em;
  height: 1em;
  top: .35em;
  left: .5em;
  transform: translate(-50%, -50%)rotate(-30deg);
  box-shadow: 0 .6em 0 -.23em;
}

.ol-editbar .ol-selection0 > button:after {
  background-color: #0000;
  border: .05em solid #0000;
  border-top: 0 solid;
  border-bottom: .7em solid;
  width: .28em;
  height: .6em;
  top: .5em;
  left: .7em;
  transform: rotate(-45deg);
}

.ol-editbar .ol-delete button:after, .ol-editbar .ol-delete button:before {
  width: 1em;
  height: .2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.ol-editbar .ol-delete button:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ol-editbar .ol-info button:before {
  border-radius: .03em;
  width: .25em;
  height: .6em;
  top: .47em;
  left: 50%;
  transform: translateX(-50%);
}

.ol-editbar .ol-info button:after {
  border-radius: .03em;
  width: .25em;
  height: .2em;
  top: .12em;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: -.1em .35em, -.1em .82em, .1em .82em;
}

.ol-editbar .ol-drawpoint button:before {
  background-color: #0000;
  border: .15em solid;
  border-radius: 50%;
  width: .7em;
  height: .7em;
  top: .2em;
  left: 50%;
  transform: translateX(-50%);
}

.ol-editbar .ol-drawpoint button:after {
  background-color: #0000;
  border: .2em solid #0000;
  border-top: .4em solid;
  border-bottom: 0 solid;
  width: .4em;
  height: .4em;
  top: .8em;
  left: 50%;
  transform: translateX(-50%);
}

.ol-editbar .ol-drawline > button:before, .ol-editbar .ol-drawpolygon > button:before, .ol-editbar .ol-drawhole > button:before {
  background-color: #0000;
  border: .13em solid;
  border-width: .2em .13em .09em;
  width: .8em;
  height: .8em;
  top: .2em;
  left: .25em;
  transform: rotate(10deg)perspective(1em)rotateX(40deg);
}

.ol-editbar .ol-drawline > button:before {
  border-bottom: 0;
}

.ol-editbar .ol-drawline > button:after, .ol-editbar .ol-drawhole > button:after, .ol-editbar .ol-drawpolygon > button:after {
  width: .3em;
  height: .3em;
  top: .2em;
  left: .25em;
  box-shadow: -.2em .55em, .6em .1em, .65em .7em;
}

.ol-editbar .ol-drawhole > button:after {
  box-shadow: -.2em .55em, .6em .1em, .65em .7em, .25em .35em;
}

.ol-editbar .ol-offset > button i, .ol-editbar .ol-transform > button i {
  width: .9em;
  height: .9em;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.ol-editbar .ol-offset > button i {
  width: .8em;
  height: .8em;
}

.ol-editbar .ol-offset > button i:before, .ol-editbar .ol-transform > button i:before, .ol-editbar .ol-transform > button i:after {
  content: "";
  background-color: currentColor;
  width: .1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
  box-shadow: .5em 0 0 .1em, -.5em 0 0 .1em;
}

.ol-editbar .ol-offset > button i:before {
  box-shadow: .45em 0 0 .1em, -.45em 0 0 .1em;
}

.ol-editbar .ol-transform > button i:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ol-editbar .ol-split > button:before {
  border-radius: 50%;
  width: .3em;
  height: .3em;
  top: .81em;
  left: .75em;
  box-shadow: .1em -.4em, -.15em -.25em;
}

.ol-editbar .ol-split > button:after {
  background-color: #0000;
  border: .2em solid;
  border-width: 0 .2em .2em 0;
  border-radius: .1em;
  width: .8em;
  height: .8em;
  top: .15em;
  left: -.1em;
  transform: rotate(20deg)scaleY(.6)rotate(-45deg);
}

.ol-editbar .ol-drawregular > button:before {
  background-color: #0000;
  border: .1em solid;
  width: .9em;
  height: .9em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-editbar .ol-drawregular .ol-bar .ol-text-button > div > div > div {
  cursor: pointer;
  vertical-align: text-bottom;
  border: .5em solid #0000;
  border-color: #0000 currentColor;
  display: inline-block;
}

.ol-editbar .ol-drawregular .ol-bar:before, .ol-control.ol-bar.ol-editbar .ol-drawregular .ol-bar {
  left: 50%;
  transform: translateX(-50%);
}

.ol-editbar .ol-drawregular .ol-bar .ol-text-button {
  text-align: center;
  min-width: 6em;
}

.ol-editbar .ol-drawregular .ol-bar .ol-text-button > div > div > div:first-child {
  border-width: .5em .5em .5em 0;
  margin: 0 .5em 0 0;
}

.ol-editbar .ol-drawregular .ol-bar .ol-text-button > div > div > div:last-child {
  border-width: .5em 0 .5em .5em;
  margin: 0 0 0 .5em;
}

.ol-gauge {
  top: .5em;
  left: 50%;
  transform: translateX(-50%);
}

.ol-gauge > * {
  vertical-align: middle;
  display: inline-block;
}

.ol-gauge > span {
  margin: 0 .5em;
}

.ol-gauge > div {
  border: 1px solid #003c8880;
  border-radius: 3px;
  width: 200px;
  padding: 1px;
  display: inline-block;
}

.ol-gauge button {
  max-width: 100%;
  height: .8em;
  margin: 0;
}

.ol-control.ol-bookmark {
  top: .5em;
  left: 3em;
}

.ol-control.ol-bookmark button {
  position: relative;
}

.ol-control.ol-bookmark > button:before {
  content: "";
  border: 5px solid #fff;
  border-top-width: 10px;
  border-bottom: 4px solid #0000;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-bookmark > div {
  min-width: 5em;
  display: none;
}

.ol-control.ol-bookmark input {
  margin: .1em 0;
  padding: 0 .5em;
  font-size: .9em;
}

.ol-control.ol-bookmark ul {
  min-width: 10em;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ol-control.ol-bookmark li {
  color: #003c88cc;
  cursor: default;
  clear: both;
  padding: 0 .2em 0 .5em;
  font-size: .9em;
}

.ol-control.ol-bookmark li:hover {
  color: #fff;
  background-color: #003c8880;
}

.ol-control.ol-bookmark > div button {
  float: right;
  cursor: pointer;
  background-color: #0000;
  border-radius: 0;
  width: 1em;
  height: .8em;
}

.ol-control.ol-bookmark > div button:before {
  content: "⨯";
  color: #936;
  border-radius: 0;
  font-size: 1.2em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-bookmark ul li button, .ol-bookmark input {
  display: none;
}

.ol-bookmark.ol-editable ul li button, .ol-bookmark.ol-editable input {
  display: block;
}

.ol-control.ol-geobt {
  inset: auto .5em 3em auto;
}

.ol-touch .ol-control.ol-geobt {
  bottom: 3.5em;
}

.ol-control.ol-geobt button:before {
  content: "";
  background: none;
  border: .16em solid;
  border-radius: 50%;
  width: .4em;
  height: .4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-geobt button:after {
  content: "";
  background: none;
  width: .2em;
  height: .2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: .42em 0, -.42em 0, 0 .42em, 0 -.42em;
}

.ol-control.ol-bar.ol-geobar .ol-control {
  vertical-align: middle;
  display: inline-block;
}

.ol-control.ol-bar.ol-geobar .ol-bar {
  display: none;
}

.ol-bar.ol-geobar.ol-active .ol-bar {
  display: inline-block;
}

.ol-bar.ol-geobar .geolocBt button:before, .ol-bar.ol-geobar .geolocBt button:after {
  content: "";
  border-top: .3em solid #fff;
  border-bottom: 0 solid #0000;
  border-left: .2em solid #0000;
  border-right: .8em solid #0000;
  font-size: 1.2em;
  display: block;
  position: absolute;
  top: .45em;
  left: .15em;
  transform: rotate(-30deg);
}

.ol-bar.ol-geobar .geolocBt button:after {
  border-width: 0 .8em .3em .2em;
  border-color: #0000 #0000 #fff;
  transform: rotate(-61deg);
}

.ol-bar.ol-geobar .startBt button:before {
  content: "";
  background-color: #800;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-bar.ol-geobar .pauseBt button:before, .ol-bar.ol-geobar .pauseBt button:after {
  content: "";
  background-color: #fff;
  width: .25em;
  height: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.ol-bar.ol-geobar .pauseBt button:after {
  left: 65%;
}

.ol-control.ol-bar.ol-geobar .centerBt, .ol-control.ol-bar.ol-geobar .pauseBt, .ol-bar.ol-geobar.pauseTrack .startBt, .ol-bar.ol-geobar.centerTrack .startBt, .ol-bar.ol-geobar.centerTrack.pauseTrack .pauseBt, .ol-bar.ol-geobar.centerTrack .pauseBt {
  display: none;
}

.ol-bar.ol-geobar.pauseTrack .pauseBt, .ol-bar.ol-geobar.centerTrack .centerBt {
  display: inline-block;
}

.ol-control.ol-globe {
  opacity: .7;
  transform-origin: 0 100%;
  border-radius: 50%;
  position: absolute;
  bottom: .5em;
  left: .5em;
  transform: scale(.5);
}

.ol-control.ol-globe:hover {
  opacity: .9;
}

.ol-control.ol-globe .panel {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #ffffff80;
}

.ol-control.ol-globe .panel .ol-viewport {
  border-radius: 50%;
}

.ol-control.ol-globe .ol-pointer {
  z-index: 1;
  background-color: #fff;
  border: 10px solid red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: opacity .15s, top, left;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-15px, -40px);
}

.ol-control.ol-globe .ol-pointer.hidden {
  opacity: 0;
  transition: opacity .15s, top 3s, left 5s;
}

.ol-control.ol-globe .ol-pointer:before {
  content: "";
  border-radius: 50%;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 23px;
  left: 0;
  box-shadow: 6px 6px 10px 5px #000;
}

.ol-control.ol-globe .ol-pointer:after {
  content: "";
  border: 10px solid #0000;
  border-top: 20px solid red;
  border-bottom: 0 solid red;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 100%;
  left: -50%;
}

.ol-control.ol-globe .panel:before {
  content: "";
  z-index: 1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset -20px -20px 80px 2px #000000b3;
}

.ol-control.ol-globe .panel:after {
  content: "";
  z-index: 1;
  border-radius: 50%;
  width: 20%;
  height: 0;
  display: block;
  position: absolute;
  top: 20%;
  left: 23%;
  transform: rotate(-40deg);
  box-shadow: 0 0 20px 7px #fff;
}

.ol-control.ol-globe.ol-collapsed .panel {
  display: none;
}

.ol-control-top.ol-globe {
  transform-origin: 0 0;
  top: 5em;
  bottom: auto;
}

.ol-control-right.ol-globe {
  transform-origin: 100% 100%;
  left: auto;
  right: .5em;
}

.ol-control-right.ol-control-top.ol-globe {
  transform-origin: 100% 0;
  left: auto;
  right: .5em;
}

.ol-gridreference {
  background: #fff;
  border: 1px solid #000;
  max-height: 100%;
  top: 0;
  right: 0;
  overflow: auto;
}

.ol-gridreference input {
  width: 100%;
}

.ol-gridreference ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ol-gridreference li {
  cursor: pointer;
  padding: 0 .5em;
}

.ol-gridreference ul li:hover {
  background-color: #ccc;
}

.ol-gridreference li.ol-title, .ol-gridreference li.ol-title:hover {
  color: #fff;
  cursor: default;
  background: #003c8880;
}

.ol-gridreference ul li .ol-ref {
  margin-left: .5em;
}

.ol-gridreference ul li .ol-ref:before {
  content: "(";
}

.ol-gridreference ul li .ol-ref:after {
  content: ")";
}

.ol-control.ol-imageline {
  border-radius: 0;
  padding: 0;
  transition: all .3s;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

.ol-control.ol-imageline.ol-collapsed {
  transform: translateY(100%);
}

.ol-imageline > div {
  white-space: nowrap;
  scroll-behavior: smooth;
  width: 100%;
  height: 4em;
  position: relative;
  overflow: hidden;
}

.ol-imageline > div.ol-move {
  scroll-behavior: unset;
}

.ol-control.ol-imageline button {
  margin: .65em;
  position: absolute;
  top: -1em;
  transform: translateY(-100%);
  box-shadow: 0 0 0 .15em #fff6;
}

.ol-control.ol-imageline button:before {
  content: "";
  border: .15em solid;
  border-width: .15em .15em 0 0;
  width: .4em;
  height: .4em;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(135deg);
}

.ol-control.ol-imageline.ol-collapsed button:before {
  top: 60%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ol-imageline, .ol-imageline:hover {
  background-color: #000000bf;
}

.ol-imageline.ol-arrow:after, .ol-imageline.ol-arrow:before {
  content: "";
  z-index: 1;
  opacity: .8;
  pointer-events: none;
  border: 1em solid #fff;
  border-left: 0 solid #000;
  border-right: .6em solid #000;
  position: absolute;
  top: 50%;
  left: .2em;
  transform: translateY(-50%);
  box-shadow: -.6em 0 0 1em #fff;
}

.ol-imageline.ol-arrow:after {
  border-width: 1em 0 1em .6em;
  left: auto;
  right: .2em;
  box-shadow: .6em 0 0 1em #fff;
}

.ol-imageline.ol-scroll0.ol-arrow:before, .ol-imageline.ol-scroll1.ol-arrow:after {
  display: none;
}

.ol-imageline .ol-image {
  cursor: pointer;
  height: 100%;
  display: inline-block;
  position: relative;
}

.ol-imageline img {
  box-sizing: border-box;
  opacity: 0;
  border: .25em solid #0000;
  max-height: 100%;
  transition: all 1s;
}

.ol-imageline img.ol-loaded {
  opacity: 1;
}

.ol-imageline .ol-image.select {
  background-color: #fff;
}

.ol-imageline .ol-image span {
  color: #fff;
  white-space: normal;
  text-align: center;
  background-color: #00000080;
  width: 125%;
  max-height: 2.4em;
  font-size: .8em;
  line-height: 1.2em;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%)scaleX(.8);
}

.ol-imageline .ol-image:hover span {
  display: block;
}

.ol-control.ol-routing.ol-isochrone .ol-method-time, .ol-control.ol-routing.ol-isochrone .ol-method-distance, .ol-control.ol-routing.ol-isochrone > button {
  position: relative;
}

.ol-control.ol-routing.ol-isochrone .ol-method-time:before, .ol-control.ol-routing.ol-isochrone > button:before {
  content: "";
  clip: unset;
  border: .1em solid;
  border-radius: 50%;
  width: .8em;
  height: .8em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 -.5em 0 -.35em, .4em -.35em 0 -.35em;
}

.ol-control.ol-routing.ol-isochrone .ol-method-time:after, .ol-control.ol-routing.ol-isochrone > button:after {
  content: "";
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  clip: unset;
  border: .3em solid #0000;
  border-right-color: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-60deg);
}

.ol-control.ol-routing.ol-isochrone .ol-method-distance:before {
  content: "";
  box-sizing: border-box;
  border: .1em solid;
  width: 1em;
  height: .5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-30deg);
}

.ol-control.ol-routing.ol-isochrone .ol-method-distance:after {
  content: "";
  width: .1em;
  height: .15em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-30deg);
  box-shadow: inset 0 -.15em, 0 .1em, .25em .1em, -.25em .1em;
}

.ol-control.ol-routing.ol-isochrone .ol-direction-direct:before, .ol-control.ol-routing.ol-isochrone .ol-direction-reverse:before {
  content: "";
  box-sizing: border-box;
  border: .1em solid;
  border-radius: 50%;
  width: .3em;
  height: .3em;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  box-shadow: .25em 0 0 -.05em;
}

.ol-control.ol-routing.ol-isochrone .ol-direction-direct:after, .ol-control.ol-routing.ol-isochrone .ol-direction-reverse:after {
  content: "";
  border: .4em solid #0000;
  border-left-color: currentColor;
  border-right: 0 solid;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-routing.ol-isochrone .ol-direction-reverse:after {
  border-width: .4em .4em .4em 0;
}

.ol-control.ol-isochrone.ol-collapsed .content {
  display: none;
}

.ol-control.ol-isochrone input[type="number"] {
  text-align: right;
  width: 3em;
  margin: 0 .1em;
}

.ol-control.ol-isochrone .ol-distance input[type="number"] {
  width: 5em;
}

.ol-isochrone .ol-time, .ol-isochrone .ol-distance {
  display: none;
}

.ol-isochrone .ol-time.selected, .ol-isochrone .ol-distance.selected {
  display: block;
}

.ol-control.ol-layerswitcher-popup {
  text-align: left;
  position: absolute;
  top: 3em;
  right: .5em;
}

.ol-control.ol-layerswitcher-popup .panel {
  clear: both;
  background: #fff;
}

.ol-layerswitcher-popup .panel {
  margin: 0;
  padding: .25em;
  list-style: none;
  overflow: hidden;
}

.ol-layerswitcher-popup .panel ul {
  padding: 0 0 0 20px;
  list-style: none;
  overflow: hidden;
}

.ol-layerswitcher-popup.ol-collapsed .panel {
  display: none;
}

.ol-layerswitcher-popup.ol-forceopen .panel {
  display: block;
}

.ol-layerswitcher-popup button {
  float: right;
  background-color: #fff;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: center;
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
}

.ol-layerswitcher-popup li {
  color: #369;
  cursor: pointer;
  padding: .25em 1em;
  font-family: Trebuchet MS, Helvetica, sans-serif;
}

.ol-layerswitcher-popup li.ol-header {
  display: none;
}

.ol-layerswitcher-popup li.select, .ol-layerswitcher-popup li.ol-visible {
  color: #fff;
  background: #003c88b3;
}

.ol-layerswitcher-popup li:hover {
  color: #fff;
  background: #003c88e6;
}

.ol-control.ol-layerswitcher.ol-layer-shop {
  max-height: unset;
  background-color: #0000;
  max-width: 16em;
  height: calc(100% - 4em);
  overflow: visible;
  pointer-events: none !important;
}

.ol-control.ol-layerswitcher > * {
  pointer-events: auto;
}

.ol-control.ol-layer-shop > button, .ol-control.ol-layer-shop .panel-container {
  box-shadow: 0 0 0 3px #ffffff80;
}

.ol-control.ol-layerswitcher.ol-layer-shop .panel-container {
  border: 2px solid #369;
  border-width: 2px 0;
  max-height: calc(100% - 6.5em);
  padding: 0;
  overflow-y: scroll;
}

.ol-control.ol-layer-shop .panel {
  box-sizing: border-box;
  margin: .25em .5em;
  padding: 0;
}

.ol-control.ol-layerswitcher.ol-layer-shop .panel-container.ol-scrolldiv {
  overflow: hidden;
}

.ol-control.ol-layer-shop .ol-scroll {
  opacity: .5;
  background-color: #0000004d;
}

.ol-layerswitcher.ol-layer-shop ul.panel li.ol-header {
  display: none;
}

.ol-layerswitcher.ol-layer-shop ul.panel li {
  margin-right: 0;
  padding-right: 0;
}

.ol-layerswitcher.ol-layer-shop .layerup {
  box-sizing: border-box;
  color: #003c88;
  background-color: #0000;
  border-radius: 3px;
  width: 1.4em;
  height: 1.5em;
  margin: 0;
}

.ol-layerswitcher.ol-layer-shop .layerup:hover {
  background-color: #003c884d;
}

.ol-layerswitcher.ol-layer-shop .layerup:before {
  background-color: currentColor;
  border: 0;
  width: 1em;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 -4px, 0 4px;
}

.ol-layerswitcher.ol-layer-shop .layerup:after {
  content: unset;
}

.ol-control.ol-layer-shop .ol-title-bar {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  transform-origin: 100% 0;
  background-color: #ffffff80;
  max-width: 14.6em;
  height: calc(2.8em - 4px);
  padding: .7em .5em;
  font-size: .9em;
  transition: transform .1s, width, -webkit-transform .1s;
  overflow: hidden;
  transform: scaleY(1.1);
}

.ol-control.ol-layer-shop:hover .ol-title-bar {
  background-color: #ffffffb3;
}

.ol-control.ol-layer-shop.ol-collapsed .ol-title-bar {
  max-width: 10em;
  transform: scale(.9, 1.1);
}

.ol-control.ol-layer-shop.ol-forceopen .ol-title-bar {
  max-width: 14.6em;
  transform: scaleY(1.1);
}

.ol-control.ol-layer-shop .ol-bar {
  clear: both;
  text-align: right;
  z-index: 10;
  background-color: #fff;
  height: 1.75em;
  position: relative;
  box-shadow: 0 0 0 3px #ffffff80;
}

.ol-control.ol-layer-shop.ol-collapsed .ol-scroll, .ol-control.ol-layer-shop.ol-collapsed .ol-bar {
  border-width: 2px 0 0;
  display: none;
}

.ol-control.ol-layer-shop.ol-forceopen .ol-scroll, .ol-control.ol-layer-shop.ol-forceopen .ol-bar {
  display: block;
}

.ol-control.ol-layer-shop .ol-bar > * {
  vertical-align: middle;
  background-color: #0000;
  margin-top: .25em;
  font-size: .9em;
  display: inline-block;
}

.ol-layer-shop .ol-bar .ol-button, .ol-touch .ol-layer-shop .ol-bar .ol-button {
  top: unset;
  left: unset;
  bottom: unset;
  right: unset;
  margin: 0;
  position: relative;
}

.ol-layer-shop .ol-bar button {
  color: #003c88;
  background-color: #fff;
}

.ol-layer-shop .ol-bar button:hover {
  background-color: #003c8833;
}

.ol-touch .ol-layerswitcher.ol-layer-shop > button {
  font-size: 1.7em;
}

.ol-touch .ol-layer-shop .ol-bar {
  height: 2em;
}

.ol-touch .ol-layer-shop .ol-control button {
  font-size: 1.4em;
}

.ol-touch .ol-control.ol-layer-shop .panel {
  max-height: calc(100% - 7em);
}

.ol-touch .ol-control.ol-layer-shop .panel label {
  height: 1.8em;
}

.ol-touch .ol-control.ol-layer-shop .panel label span {
  margin-left: .5em;
  padding-top: .25em;
}

.ol-touch .ol-control.ol-layer-shop .panel label:before, .ol-touch .ol-control.ol-layer-shop .panel label:after {
  z-index: 1;
  font-size: 1.3em;
}

.ol-control.ol-layerswitcher {
  text-align: left;
  box-sizing: border-box;
  max-height: calc(100% - 6em);
  position: absolute;
  top: 3em;
  right: .5em;
  overflow: hidden;
}

.ol-control.ol-layerswitcher .ol-switchertopdiv, .ol-control.ol-layerswitcher .ol-switcherbottomdiv {
  display: block;
}

.ol-control.ol-layerswitcher.ol-collapsed .ol-switchertopdiv, .ol-control.ol-layerswitcher.ol-collapsed .ol-switcherbottomdiv {
  display: none;
}

.ol-layerswitcher.ol-forceopen.ol-collapsed .ol-switchertopdiv, .ol-layerswitcher.ol-forceopen.ol-collapsed .ol-switcherbottomdiv {
  display: block;
}

.ol-control.ol-layerswitcher .ol-switchertopdiv, .ol-control.ol-layerswitcher .ol-switcherbottomdiv {
  z-index: 2;
  opacity: 1;
  cursor: pointer;
  box-sizing: border-box;
  background: #fff;
  border-top: 2px solid #0000;
  border-bottom: 2px solid #369;
  height: 45px;
  margin: 0 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ol-control.ol-layerswitcher .ol-switcherbottomdiv {
  border-top: 2px solid #369;
  border-bottom: 2px solid #0000;
  height: 2em;
  top: auto;
  bottom: 0;
}

.ol-control.ol-layerswitcher .ol-switchertopdiv:before, .ol-control.ol-layerswitcher .ol-switcherbottomdiv:before {
  content: "";
  opacity: .8;
  border: 10px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-layerswitcher .ol-switchertopdiv:hover:before, .ol-control.ol-layerswitcher .ol-switcherbottomdiv:hover:before {
  opacity: 1;
}

.ol-control.ol-layerswitcher .ol-switchertopdiv:before {
  border-top: 0;
  border-bottom-color: #369;
}

.ol-control.ol-layerswitcher .ol-switcherbottomdiv:before {
  border-top-color: #369;
  border-bottom: 0;
}

.ol-control.ol-layerswitcher .panel-container {
  clear: both;
  background-color: #fff;
  border-radius: 0 0 2px 2px;
  padding: .5em .5em 0;
  display: block;
}

.ol-layerswitcher .panel {
  margin: 0;
  padding: 0;
  font-family: Tahoma, Geneva, sans-serif;
  font-size: .9em;
  list-style: none;
  transition: top .3s;
  position: relative;
  top: 0;
  overflow: hidden;
}

.ol-layerswitcher .panel ul {
  clear: both;
  padding: 0 0 0 20px;
  list-style: none;
  overflow: hidden;
}

.ol-layerswitcher input[type="radio"], .ol-layerswitcher input[type="checkbox"] {
  display: none;
}

.ol-layerswitcher .panel li {
  -weblit-transition: -webkit-transform .2s linear;
  clear: both;
  box-sizing: border-box;
  border: 1px solid #0000;
  transition: transform .2s linear, -webkit-transform .2s linear;
  display: block;
}

.ol-layerswitcher .panel li.ol-layer-select {
  background-color: #003c8833;
  margin: 0 -.5em;
  padding: 0 .5em;
}

.ol-layerswitcher .panel li.drag {
  opacity: .5;
  transform: scale(.8);
}

.ol-dragover {
  opacity: .8;
  background: #33669980;
}

.ol-layerswitcher .panel li.forbidden, .forbidden .ol-layerswitcher-buttons div, .forbidden .layerswitcher-opacity div {
  background: #ff000080;
  color: red !important;
}

.ol-layerswitcher.drag, .ol-layerswitcher.drag * {
  cursor: not-allowed !important;
  cursor: no-drop !important;
}

.ol-layerswitcher.drag .panel li.dropover, .ol-layerswitcher.drag .panel li.dropover * {
  cursor: pointer !important;
  cursor: n-resize !important;
  cursor: ns-resize !important;
  cursor: -webkit-grab !important;
  cursor: grab !important;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.ol-layerswitcher .panel li.dropover {
  background: #33669980;
}

.ol-layerswitcher .panel li label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 12em;
  height: 1.4em;
  padding: 0 0 0 1.7em;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ol-layerswitcher .panel li label span {
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  padding-right: .2em;
  display: inline-block;
  overflow: hidden;
}

.ol-layerswitcher [type="radio"] + label:before, .ol-layerswitcher [type="checkbox"] + label:before, .ol-layerswitcher [type="radio"]:checked + label:after, .ol-layerswitcher [type="checkbox"]:checked + label:after {
  content: "";
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #369;
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: .1em;
  left: .1em;
}

.ol-layerswitcher [type="radio"] + label:before, .ol-layerswitcher [type="radio"] + label:after {
  border-radius: 50%;
}

.ol-layerswitcher [type="radio"]:checked + label:after {
  background: #369;
  width: .6em;
  height: .6em;
  margin: .3em;
}

.ol-layerswitcher [type="checkbox"]:checked + label:after {
  background: none;
  border: 3px solid #369;
  border-width: 0 3px 3px 0;
  width: .7em;
  height: 1em;
  top: -.05em;
  left: .55em;
  transform: rotate(45deg);
  box-shadow: 1px 0 1px 1px #fff;
}

.ol-layerswitcher .panel li.ol-layer-hidden {
  opacity: .6;
}

.ol-layerswitcher.ol-collapsed .panel-container {
  display: none;
}

.ol-layerswitcher.ol-forceopen .panel-container {
  display: block;
}

.ol-layerswitcher > button {
  float: right;
  z-index: 10;
  background-color: #fff;
  font-size: 1.7em;
  position: relative;
}

.ol-touch .ol-layerswitcher > button {
  font-size: 2.5em;
}

.ol-layerswitcher > button:before, .ol-layerswitcher > button:after {
  content: "";
  border-radius: .15em;
  width: .75em;
  height: .75em;
  position: absolute;
  transform: scaleY(.8)rotate(45deg);
}

.ol-layerswitcher > button:before {
  background: #e2e4e1;
  top: .32em;
  left: .34em;
  box-shadow: .1em .1em #325158;
}

.ol-layerswitcher > button:after {
  background: #83bcc5 radial-gradient(circle at .85em .6em, #70b3be 0 .65em, #83bcc5 .65em);
  top: .22em;
  left: .34em;
}

.ol-layerswitcher-buttons {
  float: right;
  text-align: right;
  display: block;
}

.ol-layerswitcher-buttons > div {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  background: #369;
  width: 1em;
  height: 1em;
  margin: 2px;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.ol-layerswitcher .panel li > div {
  display: inline-block;
  position: relative;
}

.ol-layerswitcher .ol-separator {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  display: block;
}

.ol-layerswitcher .layerup {
  float: right;
  opacity: .5;
  cursor: move;
  cursor: ns-resize;
  background-color: #369;
  height: 2.5em;
}

.ol-layerswitcher .layerup:before, .ol-layerswitcher .layerup:after {
  content: "";
  border: .4em solid #0000;
  border-top-color: #fff;
  border-bottom: 0 solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 3px;
  left: .1em;
}

.ol-layerswitcher .layerup:after {
  border-width: 0 .4em .4em;
  top: 3px;
  bottom: auto;
}

.ol-layerswitcher .layerInfo {
  background: #369;
  border-radius: 100%;
}

.ol-layerswitcher .layerInfo:before {
  color: #fff;
  content: "i";
  text-align: center;
  width: 1.25em;
  font-size: .8em;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ol-layerswitcher .layerTrash {
  background: #369;
}

.ol-layerswitcher .layerTrash:before {
  color: #fff;
  content: "×";
  text-align: center;
  margin: -.5em 0;
  font-size: 1em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.ol-layerswitcher .layerExtent {
  background: #369;
}

.ol-layerswitcher .layerExtent:before {
  content: "";
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  display: block;
  position: absolute;
  inset: 6px 2px 3px 6px;
}

.ol-layerswitcher .layerExtent:after {
  content: "";
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  display: block;
  position: absolute;
  inset: 3px 6px 6px 2px;
}

.ol-layerswitcher .expend-layers, .ol-layerswitcher .collapse-layers {
  background-color: #0000;
  margin: 0 2px;
}

.ol-layerswitcher .expend-layers:before, .ol-layerswitcher .collapse-layers:before {
  content: "";
  background: #369;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  left: 0;
}

.ol-layerswitcher .expend-layers:after {
  content: "";
  background: #369;
  width: 4px;
  height: 100%;
  margin-left: -2px;
  position: absolute;
  top: 0;
  left: 50%;
}

.ol-layerswitcher .layerswitcher-opacity {
  box-sizing: border-box;
  background: #69c;
  cursor: pointer;
  background: linear-gradient(to right, #003c8800, #003c8899);
  border: 1px solid #369;
  border-radius: 3px;
  width: 120px;
  height: 3px;
  margin: 5px 1em 10px 7px;
  position: relative;
  box-shadow: 1px 1px 1px #00000080;
}

.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor, .ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor:before {
  z-index: 1;
  background: #003c8880;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor:before {
  content: "";
  width: 50%;
  height: 50%;
  position: absolute;
}

.ol-touch .ol-layerswitcher .layerswitcher-opacity .layerswitcher-opacity-cursor {
  width: 26px;
  height: 26px;
}

.ol-layerswitcher .layerswitcher-opacity-label {
  font-size: .8em;
  display: none;
  position: absolute;
  bottom: 5px;
  right: -2.5em;
}

.ol-layerswitcher .layerswitcher-opacity-label:after {
  content: "%";
}

.ol-layerswitcher .layerswitcher-progress {
  width: 120px;
  margin: -4px 1em 2px 7px;
  display: block;
}

.ol-layerswitcher .layerswitcher-progress div {
  background-color: #369;
  width: 0;
  height: 2px;
  display: block;
}

.ol-control.ol-layerswitcher-image {
  text-align: left;
  transition: all .2s;
  position: absolute;
  top: 1em;
  right: .5em;
}

.ol-control.ol-layerswitcher-image.ol-collapsed {
  transition: none;
  top: 3em;
}

.ol-layerswitcher-image .panel {
  margin: 0;
  padding: .25em;
  list-style: none;
  overflow: hidden;
}

.ol-layerswitcher-image .panel ul {
  padding: 0 0 0 20px;
  list-style: none;
  overflow: hidden;
}

.ol-layerswitcher-image.ol-collapsed .panel {
  display: none;
}

.ol-layerswitcher-image.ol-forceopen .panel {
  clear: both;
  display: block;
}

.ol-layerswitcher-image button {
  float: right;
  background-color: #fff;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: center;
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  display: none;
}

.ol-layerswitcher-image.ol-collapsed button {
  display: block;
  position: relative;
}

.ol-layerswitcher-image li {
  vertical-align: middle;
  cursor: pointer;
  background-color: #fff;
  border: 3px solid #0000;
  border-radius: 4px;
  width: 64px;
  height: 64px;
  margin: 2px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 4px #00000080;
}

.ol-layerswitcher-image li.ol-layer-hidden {
  opacity: .5;
  border-color: #555;
}

.ol-layerswitcher-image li.ol-header {
  display: none;
}

.ol-layerswitcher-image li img {
  max-width: 100%;
  position: absolute;
}

.ol-layerswitcher-image li.select, .ol-layerswitcher-image li.ol-visible {
  border: 3px solid red;
}

.ol-layerswitcher-image li p {
  display: none;
}

.ol-layerswitcher-image li:hover p {
  color: #fff;
  text-align: center;
  background-color: #00000080;
  height: 1.2em;
  margin: 0;
  font-family: Verdana, Geneva, sans-serif;
  font-size: .8em;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.ol-control.ol-legend {
  z-index: 1;
  max-width: 90%;
  max-height: 90%;
  bottom: .5em;
  left: .5em;
  overflow: hidden auto;
}

.ol-control.ol-legend.ol-collapsed {
  overflow: hidden;
}

.ol-control.ol-legend button {
  display: none;
  position: relative;
}

.ol-control.ol-legend.ol-collapsed button {
  display: block;
}

.ol-control.ol-legend.ol-uncollapsible button {
  display: none;
}

.ol-control.ol-legend button.ol-closebox {
  cursor: pointer;
  z-index: 1;
  background: none;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.ol-control.ol-legend.ol-uncollapsible button.ol-closebox, .ol-control.ol-legend.ol-collapsed button.ol-closebox {
  display: none;
}

.ol-control.ol-legend button.ol-closebox:before {
  content: "×";
  color: #003c8880;
  background: none;
  font-size: 1.3em;
}

.ol-control.ol-legend button.ol-closebox:hover:before {
  color: #003c88;
}

.ol-control.ol-legend .ol-legendImg {
  display: block;
}

.ol-control.ol-legend.ol-collapsed .ol-legendImg {
  display: none;
}

.ol-control.ol-legend.ol-uncollapsible .ol-legendImg {
  display: block;
}

.ol-control.ol-legend > button:first-child:before, .ol-control.ol-legend > button:first-child:after {
  content: "";
  background-color: currentColor;
  width: .2em;
  height: .2em;
  position: absolute;
  top: .25em;
  left: .2em;
  box-shadow: 0 .35em, 0 .7em;
}

.ol-control.ol-legend button:first-child:after {
  width: .6em;
  height: .15em;
  top: .27em;
  left: .55em;
}

ul.ol-legend {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
}

.ol-control.ol-legend.ol-collapsed ul {
  display: none;
}

.ol-control.ol-legend.ol-uncollapsible ul {
  display: block;
}

ul.ol-legend li.ol-title {
  text-align: center;
  font-weight: bold;
}

ul.ol-legend li.ol-title > div:first-child {
  width: 0 !important;
}

ul.ol-legend li {
  white-space: nowrap;
  padding: 0;
  overflow: hidden;
}

ul.ol-legend li div {
  vertical-align: middle;
  display: inline-block;
}

.ol-control.ol-legend .ol-legend {
  display: inline-block;
}

.ol-control.ol-legend.ol-collapsed .ol-legend {
  display: none;
}

.ol-control.ol-mapzone {
  text-align: left;
  box-sizing: border-box;
  max-height: calc(100% - 6em);
  position: absolute;
  top: .5em;
  right: .5em;
  overflow: hidden;
}

.ol-control.ol-mapzone.ol-collapsed {
  top: 3em;
}

.ol-control.ol-mapzone button {
  float: right;
  margin-top: 2.2em;
  position: relative;
}

.ol-touch .ol-control.ol-mapzone button {
  margin-top: 1.67em;
}

.ol-control.ol-mapzone.ol-collapsed button {
  margin-top: 0;
}

.ol-control.ol-mapzone button i {
  border: .1em solid;
  border-radius: 50%;
  width: .9em;
  height: .9em;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.ol-control.ol-mapzone button i:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: .4em;
  height: .4em;
  position: absolute;
  top: .3em;
  left: .5em;
  box-shadow: .05em .3em 0 -.051em, -.05em -.35em 0 -.1em, -.5em -.35em 0 0, -.65em .1em 0 -.03em, -.65em -.05em 0 -.05em;
}

.ol-mapzone > div {
  width: 5em;
  height: 5em;
  margin: 0 .2em 0 0;
  display: inline-block;
  position: relative;
}

.ol-control.ol-mapzone.ol-collapsed > div {
  display: none;
}

.ol-mapzone > div p {
  color: #fff;
  text-align: center;
  transform-origin: 0 0;
  cursor: default;
  width: 160%;
  margin: 0;
  font-family: Lucida Grande, Verdana, Geneva, Lucida, Arial, Helvetica, sans-serif;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  transform: scaleX(.625);
}

.ol-notification {
  background: none;
  border: 0;
  width: 150%;
  margin: 0;
  padding: 0;
  bottom: 0;
}

.ol-notification > div, .ol-notification > div:hover {
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  background-color: #000c;
  border-radius: 4px 4px 0 0;
  min-width: 5em;
  max-width: calc(66% - 4em);
  min-height: 1em;
  max-height: 5em;
  padding: .2em .5em;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 33.33%;
  transform: translateX(-50%);
}

.ol-notification.ol-collapsed > div {
  opacity: 0;
  bottom: -5em;
}

.ol-notification a {
  color: #9cf;
  cursor: pointer;
}

.ol-notification .ol-close, .ol-notification .ol-close:hover {
  padding-right: 1.5em;
}

.ol-notification .closeBox {
  position: absolute;
  top: 0;
  right: .3em;
}

.ol-notification .closeBox:before {
  content: "×";
}

.ol-overlay {
  color: #fff;
  box-sizing: border-box;
  z-index: 1;
  opacity: 0;
  cursor: default;
  pointer-events: none;
  background-color: #0006;
  width: 100%;
  height: 100%;
  padding: 1em;
  transition: all .5s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ol-overlay.slide-up {
  transform: translateY(100%);
}

.ol-overlay.slide-down {
  transform: translateY(-100%);
}

.ol-overlay.slide-left {
  transform: translateX(-100%);
}

.ol-overlay.slide-right {
  transform: translateX(100%);
}

.ol-overlay.zoom {
  opacity: .5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

.ol-overlay.zoomout {
  transform: scale(3);
}

.ol-overlay.zoomrotate {
  opacity: .5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0)rotate(360deg);
}

.ol-overlay.stretch {
  opacity: .5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scaleX(0);
}

.ol-overlay.stretchy {
  opacity: .5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scaleY(0);
}

.ol-overlay.wipe {
  opacity: 1;
}

.ol-overlay.flip {
  transform: perspective(600px)rotateY(180deg);
}

.ol-overlay.card {
  opacity: .5;
  transform: translate(-80%, 100%)rotate(-.5turn);
}

.ol-overlay.book {
  transform-origin: 10%;
  transform: perspective(600px)rotateY(-180deg)scaleX(.6);
}

.ol-overlay.book.visible {
  transform-origin: 10%;
}

.ol-overlay.ol-visible {
  opacity: 1;
  pointer-events: all;
  inset: 0;
  transform: none;
}

.ol-overlay .ol-closebox {
  cursor: pointer;
  z-index: 1;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
}

.ol-overlay .ol-closebox:before {
  content: "❌";
  text-align: center;
  vertical-align: middle;
  display: block;
}

.ol-overlay .ol-fullscreen-image {
  position: absolute;
  inset: 0;
}

.ol-overlay .ol-fullscreen-image img {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-overlay .ol-fullscreen-image.ol-has-title img {
  padding-bottom: 3em;
}

.ol-overlay .ol-fullscreen-image p {
  text-align: center;
  background-color: #00000080;
  margin: 0;
  padding: .5em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ol-control.ol-overview {
  text-align: left;
  position: absolute;
  bottom: .5em;
  left: .5em;
}

.ol-control.ol-overview .panel {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #369;
  width: 150px;
  height: 150px;
  margin: 2px;
  display: block;
}

.ol-overview:not(.ol-collapsed) button {
  z-index: 2;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.ol-control.ol-overview.ol-collapsed .panel {
  display: none;
}

.ol-overview.ol-collapsed button:before {
  content: "»";
}

.ol-overview button:before {
  content: "«";
}

.ol-control-right.ol-overview {
  left: auto;
  right: .5em;
}

.ol-control-right.ol-overview:not(.ol-collapsed) button {
  left: auto;
  right: 2px;
}

.ol-control-right.ol-overview.ol-collapsed button:before {
  content: "«";
}

.ol-control-right.ol-overview button:before {
  content: "»";
}

.ol-control-top.ol-overview {
  top: 5em;
  bottom: auto;
}

.ol-control-top.ol-overview:not(.ol-collapsed) button {
  top: 2px;
  bottom: auto;
}

.ol-permalink {
  position: absolute;
  top: .5em;
  right: 2.5em;
}

.ol-touch .ol-permalink {
  right: 3em;
}

.ol-permalink button {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AcFBjYE1ZK03gAAAUlJREFUOMuVk71KA1EQhc/NaiP+gCRpFHwGBSFlCrFVfAsbwSJCBMv06QIGJOBziI3EYAgkjU8gIloIAasIn4WzMqx34zrN7J6de+6ZmbNSgQDSfADcATPgHbgCyvonSYv8KEzWdofegH3gwmG9Ikq67sAESFzNueHThTyiEIKAmr2OJCUhhO30Aou+5aUQU2Ik65K2JC1KegohPGfUBkmvksqShnntHEcGOs60NXHfjmKz6czZTsNqbhzW+muwY2ATWAWawCOwBgxcTfvnvCPxKx4Cy5bPgBWgauRpdL2ImNlGhp3MabETm8mh94nDk4yCNE5/KTGg7xxbyhYAG0AN2AEqURIDZ0a0Fxn+LXAPXDpzRqMk6cOedz1ubdYl1b6NHgZRJe72nuu/CdSBl+yKi/zZlTnbaeXOJIesClwDU+ATeEhtX5TkCwAWUyAsHH1QAAAAAElFTkSuQmCC");
  background-position: center;
  background-repeat: no-repeat;
}

.ol-control.ol-print {
  top: .5em;
  left: 3em;
}

.ol-control.ol-print button:before {
  content: "";
  width: .9em;
  height: .35em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: inset 0 0 0 .1em, inset .55em 0, 0 .2em 0 -.1em;
}

.ol-control.ol-print button:after {
  content: "";
  width: .7em;
  height: .6em;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: inset 0 0 0 .15em;
}

.ol-ext-print-dialog {
  width: 100%;
  height: 100%;
}

.ol-ext-print-dialog > form .ol-closebox {
  z-index: 1;
  color: #999;
  left: 16.5em;
  right: auto;
}

.ol-ext-print-dialog .ol-content[data-status="printing"] {
  opacity: .5;
}

.ol-ext-print-dialog .ol-content .ol-error {
  color: #ff0;
  text-align: center;
  background: #b00;
  margin: 0 -1em;
  padding: 1em .5em;
  font-weight: bold;
  display: none;
}

.ol-ext-print-dialog .ol-content[data-status="error"] .ol-error {
  display: block;
}

.ol-ext-print-dialog > form, .ol-ext-print-dialog.ol-visible > form {
  background-color: #eee;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  transition: none;
  inset: 1em;
  transform: none;
}

.ol-ext-print-dialog .ol-print-map {
  width: calc(100% - 18em);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.ol-ext-print-dialog .ol-print-map .ol-page {
  box-sizing: border-box;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ol-ext-print-dialog .ol-print-map .ol-page.margin {
  box-sizing: content-box;
}

.ol-ext-print-dialog .ol-map {
  width: 100%;
  height: 100%;
}

.ol-ext-print-dialog .ol-print-map .ol-control {
  display: none !important;
}

.ol-ext-print-dialog .ol-print-param {
  box-sizing: border-box;
  background-color: #fff;
  width: 18em;
  padding: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.ol-ext-print-dialog .ol-print-param h2 {
  color: #003c88b3;
  font-size: 1.1em;
  display: block;
}

.ol-ext-print-dialog .ol-print-param ul {
  padding: 0;
  list-style: none;
}

.ol-ext-print-dialog .ol-print-param li {
  margin: .5em 0;
  font-size: .9em;
  position: relative;
}

.ol-ext-print-dialog .ol-print-param li.hidden {
  display: none;
}

.ol-ext-print-dialog .ol-print-param label {
  vertical-align: middle;
  width: 8em;
  display: inline-block;
}

.ol-ext-print-dialog select {
  vertical-align: middle;
  outline: none;
}

.ol-ext-print-dialog .ol-orientation {
  text-align: center;
}

.ol-ext-print-dialog .ol-orientation label {
  cursor: pointer;
  width: 7em;
  position: relative;
}

.ol-ext-print-dialog .ol-orientation input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.ol-ext-print-dialog .ol-orientation span {
  width: 80%;
  padding: 3.5em 0 .2em;
  display: block;
  position: relative;
}

.ol-ext-print-dialog .ol-orientation span:before {
  content: "";
  color: #333;
  opacity: .5;
  background-color: currentColor;
  border: 1px solid;
  border-radius: 0 1em 0 0;
  width: 2em;
  height: 2.6em;
  position: absolute;
  bottom: 1.5em;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  box-shadow: inset 1.3em -1.91em #ddd;
}

.ol-ext-print-dialog .ol-orientation .landscape span:before {
  width: 2.6em;
  height: 2em;
  margin: .2em 0;
  box-shadow: inset 1.91em -1.3em #ddd;
}

.ol-ext-print-dialog .ol-orientation input:checked + span {
  opacity: 1;
  box-shadow: 0 0 .2em #00000080;
}

.ol-ext-print-dialog .ol-ext-toggle-switch span {
  position: absolute;
  top: 50%;
  right: -2em;
  transform: translateY(-50%);
}

.ol-print-title input[type="text"] {
  width: calc(100% - 6em);
  margin-top: .5em;
  margin-left: 6em;
}

.ol-ext-print-dialog .ol-size option:first-child {
  font-style: italic;
}

.ol-ext-print-dialog .ol-saveas, .ol-ext-print-dialog .ol-savelegend {
  text-align: center;
}

.ol-ext-print-dialog .ol-saveas select, .ol-ext-print-dialog .ol-savelegend select {
  color: #fff;
  background-color: #003c88b3;
  border: 0;
  max-width: 12em;
  margin: 1em 0 0;
  padding: .5em;
  font-size: 1em;
  font-weight: bold;
}

.ol-ext-print-dialog .ol-saveas select option, .ol-ext-print-dialog .ol-savelegend select option {
  color: #666;
  background-color: #fff;
}

.ol-ext-print-dialog .ol-savelegend select {
  margin-top: 0;
}

.ol-ext-print-dialog .ol-ext-buttons {
  text-align: right;
  border-top: 1px solid #ccc;
  margin: 0 -1em;
  padding: .8em .5em;
}

.ol-ext-print-dialog button {
  color: #333;
  background: none;
  border: 1px solid #999;
  margin: 0 .2em;
  padding: .3em 1em;
  font-size: 1em;
}

.ol-ext-print-dialog button[type="submit"] {
  color: #fff;
  background-color: #003c88b3;
  font-weight: bold;
}

.ol-ext-print-dialog .ol-clipboard-copy {
  pointer-events: none;
  color: #fff;
  opacity: 0;
  background-color: #00000080;
  border-radius: 1em;
  width: fit-content;
  padding: .5em 1em;
  transition: all;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ol-ext-print-dialog .ol-clipboard-copy.visible {
  animation: 1s ol-clipboard-copy;
}

.ol-ext-print-dialog .ol-print-map .ol-control.ol-canvas-control, .ol-ext-print-dialog .ol-print-map .ol-control.ol-print-compass {
  display: block !important;
}

.ol-ext-print-dialog .ol-print-map .ol-control.olext-print-compass {
  width: 60px;
  height: 60px;
  margin: 20px;
  top: 0;
  right: 0;
}

@keyframes ol-clipboard-copy {
  0% {
    opacity: 0;
    top: 0;
  }

  80% {
    opacity: 1;
    top: -3em;
  }

  100% {
    opacity: 0;
    top: -3em;
  }
}

@media print {
  body.ol-print-document {
    margin: 0 !important;
    padding: 0 !important;
  }

  body.ol-print-document > * {
    display: none !important;
  }

  body.ol-print-document > .ol-ext-print-dialog {
    display: block !important;
  }

  body.ol-print-document > .ol-ext-print-dialog .ol-content {
    max-height: unset !important;
  }

  .ol-ext-print-dialog > form, .ol-ext-print-dialog {
    position: unset;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    background: none !important;
  }

  .ol-ext-print-dialog > form > *, .ol-ext-print-dialog .ol-print-param {
    background: none;
    display: none !important;
  }

  .ol-ext-print-dialog .ol-content {
    background: none;
    border: 0;
    display: block !important;
  }

  .ol-ext-print-dialog .ol-print-map {
    position: unset;
    background: none;
    width: auto;
    overflow: visible;
  }

  .ol-ext-print-dialog .ol-print-map .ol-page {
    position: unset;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    transform: none !important;
  }
}

@media (width <= 25em) {
  .ol-ext-print-dialog .ol-print-param {
    width: 13em;
  }

  .ol-ext-print-dialog .ol-print-map {
    width: calc(100% - 13em);
  }

  .ol-ext-print-dialog .ol-print-param .ol-print-title input[type="text"] {
    width: 100%;
    margin: 0;
  }
}

.ol-profil {
  user-select: none;
  position: relative;
}

.ol-control.ol-profil {
  text-align: right;
  position: absolute;
  top: .5em;
  right: 3em;
  overflow: hidden;
}

.ol-profil .ol-zoom-out {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 2px;
  width: 1em;
  height: 1em;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
}

.ol-profil .ol-zoom-out:before {
  content: "";
  background: currentColor;
  width: 60%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-profil .ol-inner {
  padding: .5em;
  font-size: .8em;
  position: relative;
}

.ol-control.ol-profil .ol-inner {
  background-color: #ffffffb3;
  margin: 2.3em 2px 2px;
  display: block;
}

.ol-control.ol-profil.ol-collapsed .ol-inner {
  display: none;
}

.ol-profil canvas {
  display: block;
}

.ol-profil button {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAYAAAALWoRrAAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AgXCR4dn7j9TAAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAz0lEQVQ4y7WTMU4CURRFz0xIpLUBEhdAY2tJYW1jaWlsXYVxDWyBhWCFCYugYgnDFPMOhTMJGf3AwHiqn/uTk5v/3gfAH6b0RH7sMiIe1Ts162z+q2lVVbd1XqijLuJk0zzP1/VxCGyApLgsy+HJphGx8DeFOm6L1bn6eVQaEW+m2amTRqx+1fkqKY2Ie0+zUx/U7WGYfNMsy57PmMMN8A1MWsWeUoPyivV8PWtPOzL7D+lYHfUtBXgHGLTCJfBxodD6k9Dsm8BLE17LobQ39nJC61aLVoVsAAAAAElFTkSuQmCC");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 2px;
}

.ol-profil.ol-collapsed button {
  position: static;
}

.ol-profil .ol-profilbar, .ol-profil .ol-profilcursor {
  pointer-events: none;
  width: 1px;
  display: none;
  position: absolute;
}

.ol-profil .ol-profilcursor {
  width: 0;
  height: 0;
}

.ol-profil .ol-profilcursor:before {
  content: "";
  pointer-events: none;
  width: 5px;
  height: 5px;
  margin: -2px;
  display: block;
}

.ol-profil .ol-profilbar, .ol-profil .ol-profilcursor:before {
  background: red;
}

.ol-profil table {
  text-align: center;
  width: 100%;
}

.ol-profil table span {
  display: block;
}

.ol-profilpopup {
  white-space: nowrap;
  background-color: #ffffff80;
  margin: .5em;
  padding: 0 .5em;
  position: absolute;
  top: -1em;
}

.ol-profilpopup.ol-left {
  right: 0;
}

.ol-profil table td {
  padding: 0 2px;
}

.ol-profil table .track-info {
  display: table-row;
}

.ol-profil table .point-info, .ol-profil .over table .track-info {
  display: none;
}

.ol-profil .over table .point-info {
  display: table-row;
}

.ol-profil p {
  text-align: center;
  margin: 0;
}

.ol-control.ol-progress-bar {
  background-color: #0000;
  padding: 0;
  position: absolute;
  inset: 0;
  pointer-events: none !important;
}

.ol-control.ol-progress-bar > .ol-bar {
  box-sizing: border-box;
  background-color: #003c8880;
  width: 0;
  height: .5em;
  transition: width .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ol-progress-bar > .ol-waiting {
  display: none;
}

.ol-viewport .ol-control.ol-progress-bar > .ol-waiting {
  color: #fff;
  font-size: 2em;
  animation: 1s linear infinite ol-progress-bar-blink;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes ol-progress-bar-blink {
  0%, 30% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.ol-control.ol-routing {
  max-height: 90%;
  top: .5em;
  left: 3em;
  overflow-y: auto;
}

.ol-touch .ol-control.ol-routing {
  left: 3.5em;
}

.ol-control.ol-routing.ol-searching {
  opacity: .5;
}

.ol-control.ol-routing .ol-car, .ol-control.ol-routing > button {
  position: relative;
}

.ol-control.ol-routing .ol-car:after, .ol-control.ol-routing > button:after {
  content: "";
  clip: rect(0 1em .5em -1em);
  border-radius: 40% 50% 0 0 / 50% 70% 0 0;
  width: .78em;
  height: .6em;
  position: absolute;
  top: .35em;
  left: .4em;
  box-shadow: inset 0 0 0 .065em, -.35em .14em 0 -.09em, inset 0 -.37em, inset -.14em .005em;
}

.ol-control.ol-routing .ol-car:before, .ol-control.ol-routing > button:before {
  content: "";
  border-radius: 50%;
  width: .28em;
  height: .28em;
  position: absolute;
  top: .73em;
  left: .2em;
  box-shadow: inset 0 0 0 1em, .65em 0;
}

.ol-control.ol-routing .ol-pedestrian:after {
  content: "";
  border-top: .2em solid #0000;
  width: .3em;
  height: .4em;
  position: absolute;
  top: .25em;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: inset .3em 0, .1em .5em 0 -.1em, -.1em .5em 0 -.1em, .25em .1em 0 -.1em, -.25em .1em 0 -.1em;
}

.ol-control.ol-routing .ol-pedestrian:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: .3em;
  height: .3em;
  position: absolute;
  top: .1em;
  left: 50%;
  transform: translateX(-50%);
}

.ol-control.ol-routing.ol-collapsed .content, .ol-routing .ol-search.ol-collapsed ul, .ol-routing .ol-search ul .copy {
  display: none;
}

.ol-routing .content .search-input > div > * {
  vertical-align: top;
  display: inline-block;
}

.ol-routing .ol-result ul {
  list-style: none;
  display: block;
}

.ol-routing .ol-result li {
  min-height: 1.65em;
  position: relative;
}

.ol-routing .ol-result li i {
  font-size: .8em;
  font-weight: bold;
  display: block;
}

.ol-routing .ol-result li:before {
  content: "";
  transform-origin: 50% 125%;
  border: .4em solid #0000;
  border-top-width: .6em;
  border-bottom: .6em solid #369;
  position: absolute;
  top: -.8em;
  left: -1.75em;
  box-shadow: 0 .65em 0 -.25em #369;
}

.ol-routing .ol-result li:after {
  content: "";
  background: #369;
  width: .3em;
  height: .6em;
  position: absolute;
  top: .6em;
  left: -1.5em;
}

.ol-routing .ol-result li.R:before {
  transform: rotate(90deg);
}

.ol-routing .ol-result li.FR:before {
  transform: rotate(45deg);
}

.ol-routing .ol-result li.L:before {
  transform: rotate(-90deg);
}

.ol-routing .ol-result li.FL:before {
  transform: rotate(-45deg);
}

.ol-routing .content > i {
  vertical-align: middle;
  margin: 0 .3em 0 .1em;
  font-style: normal;
}

.ol-routing .ol-button, .ol-routing .ol-button:focus, .ol-routing .ol-pedestrian, .ol-routing .ol-car {
  color: #003c88;
  opacity: .5;
  vertical-align: middle;
  cursor: pointer;
  background-color: #0000;
  outline: none;
  width: 1.4em;
  height: 1.4em;
  margin: 0 .1em;
  font-size: 1.1em;
  display: inline-block;
  position: relative;
}

.ol-routing .ol-button:hover, .ol-routing .ol-button.selected, .ol-routing i.selected {
  opacity: 1;
  background: none;
}

.ol-control.ol-routing:hover {
  background-color: #ffffffd9;
}

.search-input > div > button:before {
  content: "±";
}

.ol-viewport .ol-scale {
  text-align: center;
  transform-origin: 0 0;
  bottom: 2.5em;
  left: .5em;
  transform: scaleX(.8);
}

.ol-viewport .ol-scale input {
  text-align: center;
  background: none;
  border: 0;
  width: 8em;
}

.ol-search {
  top: .5em;
  left: 3em;
}

.ol-touch .ol-search {
  left: 3.5em;
}

.ol-search button {
  float: left;
  top: 2px;
  left: 2px;
}

.ol-control.ol-search > button:before {
  content: "";
  box-sizing: border-box;
  background-color: #0000;
  border: .12em solid;
  border-radius: 100%;
  width: .7em;
  height: .7em;
  position: absolute;
  top: .35em;
  left: .35em;
}

.ol-control.ol-search > button:after {
  content: "";
  background-color: currentColor;
  border-radius: .05em;
  width: .45em;
  height: .15em;
  position: absolute;
  top: 1.1em;
  left: .95em;
  transform: rotate(45deg);
  box-shadow: -.18em 0 0 -.03em;
}

.ol-search button.ol-revers {
  float: none;
  vertical-align: bottom;
  background-image: none;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}

.ol-search.ol-revers button.ol-revers {
  background-color: #00883c80;
}

.ol-control.ol-search.ol-collapsed button.ol-revers {
  display: none;
}

.ol-search button.ol-revers:before {
  content: "";
  box-sizing: border-box;
  border: .1em solid;
  border-radius: 50%;
  width: .55em;
  height: .55em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-search button.ol-revers:after {
  content: "";
  background-color: #0000;
  width: .2em;
  height: .2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: .35em 0, 0 .35em, -.35em 0, 0 -.35em;
}

.ol-search input {
  box-sizing: border-box;
  border: 0;
  height: 1.375em;
  margin: 1px 1px 1px 2px;
  padding-left: .3em;
  font-size: 1.14em;
  transition: all .1s;
  display: inline-block;
}

.ol-touch .ol-search input, .ol-touch .ol-search ul {
  font-size: 1.5em;
}

.ol-search.ol-revers > ul, .ol-control.ol-search.ol-collapsed > * {
  display: none;
}

.ol-control.ol-search.ol-collapsed > button {
  display: block;
}

.ol-search ul {
  clear: both;
  cursor: pointer;
  z-index: 1;
  background: #fff;
  max-width: 17em;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  overflow-x: hidden;
}

.ol-search ul li {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: .1em .5em;
  overflow: hidden;
}

.ol-search ul li.select, .ol-search ul li:hover {
  color: #fff;
  background-color: #003c8880;
}

.ol-search ul li img {
  float: left;
  max-height: 2em;
}

.ol-search li.copy {
  color: #fff;
  background: #00000080;
}

.ol-search li.copy a {
  color: #fff;
  text-decoration: none;
}

.ol-search.searching:before {
  content: "";
  z-index: 2;
  background: red;
  height: 3px;
  animation: .5s linear infinite alternate pulse;
  position: absolute;
  top: 1.6em;
  left: 0;
}

@keyframes pulse {
  0% {
    left: 0;
    right: 95%;
  }

  50% {
    left: 30%;
    right: 30%;
  }

  100% {
    left: 95%;
    right: 0;
  }
}

.ol-search.IGNF-parcelle input {
  width: 13.5em;
}

.ol-search.IGNF-parcelle input:read-only {
  opacity: .8;
  background: #ccc;
}

.ol-search.IGNF-parcelle.ol-collapsed-list > ul.autocomplete {
  display: none;
}

.ol-search.IGNF-parcelle label {
  clear: both;
  display: block;
}

.ol-search.IGNF-parcelle > div input, .ol-search.IGNF-parcelle > div label {
  box-sizing: border-box;
  width: 5em;
  margin: .1em;
  font-size: 1em;
  display: inline-block;
}

.ol-search.IGNF-parcelle ul.autocomplete-page {
  text-align: center;
  width: 100%;
  margin-top: .5em;
  display: none;
}

.ol-search.IGNF-parcelle.ol-collapsed-list ul.autocomplete-parcelle, .ol-search.IGNF-parcelle.ol-collapsed-list ul.autocomplete-page {
  display: block;
}

.ol-search.IGNF-parcelle.ol-collapsed ul.autocomplete-page, .ol-search.IGNF-parcelle.ol-collapsed ul.autocomplete-parcelle, .ol-search.IGNF-parcelle ul.autocomplete-parcelle {
  display: none;
}

.ol-search.IGNF-parcelle ul.autocomplete-page li {
  color: #fff;
  background: #003c8880;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  margin: 0 .1em;
  padding: .1em;
  display: inline-block;
}

.ol-search.IGNF-parcelle ul.autocomplete-page li.selected {
  background: #003c88;
}

.ol-searchgps input.search {
  display: none;
}

.ol-control.ol-searchgps > button:first-child {
  background-image: none;
}

.ol-control.ol-searchgps > button:first-child:before {
  content: "x/y";
  position: unset;
  border: unset;
  border-radius: 0;
  width: auto;
  height: auto;
  display: block;
  transform: scaleX(.8);
}

.ol-control.ol-searchgps > button:first-child:after {
  content: unset;
}

.ol-control.ol-searchgps .ol-latitude, .ol-control.ol-searchgps .ol-longitude {
  clear: both;
}

.ol-control.ol-searchgps .ol-latitude label, .ol-control.ol-searchgps .ol-longitude label {
  text-align: right;
  transform-origin: 0 0;
  width: 5.5em;
  margin: 0 -.8em 0 0;
  display: inline-block;
  transform: scaleX(.8);
}

.ol-control.ol-searchgps .ol-latitude input, .ol-control.ol-searchgps .ol-longitude input {
  max-width: 10em;
}

.ol-control.ol-searchgps .ol-ext-toggle-switch {
  cursor: pointer;
  float: right;
  margin: .5em;
  font-size: .9em;
}

.ol-searchgps .ol-decimal {
  margin-right: .7em;
  display: inline-block;
}

.ol-searchgps .ol-dms, .ol-searchgps.ol-dms .ol-decimal {
  text-align: right;
  width: 3em;
  display: none;
}

.ol-searchgps.ol-dms .ol-dms {
  display: inline-block;
}

.ol-searchgps span.ol-dms {
  text-align: left;
  width: .5em;
}

.ol-searchgps.ol-control.ol-collapsed button.ol-geoloc {
  display: none;
}

.ol-searchgps button.ol-geoloc {
  float: right;
  background-image: none;
  margin-right: 3px;
  position: relative;
  top: 0;
}

.ol-searchgps button.ol-geoloc:before {
  content: "";
  border: .1em solid;
  border-radius: 50%;
  width: .6em;
  height: .6em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-searchgps button.ol-geoloc:after {
  content: "";
  background-color: #0000;
  width: .2em;
  height: .2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: .45em 0, -.45em 0, 0 -.45em, 0 .45em, .25em 0, -.25em 0, 0 -.25em, 0 .25em;
}

.ol-control.ol-select {
  top: .5em;
  left: 3em;
}

.ol-touch .ol-control.ol-select {
  left: 3.5em;
}

.ol-control.ol-select > button:before {
  content: "A";
  box-sizing: content-box;
  text-align: center;
  background-color: #0000;
  border: .2em solid;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  font-size: .6em;
  font-weight: normal;
  line-height: 1em;
  position: absolute;
  top: .5em;
  left: .5em;
}

.ol-control.ol-select > button:after {
  content: "";
  background-color: currentColor;
  border-radius: .05em;
  width: .45em;
  height: .15em;
  position: absolute;
  top: 1.15em;
  left: 1em;
  transform: rotate(45deg);
  box-shadow: -.18em 0 0 -.03em;
}

.ol-select > div button {
  float: right;
  width: auto;
  padding: 0 .5em;
  font-weight: normal;
}

.ol-select .ol-delete {
  vertical-align: middle;
  cursor: pointer;
  width: 1.5em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.ol-select .ol-delete:before {
  content: "×";
  text-align: center;
  width: 100%;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-select input {
  font-size: 1em;
}

.ol-control.ol-select select {
  max-width: 10em;
  font-size: 1em;
}

.ol-control.ol-select select option.ol-default {
  color: #999;
  font-style: italic;
}

.ol-control.ol-select > div {
  display: block;
}

.ol-control.ol-select.ol-collapsed > div {
  display: none;
}

.ol-control.ol-select.ol-select-check {
  max-width: 20em;
}

.ol-control.ol-select label.ol-ext-check {
  margin-right: 1em;
}

.ol-control.ol-select label.ol-ext-toggle-switch span {
  font-size: 1.1em;
}

.ol-select ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ol-control.ol-select input[type="search"], .ol-control.ol-select input[type="text"] {
  width: 8em;
}

.ol-select .ol-autocomplete {
  display: inline;
}

.ol-select .ol-autocomplete ul {
  background: #fff;
  border: 1px solid #999;
  min-width: 10em;
  font-size: .85em;
  display: block;
  position: absolute;
}

.ol-select .ol-autocomplete ul li {
  padding: 0 .5em;
}

.ol-select .ol-autocomplete ul li:hover {
  color: #fff;
  background: #003c8880;
}

.ol-select ul.ol-hidden {
  display: none;
}

.ol-select-multi li > div:hover, .ol-select-multi li > div.ol-control.ol-select {
  top: unset;
  left: unset;
  background: none;
  position: relative;
}

.ol-select-multi li > div > button, .ol-select-multi li > div .ol-ok {
  display: none;
}

.ol-select-multi li .ol-control.ol-select.ol-collapsed > div, .ol-select-multi li > div > div {
  display: block;
}

.ol-control.ol-status {
  color: #fff;
  box-sizing: border-box;
  background: #0003;
  border-radius: 0;
  width: 100%;
  padding: .3em 3em;
  font-size: .9em;
  display: none;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.ol-control.ol-status.ol-visible {
  display: initial;
}

.ol-control.ol-status.ol-bottom {
  top: auto;
  bottom: 0;
}

.ol-control.ol-status.ol-left {
  width: auto;
  padding: .3em .5em .3em 3em;
  top: 0;
  bottom: 0;
}

.ol-control.ol-status.ol-right {
  width: auto;
  padding: .3em 3em .3em .5em;
  inset: 0 0 0 auto;
}

.ol-control.ol-status.ol-center {
  top: 50%;
  transform: translateY(-50%);
}

.ol-control.ol-storymap {
  border-radius: .5em;
  max-width: 35%;
  height: auto;
  position: absolute;
  top: .5em;
  bottom: .5em;
  left: .5em;
}

.ol-storymap {
  height: 100%;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.ol-storymap > div {
  scroll-behavior: smooth;
  user-select: none;
  height: 100%;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.ol-storymap > div.ol-move {
  scroll-behavior: unset;
}

.ol-control.ol-storymap .chapter {
  padding: .5em;
}

.ol-storymap .chapter {
  cursor: pointer;
  opacity: .4;
}

.ol-storymap .chapter.ol-select {
  cursor: default;
  opacity: 1;
}

.ol-storymap .ol-scroll-top, .ol-storymap .ol-scroll-next {
  color: #003c8880;
  text-align: center;
  cursor: pointer;
  min-height: 1.7em;
  position: relative;
}

.ol-storymap .ol-scroll-next span {
  padding-bottom: 1.4em;
  display: block;
}

.ol-storymap .ol-scroll-top span {
  padding-top: 1.4em;
  display: block;
}

.ol-storymap .ol-scroll-top:before, .ol-storymap .ol-scroll-next:before {
  content: "";
  pointer-events: none;
  border: .3em solid;
  border-color: #0000 currentColor currentColor #0000;
  border-radius: .3em;
  width: .8em;
  height: .8em;
  animation: .35s linear infinite alternate ol-bounce-bottom;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
}

.ol-storymap .ol-scroll-top:before {
  border-color: currentColor #0000 #0000 currentColor;
  animation: .35s linear infinite alternate ol-bounce-top;
}

@keyframes ol-bounce-top {
  from {
    top: -.2em;
  }

  to {
    top: .5em;
  }
}

@keyframes ol-bounce-bottom {
  from {
    bottom: -.2em;
  }

  to {
    bottom: .5em;
  }
}

.ol-storymap img[data-title] {
  cursor: pointer;
}

.ol-swipe {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-swipe:before {
  content: "";
  z-index: -1;
  background: #fff;
  width: 4px;
  position: absolute;
  top: -5000px;
  bottom: -5000px;
  left: 50%;
  transform: translate(-2px);
}

.ol-swipe.horizontal:before {
  width: auto;
  height: 4px;
  inset: 50% -5000px auto;
}

.ol-swipe, .ol-swipe button {
  cursor: ew-resize;
}

.ol-swipe.horizontal, .ol-swipe.horizontal button {
  cursor: ns-resize;
}

.ol-swipe:after, .ol-swipe button:before, .ol-swipe button:after {
  content: "";
  background: #fffc;
  width: 2px;
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 50%;
  transform: translate(-1px);
}

.ol-swipe button:after {
  transform: translateX(5px);
}

.ol-swipe button:before {
  transform: translateX(-7px);
}

.ol-control.ol-timeline {
  transition: all .3s;
  bottom: 0;
  left: 0;
  right: 0;
}

.ol-control.ol-timeline.ol-collapsed {
  transform: translateY(100%);
}

.ol-timeline {
  padding: 2px 0 0;
  overflow: hidden;
}

.ol-timeline .ol-scroll {
  scroll-behavior: smooth;
  height: 6em;
  padding: 0 50%;
  line-height: 1em;
  overflow: hidden;
}

.ol-timeline .ol-scroll.ol-move {
  scroll-behavior: unset;
}

.ol-timeline.ol-hasbutton .ol-scroll {
  margin-left: 1.5em;
  padding: 0 calc(50% - .75em);
}

.ol-timeline .ol-buttons {
  z-index: 10;
  background: #ffffff80;
  width: 1.5em;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.ol-timeline.ol-hasbutton .ol-buttons {
  display: block;
}

.ol-timeline .ol-buttons button {
  margin: 1px;
  font-size: 1em;
  position: relative;
}

.ol-timeline .ol-buttons .ol-zoom-in:before, .ol-timeline .ol-buttons .ol-zoom-out:before {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-timeline .ol-buttons .ol-zoom-out:before {
  content: "−";
}

.ol-timeline .ol-scroll > div {
  height: 100%;
  position: relative;
}

.ol-timeline .ol-scroll .ol-times {
  background: #ffffff80;
  height: 1em;
  position: absolute;
  bottom: 0;
  left: -1000px;
  right: -1000px;
}

.ol-timeline .ol-scroll .ol-time {
  color: #999;
  font-size: .7em;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}

.ol-timeline .ol-scroll .ol-time.ol-year {
  color: #666;
  z-index: 1;
}

.ol-timeline .ol-scroll .ol-time:before {
  content: "";
  border-left: 1px solid;
  height: 500px;
  position: absolute;
  bottom: 1.2em;
  left: 50%;
}

.ol-timeline .ol-scroll .ol-features {
  margin: 0 0 0 200px;
  position: absolute;
  inset: 0 -1000px 1em -200px;
  overflow: hidden;
}

.ol-timeline .ol-scroll .ol-feature {
  color: #999;
  cursor: pointer;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ccc;
  max-width: 3em;
  min-height: 1em;
  max-height: 2.4em;
  padding: 0 .5em 0 0;
  font-size: .7em;
  line-height: 1.2em;
  transition: all .3s;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.ol-timeline.ol-zoomhover .ol-scroll .ol-feature:hover, .ol-timeline.ol-zoomhover .ol-scroll .ol-feature.ol-select {
  z-index: 1;
  background: #eee;
  transform: scale(1.2);
}

.ol-timeline .ol-center-date {
  z-index: 2;
  pointer-events: none;
  opacity: .4;
  background-color: red;
  width: 2px;
  height: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ol-timeline.ol-hasbutton .ol-center-date {
  left: calc(50% + .75em);
}

.ol-timeline.ol-pointer .ol-center-date {
  display: block;
}

.ol-timeline.ol-pointer .ol-center-date:before, .ol-timeline.ol-pointer .ol-center-date:after {
  content: "";
  border: .3em solid #0000;
  border-width: .3em .25em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ol-timeline.ol-pointer .ol-center-date:before {
  border-top-color: red;
  top: 0;
}

.ol-timeline.ol-pointer .ol-center-date:after {
  border-bottom-color: red;
  bottom: 0;
}

.ol-timeline.ol-interval .ol-center-date {
  box-sizing: content-box;
  opacity: .2;
  background-color: #0000;
  border: 0 solid #000;
  border-width: 0 10000px;
  display: block;
}

.ol-control.ol-videorec {
  white-space: nowrap;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ol-control.ol-videorec button {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.ol-control.ol-videorec button:before {
  content: "";
  background-color: currentColor;
  width: .8em;
  height: .8em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-videorec button.ol-start:before {
  background-color: #c00;
  border-radius: 50%;
  width: .9em;
  height: .9em;
}

.ol-control.ol-videorec button.ol-pause:before {
  background-color: #0000;
  width: .2em;
  box-shadow: -.2em 0, .2em 0;
}

.ol-control.ol-videorec button.ol-resume:before {
  box-sizing: border-box;
  background: none;
  border: .4em solid #0000;
  border-left: .6em solid;
  border-right-width: 0;
  width: auto;
}

.ol-control.ol-videorec button.ol-stop, .ol-control.ol-videorec button.ol-pause, .ol-control.ol-videorec button.ol-resume, .ol-control.ol-videorec[data-state="rec"] .ol-start, .ol-control.ol-videorec[data-state="pause"] .ol-start {
  display: none;
}

.ol-control.ol-videorec[data-state="rec"] .ol-stop, .ol-control.ol-videorec[data-state="pause"] .ol-stop, .ol-control.ol-videorec[data-state="rec"] .ol-pause, .ol-control.ol-videorec[data-state="pause"] .ol-resume {
  display: inline-block;
}

.ol-control.ol-wmscapabilities {
  top: .5em;
  right: 2.5em;
}

.ol-touch .ol-control.ol-wmscapabilities {
  right: 3em;
}

.ol-control.ol-wmscapabilities.ol-hidden {
  display: none;
}

.ol-control.ol-wmscapabilities button:before {
  content: "+";
  position: absolute;
  top: calc(50% - .35em);
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-control.ol-wmscapabilities button:after {
  content: "";
  background: none;
  border: .15em solid #0000;
  border-width: 0 .15em .15em 0;
  border-radius: .1em 0;
  width: .75em;
  height: .75em;
  position: absolute;
  top: calc(50% - .05em);
  left: 50%;
  transform: scaleY(.6)translate(-50%, -50%)rotate(45deg);
  box-shadow: inset -.18em -.18em, -.4em .1em 0 -.25em, .1em -.35em 0 -.25em, .15em .15em;
}

.ol-wmscapabilities .ol-searching {
  opacity: .5;
}

.ol-wmscapabilities .ol-searching .ol-url:after {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: .7em;
  height: .7em;
  animation: 2s linear infinite ol-wmscapabilities-rotate;
  display: block;
  position: absolute;
  top: 6em;
  left: calc(50% - .35em);
  box-shadow: 0 1em, 0 -1em, 1em 0, -1em 0;
}

@keyframes ol-wmscapabilities-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.ol-wmscapabilities .ol-url input {
  box-sizing: border-box;
  width: calc(100% - 10em);
  min-width: min(100%, 20em);
}

.ol-wmscapabilities .ol-url select {
  width: 2em;
  height: 100%;
  padding: 1px;
}

.ol-wmscapabilities .ol-url button {
  width: 7.5em;
  margin-left: .5em;
}

.ol-wmscapabilities .ol-result {
  margin-top: .5em;
  display: none;
}

.ol-wmscapabilities .ol-result.ol-visible {
  display: block;
}

.ol-wmscapabilities .ol-select-list {
  box-sizing: border-box;
  border: 1px solid #369;
  width: calc(100% - 120px);
  max-height: 14.5em;
  position: relative;
  overflow-x: hidden;
}

.ol-wmscapabilities .ol-select-list div {
  cursor: pointer;
  box-sizing: border-box;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0 .5em;
  overflow: hidden;
}

.ol-wmscapabilities .ol-select-list .level-1 {
  padding-left: 1em;
}

.ol-wmscapabilities .ol-select-list .level-2 {
  padding-left: 1.5em;
}

.ol-wmscapabilities .ol-select-list .level-3 {
  padding-left: 2em;
}

.ol-wmscapabilities .ol-select-list .level-4 {
  padding-left: 2.5em;
}

.ol-wmscapabilities .ol-select-list .level-5 {
  padding-left: 3em;
}

.ol-wmscapabilities .ol-select-list .ol-info {
  font-style: italic;
}

.ol-wmscapabilities .ol-select-list .ol-title {
  background-color: #003c881a;
}

.ol-wmscapabilities .ol-select-list div:hover {
  color: #fff;
  background-color: #003c8880;
}

.ol-wmscapabilities .ol-select-list div.selected {
  color: #fff;
  background-color: #003c88b3;
}

.ol-wmscapabilities .ol-preview {
  float: right;
  color: #666;
  text-align: center;
  background: #003c881a;
  width: 100px;
  margin-left: 10px;
  padding: 0 5px 5px;
}

.ol-wmscapabilities .ol-preview.tainted {
  float: right;
  color: #666;
  text-align: center;
  background: #88003c1a;
  width: 100px;
  margin-left: 10px;
  padding: 0 5px 5px;
}

.ol-wmscapabilities .ol-preview img {
  background: #fff;
  width: 100%;
  display: block;
}

.ol-wmscapabilities .ol-legend {
  max-width: 100%;
  display: none;
}

.ol-wmscapabilities .ol-legend.visible {
  display: block;
}

.ol-wmscapabilities .ol-buttons {
  clear: both;
  text-align: right;
}

.ol-wmscapabilities .ol-data p {
  margin: 0;
}

.ol-wmscapabilities .ol-data p.ol-title {
  margin: 1em 0 .5em;
  font-weight: bold;
}

.ol-wmscapabilities .ol-error {
  color: #800;
}

.ol-wmscapabilities ul.ol-wmsform {
  padding: 0;
  list-style: none;
  display: none;
}

.ol-wmscapabilities ul.ol-wmsform.visible {
  display: block;
}

.ol-wmscapabilities .ol-wmsform label {
  text-align: right;
  width: calc(40% - .5em);
  margin-right: .5em;
  display: inline-block;
}

.ol-wmscapabilities .ol-wmsform input {
  box-sizing: border-box;
  width: 60%;
  display: inline-block;
}

.ol-wmscapabilities .ol-wmsform input[type="checkbox"] {
  width: auto;
}

.ol-wmscapabilities .ol-wmsform button {
  float: right;
  margin: 1em 0;
}

.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] input {
  width: calc(60% - 2em);
}

.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button {
  vertical-align: middle;
  color: #444;
  width: 2em;
  height: 1.6em;
  margin: 0;
  position: relative;
}

.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button:before, .ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button:after {
  content: "";
  border: .1em solid;
  width: .25em;
  height: .9em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)skewY(-15deg);
}

.ol-wmscapabilities ul.ol-wmsform li[data-param="extent"] button:after {
  transform: translateX(.4em)translate(-50%, -50%)skewY(15deg);
  box-shadow: -.8em .25em;
}

.ol-ext-dialog.ol-wmscapabilities form {
  width: 600px;
  min-height: 15em;
  top: 15%;
  transform: translate(-50%, -15%);
}

.ol-ext-dialog.ol-wmscapabilities .ol-content {
  max-height: calc(100vh - 6em);
}

.ol-ext-dialog.ol-wmtscapabilities [data-param="map"], .ol-ext-dialog [data-param="style"] {
  display: none;
}

.ol-ext-dialog.ol-wmtscapabilities [data-param="style"] {
  display: list-item;
}

.ol-ext-dialog.ol-wmtscapabilities [data-param="proj"], .ol-ext-dialog.ol-wmtscapabilities [data-param="version"] {
  opacity: .6;
  pointer-events: none;
}

.ol-ext-dialog.ol-wmscapabilities button.ol-wmsform {
  text-align: center;
  width: 1.8em;
}

.ol-ext-dialog.ol-wmscapabilities button.ol-wmsform:before {
  content: "+";
}

.ol-ext-dialog.ol-wmscapabilities .ol-form button.ol-wmsform:before {
  content: "-";
}

.ol-ext-dialog.ol-wmscapabilities .ol-form button.ol-load, .ol-ext-dialog.ol-wmscapabilities .ol-form .ol-legend {
  display: none;
}

.ol-ext-dialog.ol-wmscapabilities .ol-form ul.ol-wmsform {
  clear: both;
  display: block;
}

.ol-target-overlay .ol-target {
  border: 1px solid #0000;
  width: 0;
  height: 20px;
  display: block;
  box-shadow: 0 0 1px 1px #fff;
}

.ol-target-overlay .ol-target:after, .ol-target-overlay .ol-target:before {
  content: "";
  border: 1px solid #369;
  width: 20px;
  height: 0;
  display: block;
  position: absolute;
  top: 10px;
  left: -10px;
  box-shadow: 0 0 1px 1px #fff;
}

.ol-target-overlay .ol-target:after {
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 0;
  height: 20px;
  top: 0;
  left: 0;
}

.ol-overlaycontainer .ol-touch-cursor {
  cursor: pointer;
  background: #fff6;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: 6px;
  box-shadow: inset 0 0 0 5px #369;
}

.ol-overlaycontainer .ol-touch-cursor:after {
  content: "";
  background: radial-gradient(circle at 100% 100%, #0000, #0000 70%, #369 70%, #369);
  width: 50%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button {
  color: #369;
  cursor: pointer;
  background: #fff6;
  border-radius: 50%;
  width: 55%;
  height: 55%;
  transition: all .5s, opacity, background;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(0);
  box-shadow: inset 0 0 0 3px;
}

.ol-overlaycontainer .ol-touch-cursor.active.disable .ol-button {
  opacity: .8;
  background: #3693;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button {
  transform: translate(-50%, -50%)scale(1);
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-0 {
  top: -18%;
  left: 118%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-1 {
  top: 50%;
  left: 140%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-2 {
  top: 120%;
  left: 120%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-3 {
  top: 140%;
  left: 50%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-4 {
  top: 118%;
  left: -18%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-5 {
  top: 50%;
  left: -40%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-6 {
  top: -18%;
  left: -18%;
}

.ol-overlaycontainer .ol-touch-cursor.active .ol-button-7 {
  top: -40%;
  left: 50%;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button:before {
  content: "";
  text-align: center;
  width: 1.5em;
  height: 1em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-add:before, .ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-remove:before {
  content: "−";
  font-size: 1.375em;
  font-weight: bold;
  line-height: .95em;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-add:before {
  content: "+";
}

.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-x:before {
  content: "×";
  font-size: 1.2em;
  font-weight: bold;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-move:before {
  content: "✥";
  font-size: 1.2em;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button.ol-button-check:before {
  content: "✓";
  font-weight: bold;
}

.ol-overlaycontainer .ol-touch-cursor.nodrawing .ol-button.ol-button-x, .ol-overlaycontainer .ol-touch-cursor.nodrawing .ol-button.ol-button-remove, .ol-overlaycontainer .ol-touch-cursor.nodrawing .ol-button.ol-button-check {
  opacity: .8;
  background: #3693;
}

.ol-overlaycontainer .ol-touch-cursor .ol-button > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-overlaycontainer .ol-touch-cursor .ol-button-type:before {
  content: "⇎";
  font-weight: bold;
}

.mapboxgl-canvas:focus {
  outline: none;
}

.ol-perspective-map {
  width: 200%;
  height: 200%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-perspective-map .ol-layer {
  z-index: -1 !important;
}

.ol-perspective-map .ol-layers {
  transform: translateY(0)perspective(200px)rotateX(0)scaleY(1);
}

.ol-perspective-map .ol-overlaycontainer, .ol-perspective-map .ol-overlaycontainer-stopevent {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50% !important;
  height: 50% !important;
}

.ol-overlay-container .ol-magnify {
  z-index: 0;
  background: #00000080;
  border: 3px solid #369;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  box-shadow: 5px 5px 5px #00000080;
}

.ol-overlay-container .ol-magnify:before {
  content: "";
  z-index: 1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 40px 2px #00000040;
}

.ol-overlay-container .ol-magnify:after {
  content: "";
  z-index: 1;
  border-radius: 50%;
  width: 20%;
  height: 0;
  display: block;
  position: absolute;
  top: 20%;
  left: 23%;
  transform: rotate(-40deg);
  box-shadow: 0 0 20px 7px #fff;
}

.ol-popup.anim {
  visibility: hidden;
}

.ol-popup.anim.visible {
  visibility: visible;
}

.ol-popup.anim.visible > div {
  visibility: visible;
  animation: .4s ol-popup_bounce;
  transform: none;
}

@keyframes ol-popup_bounce {
  from {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(.95);
  }

  to {
    transform: scale(1);
  }
}

.ol-popup.anim.ol-popup-bottom.ol-popup-left > div {
  transform-origin: 0 100%;
}

.ol-popup.anim.ol-popup-bottom.ol-popup-right > div {
  transform-origin: 100% 100%;
}

.ol-popup.anim.ol-popup-bottom.ol-popup-center > div {
  transform-origin: 50% 100%;
}

.ol-popup.anim.ol-popup-top.ol-popup-left > div {
  transform-origin: 0 0;
}

.ol-popup.anim.ol-popup-top.ol-popup-right > div {
  transform-origin: 100% 0;
}

.ol-popup.anim.ol-popup-top.ol-popup-center > div {
  transform-origin: 50% 0;
}

.ol-popup.anim.ol-popup-middle.ol-popup-left > div {
  transform-origin: 0;
}

.ol-popup.anim.ol-popup-middle.ol-popup-right > div {
  transform-origin: 100%;
}

.ol-popup {
  user-select: none;
  font-size: .9em;
}

.ol-popup .ol-popup-content {
  cursor: default;
  padding: .25em .5em;
  overflow: hidden;
}

.ol-popup.hasclosebox .ol-popup-content {
  margin-right: 1.7em;
}

.ol-popup .ol-popup-content:after {
  clear: both;
  content: "";
  height: 0;
  font-size: 0;
  display: block;
}

.ol-popup .anchor {
  pointer-events: none;
  background: red;
  width: 0;
  height: 0;
  margin: -11px 22px;
  display: block;
  position: absolute;
}

.ol-popup .anchor:after, .ol-popup .anchor:before {
  position: absolute;
}

.ol-popup-right .anchor:after, .ol-popup-right .anchor:before {
  right: 0;
}

.ol-popup-top .anchor {
  top: 0;
}

.ol-popup-bottom .anchor {
  bottom: 0;
}

.ol-popup-right .anchor {
  right: 0;
}

.ol-popup-left .anchor {
  left: 0;
}

.ol-popup-center .anchor {
  left: 50%;
  margin-left: 0 !important;
}

.ol-popup-middle .anchor {
  top: 50%;
  margin-top: 0 !important;
}

.ol-popup-center.ol-popup-middle .anchor {
  display: none;
}

.ol-popup.ol-fixed {
  margin: 0 !important;
  inset: .5em .5em auto auto !important;
  transform: none !important;
}

.ol-popup.ol-fixed .anchor {
  display: none;
}

.ol-popup.ol-fixed.anim > div {
  animation: none;
}

.ol-popup .ol-fix {
  float: right;
  cursor: pointer;
  background: #fff;
  width: 1em;
  height: .9em;
  margin: .2em;
  position: relative;
}

.ol-popup .ol-fix:before {
  content: "";
  box-sizing: border-box;
  border: .1em solid #666;
  border-right-width: .3em;
  width: .8em;
  height: .7em;
  margin: .1em;
  display: block;
}

.ol-popup.shadow {
  box-shadow: 2px 2px 2px 2px #00000080;
}

.ol-popup .closeBox {
  color: #fff;
  cursor: pointer;
  float: right;
  background-color: #003c8880;
  border: 0;
  border-radius: 2px;
  width: 1.4em;
  height: 1.4em;
  margin: 5px 5px 0 0;
  padding: 0;
  font-size: .9em;
  font-weight: 700;
  display: none;
  position: relative;
}

.ol-popup.hasclosebox .closeBox {
  display: block;
}

.ol-popup .closeBox:hover {
  background-color: #003c88b3;
}

.ol-popup .closeBox:after {
  content: "×";
  text-align: center;
  width: 100%;
  margin: -.5em 0;
  font-size: 1.5em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.ol-popup.modifytouch {
  background-color: #eee;
}

.ol-popup.modifytouch .ol-popup-content {
  white-space: nowrap;
  padding: 0 .25em;
  font-size: .85em;
}

.ol-popup.modifytouch .ol-popup-content a {
  text-decoration: none;
}

.ol-popup.tooltips {
  background-color: #ffa;
}

.ol-popup.tooltips .ol-popup-content {
  white-space: nowrap;
  padding: 0 .25em;
  font-size: .85em;
}

.ol-popup.default > div {
  background-color: #fff;
  border: 1px solid #69f;
  border-radius: 5px;
}

.ol-popup.default {
  margin: -11px 0;
  transform: translate(0, -22px);
}

.ol-popup-top.ol-popup.default {
  margin: 11px 0;
  transform: none;
}

.ol-popup-left.default {
  margin: -11px -22px;
  transform: translate(0, -22px);
}

.ol-popup-top.ol-popup-left.default {
  margin: 11px -22px;
  transform: none;
}

.ol-popup-right.default {
  margin: -11px 22px;
  transform: translate(44px, -22px);
}

.ol-popup-top.ol-popup-right.default {
  margin: 11px 22px;
  transform: translate(44px);
}

.ol-popup-middle.default {
  margin: 0 10px;
  transform: none;
}

.ol-popup-middle.ol-popup-right.default {
  margin: 0 -10px;
  transform: translate(-20px);
}

.ol-popup.default .anchor {
  color: #69f;
}

.ol-popup.default .anchor:after, .ol-popup.default .anchor:before {
  content: "";
  border: 11px solid;
  border-color: currentColor #0000;
  margin: 0 -11px;
}

.ol-popup.default .anchor:after {
  border-width: 11px;
  border-color: #fff #0000;
  margin: 2px -11px;
}

.ol-popup-top.default .anchor:before, .ol-popup-top.default .anchor:after {
  border-top: 0;
  top: 0;
}

.ol-popup-bottom.default .anchor:before, .ol-popup-bottom.default .anchor:after {
  border-bottom: 0;
  bottom: 0;
}

.ol-popup-middle.default .anchor:before {
  border-color: #0000 currentColor;
  margin: -11px -33px;
}

.ol-popup-middle.default .anchor:after {
  border-color: #0000 #fff;
  margin: -11px -31px;
}

.ol-popup-middle.ol-popup-left.default .anchor:before, .ol-popup-middle.ol-popup-left.default .anchor:after {
  border-left: 0;
}

.ol-popup-middle.ol-popup-right.default .anchor:before, .ol-popup-middle.ol-popup-right.default .anchor:after {
  border-right: 0;
}

.ol-popup.placemark {
  color: #c00;
  margin: -.65em 0;
  transform: translate(0, -1.3em);
}

.ol-popup.placemark > div {
  min-width: unset;
  box-sizing: border-box;
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  font-size: 15px;
  position: relative;
  box-shadow: inset 0 0 0 .45em;
}

.ol-popup.placemark .ol-popup-content {
  cursor: default;
  text-align: center;
  width: 1em;
  height: 1em;
  padding: .25em 0;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.ol-popup.placemark .anchor {
  margin: 0;
}

.ol-popup.placemark .anchor:before {
  content: "";
  background: none;
  border-radius: 50%;
  width: 1em;
  height: .5em;
  margin: -.5em;
  box-shadow: 0 1em .5em #00000080;
}

.ol-popup.placemark .anchor:after {
  content: "";
  border: .7em solid #0000;
  border-top: 1em solid;
  border-bottom: 0 solid;
  margin: -.75em -.7em;
  bottom: 0;
}

.ol-popup.placemark.shield > div {
  border-radius: .2em;
}

.ol-popup.placemark.shield .anchor:after {
  border-width: .8em 1em 0;
  margin: -.7em -1em;
}

.ol-popup.placemark.blazon > div {
  border-radius: .2em;
}

.ol-popup.placemark.pushpin {
  margin: -2.2em 0;
  transform: translate(0, -4em);
}

.ol-popup.placemark.pushpin > div {
  border-radius: 0;
  width: 1.1em;
  box-shadow: inset 2em 0;
  background: none !important;
}

.ol-popup.placemark.pushpin > div:before {
  content: "";
  pointer-events: none;
  border: .5em solid #0000;
  border-top: .3em solid;
  border-bottom-color: currentColor;
  width: 1.3em;
  height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-popup.placemark.needle {
  margin: -2em 0;
  transform: translate(0, -4em);
}

.ol-popup.placemark.pushpin .anchor, .ol-popup.placemark.needle .anchor {
  margin: -1.2em;
}

.ol-popup.placemark.pushpin .anchor:after, .ol-popup.placemark.needle .anchor:after {
  border-style: solid;
  border-width: 2em .15em 0;
  width: .1em;
  margin: -.55em -.2em;
}

.ol-popup.placemark.pushpin .anchor:before, .ol-popup.placemark.needle .anchor:before {
  margin: -.75em -.5em;
}

.ol-popup.placemark.flagv {
  margin: -2em 1em;
  transform: translate(0, -4em);
}

.ol-popup.placemark.flagv > div {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
  border-radius: 0;
}

.ol-popup.placemark.flagv > div:before {
  content: "";
  pointer-events: none;
  border: 1em solid #0000;
  border-left: 2em solid;
  position: absolute;
}

.ol-popup.placemark.flagv .anchor {
  margin: -1.4em;
}

.ol-popup.placemark.flag {
  margin: -2em 1em;
  transform: translate(0, -4em);
}

.ol-popup.placemark.flag > div {
  border-radius: 0;
  transform-origin: 0 150% !important;
}

.ol-popup.placemark.flag .anchor {
  margin: -1.4em;
}

.ol-popup.placemark.flagv .anchor:after, .ol-popup.placemark.flag .anchor:after {
  border-style: solid;
  border-width: 2em .15em 0;
  width: .1em;
  margin: -.55em -1em;
}

.ol-popup.placemark.flagv .anchor:before, .ol-popup.placemark.flag .anchor:before {
  margin: -.75em -1.25em;
}

.ol-popup.placemark.flag.finish {
  margin: -2em 1em;
}

.ol-popup.placemark.flag.finish > div {
  background-image: linear-gradient(45deg, currentColor 25%, #0000 25% 75%, currentColor 75%, currentColor), linear-gradient(45deg, currentColor 25%, #0000 25% 75%, currentColor 75%, currentColor);
  background-position: .5em 0, 0 .5em;
  background-size: 1em 1em;
  box-shadow: inset 0 0 0 .25em;
}

.ol-popup.black .closeBox {
  color: #f80;
  background-color: #00000080;
  border-radius: 5px;
}

.ol-popup.black .closeBox:hover {
  color: #da2;
  background-color: #000000b3;
}

.ol-popup.black {
  margin: -20px 0;
  transform: translate(0, -40px);
}

.ol-popup.black > div {
  color: #fff;
  background-color: #0009;
  border-radius: 5px;
}

.ol-popup-top.ol-popup.black {
  margin: 20px 0;
  transform: none;
}

.ol-popup-left.black {
  margin: -20px -22px;
  transform: translate(0, -40px);
}

.ol-popup-top.ol-popup-left.black {
  margin: 20px -22px;
  transform: none;
}

.ol-popup-right.black {
  margin: -20px 22px;
  transform: translate(44px, -40px);
}

.ol-popup-top.ol-popup-right.black {
  margin: 20px 22px;
  transform: translate(44px);
}

.ol-popup-middle.black {
  margin: 0 11px;
  transform: none;
}

.ol-popup-left.ol-popup-middle.black {
  transform: none;
}

.ol-popup-right.ol-popup-middle.black {
  margin: 0 -11px;
  transform: translate(-22px);
}

.ol-popup.black .anchor {
  color: #0009;
  margin: -20px 11px;
}

.ol-popup.black .anchor:before {
  content: "";
  border: 20px solid;
  border-left: 11px solid #0000;
  border-right: 11px solid #0000;
}

.ol-popup-top.black .anchor:before {
  border-top: 0;
  top: 0;
}

.ol-popup-bottom.black .anchor:before {
  border-bottom: 0;
  bottom: 0;
}

.ol-popup-middle.black .anchor:before {
  border-color: #0000 currentColor;
  margin: -20px -22px;
}

.ol-popup-middle.ol-popup-left.black .anchor:before {
  border-left: 0;
}

.ol-popup-middle.ol-popup-right.black .anchor:before {
  border-right: 0;
}

.ol-popup-center.black .anchor:before {
  margin: 0 -10px;
}

.ol-popup.tips .closeBox {
  color: #fff;
  background-color: red;
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
}

.ol-popup.tips .closeBox:hover {
  background-color: #f40;
}

.ol-popup.tips {
  margin: -20px 0;
  transform: translate(0, -40px);
}

.ol-popup.tips > div {
  color: #333;
  background-color: #cea;
  border: 5px solid #ad7;
  border-radius: 5px;
}

.ol-popup-top.ol-popup.tips {
  margin: 20px 0;
  transform: none;
}

.ol-popup-left.tips {
  margin: -20px -22px;
  transform: translate(0, -40px);
}

.ol-popup-top.ol-popup-left.tips {
  margin: 20px -22px;
  transform: none;
}

.ol-popup-right.tips {
  margin: -20px 22px;
  transform: translate(44px, -40px);
}

.ol-popup-top.ol-popup-right.tips {
  margin: 20px 22px;
  transform: translate(44px);
}

.ol-popup-middle.tips {
  margin: 0;
  transform: none;
}

.ol-popup-left.ol-popup-middle.tips {
  margin: 0 22px;
  transform: none;
}

.ol-popup-right.ol-popup-middle.tips {
  margin: 0 -22px;
  transform: translate(-44px);
}

.ol-popup.tips .anchor {
  color: #ad7;
  margin: -18px 22px;
}

.ol-popup.tips .anchor:before {
  content: "";
  border: 20px solid;
  border-left: 11px solid #0000;
  border-right: 11px solid #0000;
}

.ol-popup-top.tips .anchor:before {
  border-top: 0;
  top: 0;
}

.ol-popup-bottom.tips .anchor:before {
  border-bottom: 0;
  bottom: 0;
}

.ol-popup-center.tips .anchor:before {
  border-width: 20px 6px;
  margin: 0 -6px;
}

.ol-popup-left.tips .anchor:before {
  border-left: 0;
  margin-left: 0;
}

.ol-popup-right.tips .anchor:before {
  border-right: 0;
  margin-right: 0;
}

.ol-popup-middle.tips .anchor:before {
  border-width: 6px 20px;
  border-color: #0000 currentColor;
  margin: -6px -41px;
}

.ol-popup-middle.ol-popup-left.tips .anchor:before {
  border-left: 0;
}

.ol-popup-middle.ol-popup-right.tips .anchor:before {
  border-right: 0;
}

.ol-popup.warning .closeBox {
  color: #fff;
  background-color: red;
  border-radius: 50%;
  font-size: .83em;
}

.ol-popup.warning .closeBox:hover {
  background-color: #f40;
}

.ol-popup.warning {
  color: #900;
  background-color: #fd0;
  border: 4px dashed red;
  border-radius: 3px;
  margin: -28px 10px;
  transform: translate(0, -56px);
}

.ol-popup-top.ol-popup.warning {
  margin: 28px 10px;
  transform: none;
}

.ol-popup-left.warning {
  margin: -28px -22px;
  transform: translate(0, -56px);
}

.ol-popup-top.ol-popup-left.warning {
  margin: 28px -22px;
  transform: none;
}

.ol-popup-right.warning {
  margin: -28px 22px;
  transform: translate(44px, -56px);
}

.ol-popup-top.ol-popup-right.warning {
  margin: 28px 22px;
  transform: translate(44px);
}

.ol-popup-middle.warning {
  margin: 0;
  transform: none;
}

.ol-popup-left.ol-popup-middle.warning {
  margin: 0 22px;
  transform: none;
}

.ol-popup-right.ol-popup-middle.warning {
  margin: 0 -22px;
  transform: translate(-44px);
}

.ol-popup.warning .anchor {
  margin: -33px 7px;
}

.ol-popup.warning .anchor:before {
  content: "";
  border: 30px solid red;
  border-left: 11px solid #0000;
  border-right: 11px solid #0000;
}

.ol-popup-top.warning .anchor:before {
  border-top: 0;
  top: 0;
}

.ol-popup-bottom.warning .anchor:before {
  border-bottom: 0;
  bottom: 0;
}

.ol-popup-center.warning .anchor:before {
  margin: 0 -21px;
}

.ol-popup-middle.warning .anchor:before {
  border-width: 10px 22px;
  border-color: #0000 red;
  margin: -10px -33px;
}

.ol-popup-middle.ol-popup-left.warning .anchor:before {
  border-left: 0;
}

.ol-popup-middle.ol-popup-right.warning .anchor:before {
  border-right: 0;
}

.ol-popup .ol-popupfeature table {
  width: 100%;
}

.ol-popup .ol-popupfeature table td {
  text-overflow: ellipsis;
  max-width: 25em;
  overflow: hidden;
}

.ol-popup .ol-popupfeature table td img {
  max-width: 100px;
  max-height: 100px;
}

.ol-popup .ol-popupfeature tr:nth-child(odd) {
  background-color: #eee;
}

.ol-popup .ol-popupfeature .ol-zoombt {
  color: #003c8880;
  background: none;
  border: 0;
  outline: none;
  width: 2em;
  height: 2em;
  display: inline-block;
  position: relative;
}

.ol-popup .ol-popupfeature .ol-zoombt:before {
  content: "";
  box-sizing: border-box;
  background-color: #0000;
  border: .17em solid;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  position: absolute;
  top: .3em;
  left: .3em;
}

.ol-popup .ol-popupfeature .ol-zoombt:after {
  content: "";
  box-sizing: border-box;
  border-style: solid;
  border-width: .1em .3em;
  border-radius: .03em;
  position: absolute;
  top: 1.35em;
  left: 1.15em;
  transform: rotate(45deg);
  box-shadow: -.2em 0 0 -.04em;
}

.ol-popup .ol-popupfeature .ol-count {
  float: right;
  margin: .25em 0;
}

.ol-popup .ol-popupfeature .ol-prev, .ol-popup .ol-popupfeature .ol-next {
  vertical-align: bottom;
  cursor: pointer;
  border: .5em solid #0000;
  border-left-color: #003c8880;
  border-right: 0 solid #003c8880;
  margin: 0 .5em;
  display: inline-block;
}

.ol-popup .ol-popupfeature .ol-prev {
  border-width: .5em .5em .5em 0;
}

.ol-popup.tooltips.black {
  background-color: #0000;
}

.ol-popup.tooltips.black > div {
  background-color: #00000080;
  padding: .2em .5em;
  transform: scaleY(1.3);
}

.ol-popup-middle.tooltips.black .anchor:before {
  border-width: 5px 10px;
  margin: -5px -21px;
}

.ol-popup-center.ol-popup-middle, .ol-popup-top.ol-popup-left.ol-fixPopup, .ol-popup-top.ol-popup-right.ol-fixPopup, .ol-popup.ol-fixPopup {
  margin: 0;
}

.ol-miniscroll {
  position: relative;
}

.ol-miniscroll:hover .ol-scroll {
  opacity: .5;
  transition: opacity 1s;
}

.ol-miniscroll .ol-scroll {
  -ms-touch-action: none;
  touch-action: none;
  opacity: 0;
  z-index: 1;
  border-radius: 9px;
  width: 9px;
  height: auto;
  max-height: 100%;
  transition: opacity 1s .5s;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.ol-miniscroll .ol-scroll > div {
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid #0000;
  border-radius: 9px / 12px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: inset 10px 0;
}

.ol-miniscroll .ol-scroll.ol-100pc {
  opacity: 0;
}

.ol-viewport canvas.ol-fixedoverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ol-ext-toggle-switch {
  cursor: pointer;
  position: relative;
}

.ol-ext-toggle-switch input[type="radio"], .ol-ext-toggle-switch input[type="checkbox"] {
  display: none;
}

.ol-ext-toggle-switch span {
  color: #003c8880;
  cursor: pointer;
  vertical-align: middle;
  background-color: #ccc;
  border-radius: 1em;
  width: 1.6em;
  height: 1em;
  margin: -.15em .2em .15em;
  font-size: 1.3em;
  transition: all .4s, background-color, border-color;
  display: inline-block;
  position: relative;
}

.ol-ext-toggle-switch span:before {
  content: "";
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  transition: all .4s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.ol-ext-toggle-switch:hover span {
  background-color: #999;
}

.ol-ext-toggle-switch:hover span:before {
  border-color: #999;
}

.ol-ext-toggle-switch input:checked + span {
  background-color: currentColor;
}

.ol-ext-toggle-switch input:checked + span:before {
  border-color: currentColor;
  transform: translate(.6em, -50%);
}

.ol-ext-check {
  display: inline-block;
  position: relative;
}

.ol-ext-check input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.ol-ext-check span {
  color: #003c8880;
  vertical-align: middle;
  background-color: #ccc;
  width: 1em;
  height: 1em;
  margin: -.1em .5em .1em;
  display: inline-block;
  position: relative;
}

.ol-ext-check:hover span {
  background-color: #999;
}

.ol-ext-checkbox input:checked ~ span {
  background-color: currentColor;
}

.ol-ext-checkbox input:checked ~ span:before {
  content: "";
  width: .5em;
  height: .8em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-.1em)translate(-50%, -50%)rotate(45deg);
  box-shadow: inset -.2em -.2em #fff;
}

.ol-ext-radio span {
  border-radius: 50%;
  width: 1.1em;
  height: 1.1em;
}

.ol-ext-radio:hover input:checked ~ span {
  background-color: #ccc;
}

.ol-ext-radio input:checked ~ span:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-collection-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ol-collection-list li {
  padding: 0 2em 0 1em;
  position: relative;
}

.ol-collection-list li:hover {
  background-color: #003c8833;
}

.ol-collection-list li.ol-select {
  color: #fff;
  background-color: #003c8880;
}

.ol-collection-list li .ol-order {
  -ms-touch-action: none;
  touch-action: none;
  cursor: n-resize;
  width: 2em;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ol-collection-list li .ol-order:before {
  content: "";
  background-color: currentColor;
  border-radius: 2px;
  width: 18px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px, 0 -5px;
}

.ol-ext-colorpicker.ol-popup {
  background-color: #0000;
  background-image: linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%), linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%);
  background-position: 0 -1px, 5px 4px;
  background-size: 10px 10px;
  width: 2em;
  height: 1.5em;
}

.ol-ext-colorpicker .ol-tabbar {
  background-color: #eee;
  border-bottom: 1px solid #999;
  display: none;
}

.ol-ext-colorpicker.ol-tab .ol-tabbar {
  display: block;
}

.ol-ext-colorpicker .ol-tabbar > div {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 2px 2px 0 0;
  padding: 0 .5em;
  display: inline-block;
  position: relative;
  top: 1px;
}

.ol-ext-colorpicker .ol-tabbar > div:first-child {
  border-bottom-color: #fff;
}

.ol-ext-colorpicker.ol-picker-tab .ol-tabbar > div:first-child {
  border-bottom-color: #999;
}

.ol-ext-colorpicker.ol-picker-tab .ol-tabbar > div:nth-child(2) {
  border-bottom-color: #fff;
}

.ol-ext-colorpicker.ol-popup.ol-tab .ol-popup {
  width: 180px;
}

.ol-ext-colorpicker.ol-tab .ol-palette {
  margin: 0 10px;
}

.ol-ext-colorpicker.ol-tab .ol-container {
  display: none;
}

.ol-ext-colorpicker.ol-tab.ol-picker-tab .ol-container {
  display: block;
}

.ol-ext-colorpicker.ol-tab.ol-picker-tab .ol-palette {
  display: none;
}

.ol-ext-colorpicker.ol-popup .ol-popup {
  width: 340px;
}

.ol-ext-colorpicker.ol-popup .ol-vignet {
  content: "";
  pointer-events: none;
  background-color: currentColor;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ol-ext-colorpicker .ol-container {
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.ol-ext-colorpicker .ol-cursor {
  pointer-events: none;
}

.ol-ext-colorpicker .ol-picker {
  cursor: crosshair;
  background-color: currentColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(#000), to(transparent)), -webkit-gradient(linear, left top, right top, from(#fff), to(transparent));
  background-image: linear-gradient(0deg, #000, #0000), linear-gradient(90deg, #fff, #0000);
  border: 5px solid #fff;
  width: 150px;
  height: 150px;
  position: relative;
}

.ol-ext-colorpicker .ol-picker .ol-cursor {
  border: 1px solid #000000b3;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 1px #ffffffb3;
}

.ol-ext-colorpicker .ol-slider {
  cursor: crosshair;
  background-color: #fff;
  background-image: linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%), linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%);
  background-position: 0 -1px, 5px 4px;
  background-size: 10px 10px;
  border: 0 solid #fff;
  border-width: 0 5px;
  width: 150px;
  height: 10px;
  margin: 5px 0 10px;
  position: relative;
}

.ol-ext-colorpicker .ol-slider > div {
  pointer-events: none;
  background-image: linear-gradient(45deg, #0000, #fff);
  width: 100%;
  height: 100%;
}

.ol-ext-colorpicker .ol-slider .ol-cursor {
  background: none;
  border: 1px solid #000;
  width: 4px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.ol-ext-colorpicker .ol-tint {
  cursor: crosshair;
  -webkit-box-sizing: border;
  box-sizing: border;
  background-image: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(red), color-stop(#f0f), color-stop(#00f), color-stop(#0ff), color-stop(#0f0), color-stop(#ff0), to(red));
  background-image: linear-gradient(0deg, red, #f0f, #00f, #0ff, #0f0, #ff0, red);
  border: 5px solid #fff;
  border-width: 5px 0;
  width: 10px;
  height: 150px;
  position: absolute;
  top: 0;
  right: 5px;
}

.ol-ext-colorpicker .ol-tint .ol-cursor {
  border: 1px solid #000;
  width: 12px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-ext-colorpicker .ol-clear {
  border: 2px solid #999;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 163px;
  right: 4px;
}

.ol-ext-colorpicker .ol-clear:before, .ol-ext-colorpicker .ol-clear:after {
  content: "";
  background-color: #999;
  width: 15px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.ol-ext-colorpicker .ol-clear:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ol-ext-colorpicker.ol-nopacity .ol-slider, .ol-ext-colorpicker.ol-nopacity .ol-clear, .ol-ext-colorpicker.ol-nopacity .ol-alpha {
  display: none;
}

.ol-ext-colorpicker .ol-rgb {
  width: 170px;
  padding: 5px;
  display: none;
  position: relative;
}

.ol-ext-colorpicker .ol-rgb input {
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 2px;
  width: 25%;
  padding: 0 0 0 2px;
  font-size: 13px;
}

.ol-ext-colorpicker .ol-rgb input:first-child {
  background-color: #ff00001a;
}

.ol-ext-colorpicker .ol-rgb input:nth-child(2) {
  background-color: #00ff001a;
}

.ol-ext-colorpicker .ol-rgb input:nth-child(3) {
  background-color: #0000ff1f;
}

.ol-ext-colorpicker button, .ol-ext-colorpicker .ol-txt-color {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 2px;
  width: 170px;
  margin: 0 5px 5px;
  padding: 0;
  font-size: 13px;
  display: block;
}

.ol-ext-colorpicker button {
  background-color: #eee;
}

.ol-ext-colorpicker button:hover {
  background-color: #e9e9e9;
}

.ol-ext-colorpicker .ol-txt-color.ol-error {
  background-color: #f003;
}

.ol-ext-colorpicker .ol-palette {
  width: 152px;
  padding: 2px;
  display: inline-block;
}

.ol-ext-colorpicker .ol-palette > div {
  cursor: pointer;
  background-image: linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%), linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%);
  background-position: 0 0, 5px 5px;
  background-size: 10px 10px;
  border-radius: 1px;
  width: 15px;
  height: 15px;
  margin: 2px;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 2px #666;
}

.ol-ext-colorpicker .ol-palette > div:before {
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 100%;
  position: absolute;
}

.ol-ext-colorpicker .ol-palette > div.ol-select:after {
  content: "";
  width: 6px;
  height: 12px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
  box-shadow: 1px 1px #fff, 2px 2px #000;
}

.ol-ext-colorpicker .ol-palette > div:hover {
  box-shadow: 0 0 2px 1px #d90;
}

.ol-ext-colorpicker .ol-palette hr {
  margin: 0;
}

.ol-input-popup {
  display: inline-block;
  position: relative;
}

.ol-input-popup .ol-popup {
  z-index: 1;
  white-space: nowrap;
  background-color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  left: -5px;
  box-shadow: 1px 1px 3px 1px #999;
}

.ol-input-popup.ol-hover:hover .ol-popup, .ol-input-popup.ol-focus .ol-popup {
  display: block;
}

.ol-input-popup.ol-right .ol-popup {
  left: auto;
  right: -5px;
}

.ol-input-popup.ol-middle .ol-popup {
  top: 50%;
  transform: translateY(-50%);
}

.ol-input-popup .ol-popup li {
  padding: 10px 5px;
  position: relative;
}

.ol-input-popup li:hover, .ol-input-popup li.ol-selected {
  background-color: #ccc;
}

.ol-input-popup.ol-fixed:hover .ol-popup, .ol-input-popup.ol-fixed .ol-popup {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  vertical-align: middle;
  background-color: #0000;
  display: inline-block;
  position: relative;
  left: 0;
}

.ol-input-popup.ol-fixed.ol-left .ol-popup {
  float: left;
}

.ol-input-popup > div {
  vertical-align: middle;
  border: 1px solid #999;
  border-radius: 2px;
  padding: 3px 20px 3px 10px;
  display: inline-block;
  position: relative;
}

.ol-input-popup > div:before {
  content: "";
  border: 5px solid #0000;
  border-top-color: #999;
  position: absolute;
  top: 50%;
  right: 5px;
}

.ol-ext-popup-input {
  vertical-align: top;
  display: inline-block;
}

.ol-ext-popup-input.ol-popup {
  box-sizing: content-box;
  user-select: none;
  vertical-align: middle;
  border: 3px solid #fff;
  border-right-width: 1em;
  border-radius: 2px;
  width: 2em;
  height: 1.5em;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 2px 1px #666;
}

.ol-ext-popup-input.ol-popup:after {
  content: "";
  pointer-events: none;
  border: .3em solid #0000;
  border-top: .5em solid #999;
  border-bottom: 0 solid #999;
  position: absolute;
  top: 50%;
  right: -.8em;
  transform: translateY(-50%);
}

.ol-ext-popup-input * {
  box-sizing: content-box;
}

.ol-ext-popup-input.ol-popup .ol-popup {
  z-index: 1;
  background-color: #fff;
  min-width: 3em;
  min-height: 3em;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 1px 1px 3px 1px #999;
}

.ol-ext-popup-input.ol-popup .ol-popup.ol-visible {
  display: block;
}

.ol-ext-popup-input.ol-popup-fixed .ol-popup {
  position: fixed;
  top: auto;
  left: auto;
}

.ol-input-popup.ol-size li {
  vertical-align: middle;
  height: 100%;
  padding: 5px;
  display: table-cell;
}

.ol-input-popup.ol-size li > * {
  vertical-align: middle;
  background-color: #369;
  border-radius: 50%;
  width: 1em;
  height: 1em;
}

.ol-input-popup.ol-size li > .ol-option-0 {
  color: #aaa;
  box-sizing: border-box;
  background-color: #0000;
  border: 2px solid;
  width: 1em;
  height: 1em;
  position: relative;
}

.ol-input-popup.ol-size li > :before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ol-input-popup.ol-size li > .ol-option-0:before {
  content: "";
  background-color: #aaa;
  width: 1em;
  height: 2px;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ol-input-slider {
  display: inline-block;
  position: relative;
}

.ol-input-slider .ol-popup {
  z-index: 1;
  background-color: #fff;
  display: none;
  position: absolute;
  left: -5px;
  box-shadow: 1px 1px 3px 1px #999;
}

.ol-input-slider.ol-right .ol-popup {
  left: auto;
  right: -5px;
}

.ol-input-slider.ol-hover:hover .ol-popup, .ol-input-slider.ol-focus .ol-popup {
  white-space: nowrap;
  display: block;
}

.ol-input-slider.ol-hover:hover .ol-popup > *, .ol-input-slider.ol-focus .ol-popup > * {
  vertical-align: middle;
  display: inline-block;
}

.ol-input-slider.ol-hover:hover .ol-popup > .ol-before, .ol-input-slider.ol-focus .ol-popup > .ol-before {
  margin-left: 10px;
}

.ol-input-slider.ol-hover:hover .ol-popup > .ol-after, .ol-input-slider.ol-focus .ol-popup > .ol-after {
  margin-right: 10px;
}

.ol-input-slider .ol-slider {
  vertical-align: middle;
  box-sizing: content-box;
  cursor: pointer;
  border: 10px solid #0000;
  border-width: 10px 15px;
  width: 100px;
  height: 3px;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 0 1px #999;
}

.ol-input-slider .ol-slider > .ol-cursor {
  pointer-events: none;
  background-color: #999;
  width: 5px;
  height: 10px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ol-input-range .ol-slider {
  cursor: default;
  width: 150px;
}

.ol-input-range .ol-slider > .ol-cursor {
  pointer-events: all;
  cursor: pointer;
  background-color: #003c88;
  border-radius: 50%;
  width: 10px;
}

.ol-input-range.ol-moving .ol-slider > .ol-cursor {
  pointer-events: none;
}

.ol-input-range .ol-slider > .ol-back {
  pointer-events: none;
  background-color: #003c88;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 30%;
  right: 20%;
  transform: translateY(-50%);
}

.ol-input-slider.ol-fixed:hover .ol-popup, .ol-input-slider.ol-fixed .ol-popup {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  vertical-align: middle;
  background-color: #0000;
  display: inline-block;
  position: relative;
  left: 0;
}

.ol-input-slider.ol-fixed.ol-left .ol-popup {
  float: left;
}

.ol-input-slider.ol-size .ol-slider {
  -webkit-box-shadow: none;
  box-shadow: none;
  height: auto;
}

.ol-input-slider.ol-size .ol-slider .ol-back {
  color: #aaa;
  pointer-events: none;
  border-top: 0 solid;
  border-bottom: 20px solid;
  border-left: 100px solid #0000;
  border-right: 0 solid #0000;
  width: 0;
}

.ol-input-slider.ol-size .ol-slider > .ol-cursor {
  border: 5px solid red;
  border-left: 3px solid #0000;
  border-right: 3px solid #0000;
  -o-border-image: initial;
  border-image: initial;
  background-color: #0000;
  width: 2px;
  height: calc(100% + 4px);
  box-shadow: inset 3px 0 red;
}

.ol-input-popup.ol-width li {
  padding: 5px;
}

.ol-input-popup.ol-width li > * {
  background-color: #369;
  width: 100px;
  height: 1em;
}

.ol-input-popup.ol-width li > .ol-option-0 {
  background-color: #0000;
  background-image: linear-gradient(90deg, #aaa 2px, #0000 2px);
  background-size: 4px;
  height: 1px;
  position: relative;
}

/*# sourceMappingURL=index.99ccc1b4.css.map */
